import classes from "./DevicesBar.module.css";
import { useContext, useEffect, useRef, useState } from "react";
import UserProvider from "../../store/user-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGear,
  faWifi,
  faBatteryThreeQuarters,
  faChevronDown,
  faBell,
  faX,
  faAdd,
  faMicrochip,
  faGaugeHigh,
  faPlus,
  faSave,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import GeneralPopup from "../../UI/GeneralPopup";
import { toast } from "react-toastify";
import PuffLoader from "react-spinners/PuffLoader";
import { useTranslation } from "react-i18next";
import HashLoader from "react-spinners/HashLoader";

const calculateWifi = (wifi) => {
  let wifisignal = 0;
  if (wifi <= -100) wifisignal = 0;
  else if (wifi >= -50) wifisignal = 100;
  else wifisignal = 2 * (wifi + 100);

  return wifisignal;
};
const init = {
  name: "",
  id: "",
  online: false,
  wifi: "0",
  battery: "-1",
  service: false,
};
const DevicesBar = (props) => {
  const userCtx = useContext(UserProvider);
  const [dropDown, setDropDown] = useState([]);
  const [device, setDevice] = useState(init);
  const [extender_popup, set_extender_popup] = useState(false);
  const [extender_device, set_extender_device] = useState("");
  const [extender_loading, set_extender_loading] = useState(false);
  const [device_timeout, set_device_timeout] = useState(false);
  const [service_loading, set_service_loading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    try {
      if (
        Object.keys(userCtx.devicesList).length > 0 &&
        userCtx.devicesList[props.device]
      ) {
        let deviceList = userCtx.devicesList[props.device];
        let temp = {
          id: props.device,
          name: deviceList["device-name"],
          online: deviceList["device-state"],
          wifi: calculateWifi(deviceList["device-wifi"]),
          battery: deviceList["battery-level"],
          service: deviceList["service"] ? true : false,
        };

        setDevice(temp);
      }
    } catch (error) {}
  }, [userCtx.devicesList, props]);

  useEffect(() => {
    // console.log(userCtx.devicesAll)
    // console.log(userCtx.watchedList)
    // console.log(props.device)

    let temp = [];

    try {
      if (userCtx.deviceInputs[props.device]) {
        for (let i in userCtx.deviceInputs[props.device]) {
          if (
            userCtx.deviceInputs[props.device][i]["active"] &&
            userCtx.deviceInputs[props.device][i]["enabled"]
          ) {
            temp.push({
              id: i,
              name: userCtx.deviceInputs[props.device][i]["name"],
              watched:
                userCtx.watchedList[props.device] &&
                userCtx.watchedList[props.device].length > 0 &&
                userCtx.watchedList[props.device].includes(i)
                  ? true
                  : false,
            });
          }
        }
      }

      if (userCtx.deviceOutputs[props.device]) {
        for (let i in userCtx.deviceOutputs[props.device]) {
          if (
            userCtx.deviceOutputs[props.device][i]["active"] &&
            userCtx.deviceOutputs[props.device][i]["enabled"]
          ) {
            temp.push({
              id: i,
              name: userCtx.deviceOutputs[props.device][i]["name"],
              watched:
                userCtx.watchedList[props.device] &&
                userCtx.watchedList[props.device].length > 0 &&
                userCtx.watchedList[props.device].includes(i)
                  ? true
                  : false,
            });
          }
        }
      }
      setDropDown(temp);
    } catch (error) {
      // console.log(error)
    }
  }, [userCtx.deviceInputs, userCtx.deviceOutputs, userCtx.watchedList, props]);

  const handleWatchList = (e) => {
    let sensor = e.target.closest("button").getAttribute("data-id");

    fetch(`${userCtx.serverHost}api/dashboardWatchlistToggle/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
      body: JSON.stringify({
        device: props.device,
        sensor: sensor,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        userCtx.fetchWatchedList();
      });
  };

  const add_extender = async () => {
    if (!extender_device) {
      toast(t("Fill in the name of the device"));
      return;
    }

    set_extender_loading(true);

    const response = await fetch(`${userCtx.serverHost}api/add_extender`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
      body: JSON.stringify({
        device_name: props.device,
        extender_name: extender_device,
      }),
    })
      .then((res) => res.json())
      .catch((err) => "");

    if (response["extenter_allready_in_device"]) {
      toast(t("The extender is already connected to the device"));
      set_extender_loading(false);
      return;
    }

    if (response["extenter_not_available"]) {
      toast(t("The extender is connected to another device"));
      set_extender_loading(false);
      return;
    }

    if (response["invalid_extender"]) {
      toast(t("Enter a valid extender"));
      set_extender_loading(false);
      return;
    }

    if (response["extenter_not_a_slave"]) {
      toast(t("The device you imported is not an extender"));
      set_extender_loading(false);
      return;
    }

    if (response["status"] == 200) {
      set_extender_loading(true);
      set_device_timeout(true);
      return;
    }
    set_extender_loading(false);
  };

  useEffect(() => {
    let alert;

    if (device_timeout) {
      let found = false;

      for (let i in userCtx.devicesList[props.device]["linked_with"]) {
        let extender = userCtx.devicesList[props.device]["linked_with"][i];

        if (extender == extender_device) {
          set_extender_loading(false);
          set_device_timeout(false);
          found = true;
          break;
        }
      }

      if (!found) {
        alert = setTimeout(() => {
          toast(t("Device unavailable try again later"));
          set_extender_loading(false);
          set_device_timeout(false);
        }, 7000);
      } else {
        set_extender_loading(false);
        set_device_timeout(false);
        set_extender_device("");
        clearTimeout(alert);

        userCtx.refreshApp();
        toast(t("Extender added successfully"));
      }
    }

    return () => {
      clearTimeout(alert);
    };
  }, [device_timeout, userCtx.devicesList[props.device]]);

  const toggle_service = async () =>{
    set_service_loading(true)

    const response = await fetch(`${userCtx.serverHost}api/sendMqtt/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
      body: JSON.stringify({
        retain : true,
        topic: `${props.device}/servicemode`,
        payload: {
          service : !device['service'],
        },
      }),
    })
      .then((res) => res.json())

    if( !response['flag'] ){
      toast(t("Something went wrong, please try again later"));
      set_service_loading(false)
    }

  }

  useEffect(() => {
    set_service_loading(false);
  },[device['service']])


  useEffect(() => {
    
    const alert = setTimeout(() => {
      if (service_loading) {
        forceServiceMode();
        set_service_loading(false);
      }
    }, 7000);

    return() => {
      clearTimeout(alert);
    }

  }, [service_loading]);

  const forceServiceMode = async () =>{
    const response = await fetch(`${userCtx.serverHost}api/sendMqtt/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
      body: JSON.stringify({
        topic: `forceServiceMode`,
        payload: {
          device : props.device,
          service : !device['service'],
        },
      }),
    })
    .then((res) => res.json())

    if( response['flag'] ){
      toast(t("Device unavailable, next time it comes online it will automatically set to your preference"));
    }else{
      toast(t("Something went wrong, please try again later"));
    }
  }

  return (
    <div className="d-flex flex-column flex-md-row flex-warp justify-content-between align-items-center">
      <div className="row w-100 align-items-center">
        <div className="col-6 ps-0 ps-sm-3 col-md-9">
          <FontAwesomeIcon
            icon={faMicrochip}
            size="2x"
            className="text-primary"
          />
          <h1 className="d-inline text-primary">{device["name"]}</h1>

          <div className=" d-inline mx-2">
            {service_loading ? (
              <HashLoader
                color="#04cc21"
                loading={true}
                size={15}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            ) : (
              <button
                className={`btn ${device["service"] ? "enable" : "disable"}`}
                onClick={toggle_service}
              >
                <FontAwesomeIcon icon={faScrewdriverWrench} />
              </button>
            )}
          </div>

          <p className="mb-0 text-info d-block d-sm-inline ms-0 ms-sm-4">
            #{device["id"]}
          </p>
          <span className="d-none d-sm-inline-block ms-4">
            <span
              className={`${classes.status} ${
                device.online ? classes.online : classes.offline
              }`}
            >
              {device.online ? t("Online") : t("Offline")}
            </span>
          </span>
        </div>
        <div className="col-6 col-md-3 text-end pe-0 pe-sm-2 d-block d-sm-none">
          <span
            className={`${classes.status} ${
              device.online ? classes.online : classes.offline
            }`}
          >
            {device.online ? t("Online") : t("Offline")}
          </span>
        </div>
      </div>

      <div className="d-flex flex-row gap-3 mt-3 mt-sm-0 align-items-center">
        {device && device.online && (
          <div className="text-primary">
            <span className="d-flex align-items-center">
              <FontAwesomeIcon icon={faWifi} className="me-2" /> {device.wifi}%
            </span>
            {device.battery != "-1" && (
              <span className="d-flex align-items-center">
                <FontAwesomeIcon icon={faBatteryThreeQuarters} />{" "}
                {device.battery}%
              </span>
            )}
          </div>
        )}

        <div className="position-relative watchListRelative ms-0 ms-sm-3">
          <div className="d-flex align-items-center btn btn-outline-primary rounded gap-2  px-2 py-1">
            <FontAwesomeIcon icon={faGaugeHigh} />
            <span>{t("Monitor")}</span>
            <FontAwesomeIcon icon={faChevronDown} />
          </div>

          <div className="bg-white pt-1 px-2 fs-6 fw-semibold  rounded-1 watchListAbsolute ">
            {dropDown &&
              dropDown.length > 0 &&
              dropDown.map((item) => {
                return (
                  <div
                    className="row py-2 px-2 align-items-center"
                    style={{ borderBottom: "1px solid #ddd" }}
                  >
                    <span className="col">{item["name"]}</span>
                    <div className="col-3">
                      <button
                        className={`w-100 p-0 py-1 text-center btn ${
                          item["watched"]
                            ? "btn-outline-danger"
                            : "btn-outline-primary"
                        }`}
                        data-id={item["id"]}
                        onClick={handleWatchList}
                      >
                        {item["watched"] ? (
                          <FontAwesomeIcon icon={faX} size="xs" />
                        ) : (
                          <FontAwesomeIcon icon={faAdd} />
                        )}
                      </button>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <NavLink
          className="btn btn-primary text-decoration-none d-flex align-items-center"
          to={`/devices/settings/${props.device}`}
        >
          <FontAwesomeIcon icon={faGear} size="" className="me-2" />

          {t("Settings")}
        </NavLink>

        {props.is_master && (
          <div>
            <button
              onClick={() => {
                set_extender_popup(true);
              }}
              className="btn btn-primary text-decoration-none d-flex align-items-center"
            >
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              <span>Extender</span>
            </button>
          </div>
        )}

        <GeneralPopup
          popUpIsShow={extender_popup}
          setPopUpIsShow={set_extender_popup}
          title={"Προσθήκη extender"}
        >
          <div className="mt-2 d-flex flex-column gap-3">
            <div>{t("Device name")}</div>
            <input
              type="text"
              value={extender_device}
              onChange={(e) => set_extender_device(e.target.value)}
            />
            <button
              className="btn btn-primary d-flex align-items-center justify-content-center gap-2"
              onClick={add_extender}
              disabled={extender_loading}
            >
              <FontAwesomeIcon icon={faSave} />
              <span>{t("Save")}</span>
              <PuffLoader
                color="#fff"
                loading={extender_loading}
                size={35}
                aria-label="Loading Spinner"
                data-testid="loader"
                className="ms-3"
              />
            </button>
          </div>
        </GeneralPopup>
      </div>
    </div>
  );
};

export default DevicesBar;
