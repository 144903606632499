import i18next from "i18next";
import { useEffect, useState } from "react";

const languages = {
  el: { label: "ΕΛ", dir: "ltr", active: false },
  en: { label: "EN", dir: "ltr", active: true }
};
const LanguageSelect = () => {
  const [selected_language, set_selected_language] = useState('')



  useEffect(() =>{
    if( localStorage.getItem("i18nextLng") ){
      set_selected_language(localStorage.getItem("i18nextLng"))
    }
  },[])
  
 useEffect(() => {

  if(selected_language){
    document.body.dir = languages[selected_language].dir;
    i18next.changeLanguage(selected_language);
    localStorage.setItem("i18nextLng", selected_language)
  }
  
  }, [selected_language]);

  const handle_language_change = (e) =>{
    set_selected_language(e.target.value)
  }

  return (
    <div className="d-flex justify-content-end align-items-center language-select-root">
      <select
        onChange={handle_language_change}
        value={selected_language}
      >
        {Object.keys(languages).map((item, i) => {
          return(
            <option 
              key={`ls_${i}`}
              value={item}
            >{languages[item].label}
            </option>
          )
        })}
      </select>
    </div>
  );
};

export default LanguageSelect;
