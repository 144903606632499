import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import UserProvider from "../../store/user-context";
import { useTranslation } from "react-i18next";
import HashLoader from "react-spinners/HashLoader";

const initialUser = {
  fName: "",
  lName: "",
  email: "",
  phone: "",
  role: "Client",
  relay: false,
  settings: false,
  watch: false,
};

function emailIsValid(InputEmail) {
  if (InputEmail.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
    return true;
  } else {
    return false;
  }
}

const UserForm = (props) => {
  const userCtx = useContext(UserProvider);
  const [user, setUser] = useState(initialUser);
  const [loading, set_loading] = useState(false);
  const { t } = useTranslation();



  useEffect(() => {
    if (props["type"] === "edit") {
      let temp = {
        fName: props["data"]["first-name"],
        lName: props["data"]["last-name"],
        email: props["data"]["email"],
        phone: props["data"]["phone-number"],
        role: props["data"]["role"],
        relay: props["data"]['rules']['relay'],
        settings: props["data"]['rules']['settings'],
        watch: props["data"]['rules']['watch'],
      };
      setUser(temp);
    }
    if (props["type"] === "editMyProfile") {
      let temp = {
        fName: props["data"]["first-name"],
        lName: props["data"]["last-name"],
        email: props["data"]["email"],
        phone: props["data"]["phone-number"],
        role: props["data"]["role"],
        relay: props["data"]['rules']['relay'],
        settings: props["data"]['rules']['settings'],
        watch: props["data"]['rules']['watch'],
      };
      setUser(temp);
    }
  }, []);

  const handleUserForm = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if( name == 'watch' ||  name == 'relay' || name == 'settings'  ){
      value = !user[name];
    }

    if( name == 'settings' && !user['settings'] ){
      setUser((previus) => ({ ...previus, 'watch': true }));
    }

    setUser((previus) => ({ ...previus, [name]: value }));
  };

  const updateUser = async () => {
    if (userCtx.isDemo) {
      toast(t("There is no capability to save actions in a user demo"));

      return;
    }

    if (
      user["fName"] &&
      user["lName"] &&
      user["email"] &&
      user["role"] &&
      emailIsValid(user["email"]) &&
      user["phone"]
    ) {
      set_loading(true);

      let response = await fetch(`${userCtx.serverHost}api/editUser/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": userCtx.csrf,
        },
        credentials: "include",
        body: JSON.stringify({ ...user, userId: props["child"] }),
      }).then((res) => res.json());

      toast(response["message"]);
      if (response["flag"]) {
        closePopUp();
        props.fetchProfile();
      }

      set_loading(false);
    } else {
      toast(t("Please make sure you fill in all fields correctly"));
    }
  };
  const submitForm = async () => {
    if (userCtx.isDemo) {
      toast(t("There is no capability to save actions in a user demo"));
      return;
    }

    if (
      user["fName"] &&
      user["lName"] &&
      user["email"] &&
      user["role"] &&
      emailIsValid(user["email"]) &&
      user["phone"]
    ) {
      set_loading(true);

      let response = await fetch(`${userCtx.serverHost}api/addUser/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": userCtx.csrf,
        },
        credentials: "include",
        body: JSON.stringify(user),
      }).then((res) => res.json());

      if (response["flag"]) {
        toast(t("The account was successfully registered."));
        setUser(initialUser);
        closePopUp();
        props.fetchProfile();
      } else {
        toast(t("Please enter a different email."));
      }

      set_loading(false);
    } else {
      toast(t("Please make sure you fill in all fields correctly"));
    }
  };

  const closePopUp = () => {
    props.setUserFormHandler((previus) => ({ ...previus, show: false }));
  };

  return (
    <div className="fixedContainer">
      <div className="profileContainer fs-6 fw-semibold">
        <div className="d-flex justify-content-between px-md-5 align-items-center">
          <span>{`${t("Name")} *`}</span>
          <input
            type="text"
            name="fName"
            value={user["fName"]}
            onChange={handleUserForm}
          />
        </div>
        <div className="d-flex justify-content-between px-md-5  align-items-center">
          <span>{`${t("Last name")} *`}</span>
          <input
            type="text"
            name="lName"
            value={user["lName"]}
            onChange={handleUserForm}
          />
        </div>
        {props["type"] === "add" && (
          <div className="d-flex justify-content-between px-md-5  align-items-center">
            <span>{`${t("Email")} *`}</span>
            <input
              type="text"
              name="email"
              value={user["email"]}
              onChange={handleUserForm}
            />
          </div>
        )}

        <div className="d-flex justify-content-between px-md-5  align-items-center">
          <span>{`${t("Phone")} *`}</span>
          <input
            type="number"
            name="phone"
            value={user["phone"]}
            onChange={handleUserForm}
          />
        </div>

        {props["type"] !== "editMyProfile" && (
          <>
            <div className="d-flex justify-content-between px-md-5  align-items-center">
              <span>{`${t("Role")} *`}</span>
              <select name="role" value={user["role"]} onChange={handleUserForm}>
                <option value="Admin">{t("Admin")}</option>
                <option value="Client">{t("Client")}</option>
              </select>
            </div>

            {user["role"] != 'Admin' &&
              <div className="d-flex justify-content-between px-md-5  align-items-center">
                <div className="d-flex gap-2 align-items-center">
                  <span>
                    {t('Devices')}
                  </span>

                  <input 
                    type="checkbox"
                    name="watch"
                    onChange={handleUserForm}
                    value={user["watch"]}
                    checked={user["watch"]}
                  />
                </div>
                <div className="d-flex gap-2 align-items-center">
                  <span>
                    {t('Settings')}
                  </span>

                  <input 
                    type="checkbox"
                    name="settings"
                    onChange={handleUserForm}
                    value={user["settings"]}
                    checked={user["settings"]}
                  />
                </div>
                <div className="d-flex gap-2 align-items-center">
                  <span>
                    {t('Relay')}
                  </span>

                  <input 
                    type="checkbox"
                    name="relay"
                    onChange={handleUserForm}
                    value={user["relay"]}
                    checked={user["relay"]}
                  />
                </div>
                
              </div>
            }


            
          </>
          


          
        )}

        <div className="d-flex justify-content-between px-md-5  align-items-center">
          <button className="btn btn-outline-danger" onClick={closePopUp}>
            {t("Cancel")}{" "}
          </button>
          {props["type"] === "add" && (
            <button
              className="btn btn-outline-primary d-flex align-items-center gap-2 justify-content-center"
              disabled={loading}
              onClick={submitForm}
            >
              {" "}
              {t("Add")}{" "}
              <HashLoader
                color="#04cc21"
                loading={loading}
                size={15}
                aria-label="Loading Spinner"
                data-testid="loader"
              />{" "}
            </button>
          )}
          {(props["type"] === "edit" || props["type"] === "editMyProfile") && (
            <button
              className="btn btn-outline-primary d-flex align-items-center gap-2 justify-content-center"
              disabled={loading}
              onClick={updateUser}
            >
              {t("Save")}{" "}
              <HashLoader
                color="#04cc21"
                loading={loading}
                size={15}
                aria-label="Loading Spinner"
                data-testid="loader"
              />{" "}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserForm;
