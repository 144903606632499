import React, { useContext, useEffect } from "react";
import Login from "./components/Login/Login";
import Dashboard from "./components/Dashboard/Dashboard";
import UserProvider from "./store/user-context";
import "bootstrap/dist/css/bootstrap.css";
import Header from "./UI/Header";
import Menu from "./UI/Menu/Menu";
import Wrapper from "./UI/Wrapper";
import Device from "./components/Devices/Device";
import { BrowserRouter as Router, Routes, Route , Navigate } from "react-router-dom";
import Profile from "./components/Account/Profile";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './style.css'
import './reboot.css'
import Settings from "./components/Settings/Settings";
import Register from "./components/Register/Register";
import AddDevice from "./components/Devices/AddDevice";
import ForgotPassword from "./components/Login/ForgotPassword";
import RingLoader from "react-spinners/RingLoader";
import Notifications from "./utils/Notifications";
import AccountDevices from "./components/Account/AccountDevices";
import InsufficientRights from "./UI/InsufficientRights";

function App() {
  const ctx = useContext(UserProvider);

  
  
  return (
    <React.Fragment>
      <ToastContainer
            position="top-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
      <Router>
      { ctx && !ctx.isLoggedIn && 
        <Routes>
          <Route path="/*" element={ !ctx.isLoggedIn &&  <Login></Login>} /> 
          <Route path="/demo/login" element={ <Login force={"demo"} ></Login>} /> 
          <Route path="/register" element={ !ctx.isLoggedIn &&  <Register />} /> 
          <Route path="/forgotpassword" element={ !ctx.isLoggedIn &&  <ForgotPassword />} /> 
        </Routes>
      }


        
      <main>
        {ctx && ctx.isLoggedIn &&
          <>
            <Header  />
            <Wrapper>
              <Notifications />
              <Menu></Menu>
               { ctx.loading   ? 
               
                <div className="d-flex align-items-center justify-content-center w-100">
                  <RingLoader
                    color={"#04cc21"}
                    loading={true}
                    size={150}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </div>        
                : 
                <>
                  <Routes>
                    <Route path="/*"   element={<Navigate to="/dashboard/" replace={true} />}> </Route>
                    <Route path="/dashboard/"  exact element={<Dashboard></Dashboard>}> </Route>
                    <Route path="/devices/:deviceId/" exact element={<Device></Device>}> </Route>
                    <Route path="/devices/settings/:deviceId/" exact element={<Settings></Settings>}> </Route>
                    <Route path="/devices/" exact element={<AccountDevices/>}> </Route>
                    <Route path="/profile" exact element={<Profile></Profile>}> </Route>
                    <Route path="/insufficient_rights/" exact element={<InsufficientRights></InsufficientRights>}> </Route>
                    <Route path="/addDevice" exact element={<AddDevice />}> </Route>
                  </Routes> 
                </>
              }
            </Wrapper>
          </>
          }
      </main>
      </Router>
    </React.Fragment>
  );
}

export default App;
