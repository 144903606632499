import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./assets/translations/en.json";
import translationEL from "./assets/translations/el.json";

const fallbackLng = ["en"];
const availableLanguages = ["en", "el"];
let userLang = "";
if (!localStorage.getItem("i18nextLng")) {
  userLang = "el";
} else {
  userLang = localStorage.getItem("i18nextLng");
}
const lng = userLang;
localStorage.setItem("i18nextLng", lng)


const resources = {
  en: {
    translation: translationEN,
  },
  el: {
    translation: translationEL,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: lng,
    fallbackLng,
    detection: {
      checkWhitelist: true,
    },
    debug: false,
    whitelist: availableLanguages,
    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;
