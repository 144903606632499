import { getFToken,onMessageListener } from "./firebase"; 
import { useContext, useEffect, useState } from "react";
import { toast } from 'react-toastify';
import UserProvider from "../store/user-context";

const Notifications = () => {
  const userCtx = useContext(UserProvider);
  const [token, setToken] = useState(false)

  useEffect(() => {
    requestPermission()
  },[])


  
  useEffect(() => {
    if( token !== false && userCtx.device_notifications.length > 0 ){
      subscribeToTopics()
    }
  },[token, userCtx.device_notifications])

   function requestPermission  () {
    var NotificationIsSupported = !!(window.Notification /* W3C Specification */ || window.webkitNotifications /* old WebKit Browsers */ || navigator.mozNotification /* Firefox for Android and Firefox OS */)

    if(NotificationIsSupported){
      Promise.resolve(Notification.requestPermission()).then(function(permission) {
        // Do something
        if (permission === 'granted') {
          async function token(){
            let token = await getFToken()
            setToken(token)
          }
          token()
        }
      });
    }
  }

  const subscribeToTopics = async () => {
    const data = await fetch(`${userCtx.serverHost}api/subscribeToTopics/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
      body: JSON.stringify(
        {
          token : token,
          devices : userCtx.device_notifications
        }
      )
    }).then((res) => res.json())
  }

  


  


  onMessageListener().then(payload => {
    let notification = `Ειδοποίηση: ${payload.notification.title}  ${payload.notification.body}`
    toast(notification)
  }).catch(err => console.log('failed: ', err));
}


export default Notifications