import logo from "../../static/images/logo.png";
import { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import UserProvider from "../../store/user-context";
import { useNavigate,NavLink } from "react-router-dom";
import PuffLoader from "react-spinners/PuffLoader";
import { useTranslation } from "react-i18next";

let init = {
  type : "1",
  fName : "",
  lName : "",
  afm : "",
  email : "",
  password : "",
  repassword : "",
  newsletter : false,
}


const Register = () => {
  const { t } = useTranslation();
  const [user, setUser] = useState(init)
  const userCtx = useContext(UserProvider);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)

  const handlerUser = (e) => {
    let name = e.target.name
    let value = e.target.value
    let temp = {...user}

    if( name === 'newsletter' ){
      value =  (value === 'true') ? false : true
    } 

    temp[name] = value
    setUser(temp)

  }

  const checkForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let flag = true


    if( 
      !user['fName']      ||
      !user['lName']      ||
      !user['email']      ||
      !user['password']   ||
      !user['repassword'] ||
      user['password'] !== user['repassword'] ||
      !emailRegex.test(user['email']) 
      ){
        flag = false
    }

    if (!emailRegex.test(user['email']) ){
      toast(t("Enter a valid email"));

    }
    else if( user['password'].length < 6  ){
      toast(t("The code must be at least 6 characters long"));

    } 
    else if ( user['password'] !== user['repassword'] ) {
      toast(t("The codes you entered are not the same"));

    }else if ( user['type']  ==  2 && !user['afm'] ) {
      toast(t("Enter VAT number"));

      flag = false
    }else if ( !flag ){
      toast(t("Enter the required fields"));

    }

    return flag
  }

   const registerUser = async () => {
    setLoading(true)

    if( checkForm() && !loading){
      const response = await  fetch(`${userCtx.serverHost}api/register/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": userCtx.csrf,
        },
        credentials: "include",
        body: JSON.stringify(user),
      })
      .then((res) => res.json())

      if( response['flag'] ){
        toast(t("Your account has been successfully registered, please check your email."));

        setUser(init)
        navigate('/login')
      }else{
        toast(t("The email is already registered, please enter a different email."));

      }
    }
    setLoading(false)
  }


  return(

    <div className="greyContainer">
      <div className="d-flex justify-content-center"><img className="registerImage" src={logo} alt={logo} ></img></div>
      <div className="registerForm boxShadow mt-4">

        <div>
          <select name="type" value={user['type']} onChange={handlerUser}>
            <option value="1"> {t("Individual")}</option>
            <option value="2"> {t("Company")} </option>
          </select>
        </div>
        <div>
          <input type="text" placeholder={`${t("Name")} *`} name="fName" value={user['fName']} onChange={handlerUser} />
        </div>
        <div>
          <input type="text" placeholder={`${t("Last name")} *`} name="lName" value={user['lName']} onChange={handlerUser}/>
        </div>
        {user['type'] == 2 && 
          <div>
            <input type="text" placeholder={`${t("Vat number")} *`} name="afm" value={user['afm']} onChange={handlerUser}/>
          </div>
        }
        <div>
          <input type="email" placeholder={t("Email")} name="email" value={user['email']} onChange={handlerUser} />
        </div>
        <div>
          <input type="password" placeholder={`${t("Password")}  *`} name="password" value={user['password']} onChange={handlerUser}/>
        </div>
        <div>
          <input type="password" placeholder={`${t("Password verification")}  *`} name="repassword" value={user['repassword']} onChange={handlerUser}/>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <input 
            type="checkbox" 
            placeholder={t("Newsletter")}
            name="newsletter" 
            value={user['newsletter']} 
            checked={user['newsletter']}  
            onChange={handlerUser}
            className="me-2"/>
          <span> {t("Subscribe to our newsletter")}</span>
        </div>

        <div>
          <button className="custom-btn-blue d-flex justify-content-center" disabled={loading} onClick={registerUser}> 
            <span>{t("Register")}</span>
            <PuffLoader
              color="#111344"
              loading={loading}
              size={35}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </button>
        </div>

      </div>
        <NavLink 
            to={"/login"}
            className={`btn btn-link text-center fs-5 mt-3`}
          >
          {t("Login")}
        </NavLink>
    </div>
  )

}


export default Register