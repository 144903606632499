import { useTranslation } from "react-i18next";

const DigitalPreview = (props) => {
  const { t } = useTranslation();


  return (
    <>
      { props && Object.keys(props['inputs']).length > 0 &&
        <div>
          <div className="cardContainer">          
            <div className="d-flex justify-content-center radius-top-left-right-8 cardTitleColor">
              <h3 className="my-2 fs-5">
              {props.place == 'dashboard' &&
            
            <span className="mx-2">{`${props.name}`}</span>
            }
            {t("Inputs")}</h3>
            </div>
            <div className="position-relative">
              <ul className="ulColumn p-3">
              { Object.keys(props['inputs']).map((input) => {
                let cInput = {...props['inputs'][input]}
                let cInputValue = props['liveData']['inputs'][input]
                return(
                  <>
                    {
                      cInput['active'] && cInput['enabled'] ?
                        <li key={input}>
                          <span>{cInput["name"]}</span>
                          <span className={`fw-bold ${cInputValue == 1 ? 'text-success' : 'text-danger'}`}>
                            {cInputValue == 1 ? 'ON' : 'OFF' }
                          </span>
                        </li>
                        :""
                    } 
                  </>
                )
              })}
              </ul>
              { props.deviceState == false &&
                <div className="position-absolute w-100 h-100 top-0 left-0 bg-seethrought-grey z-index-2 border-radius-bottom-8 "></div>
              }
            </div>
            
          </div>
        </div>
      }
    </>
  );
};

export default DigitalPreview;
