import { Link, useNavigate, useParams } from "react-router-dom";
import React from "react";
import { useEffect, useContext, useState } from "react";
import UserProvider from "../../store/user-context";
import AnalogInputLog from "./AnalogInputLog";
import DevicesBar from "./DevicesBar";
// Reusable Containers
import AnalogPreview from "./AnalogPreview";
import DigitalPreview from "./DigitalPreview";
import OutputsPreview from "./OutputsPreview";
import DigitalLogs from "./DigitalLogs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Device = () => {
  const params = useParams();
  const userCtx = useContext(UserProvider);
  const [analogInputs, setAnalogInputs] = useState({});
  const [digitalInputs, setDigitalInputs] = useState({});
  const [outputs, setOutputs] = useState({});
  const [liveData, setLiveData] = useState({});
  const [deviceState, setDeviceState] = useState(false);
  const [deviceLogs, setdeviceLogs] = useState({});
  const [device, setDevice] = useState("");
  const [linked_with, set_linked_with] = useState([]);
  const [waiting_unlink_slave , set_waiting_unlink_slave] = useState("")
  const [logs_for_analog_inputs , set_logs_for_analog_inputs] = useState({})
  const [logs_keys_for_analog_inputs , set_logs_keys_for_analog_inputs] = useState({})
  const [logs_for_digital_inputs , set_logs_for_digital_inputs] = useState({})
  const [logs_keys_for_digital_inputs , set_logs_keys_for_digital_inputs] = useState({})
  const [logs_for_outputs , set_logs_for_outputs] = useState({})
  const [logs_keys_for_outputs , set_logs_keys_for_outputs] = useState({})
  const [is_master, set_is_master] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  

  useEffect(() =>{

    if( userCtx.user['rules'] && userCtx.user['rules']['watch'] ){
      return
    }
    if( userCtx.user && userCtx.user['role'] == "Admin" ){
      return
    }

    navigate('/insufficient_rights/')
    
  },[])

  useEffect(() => {



    const device_type = userCtx.devicesList[params.deviceId]['device-type'];
    const type = userCtx.devicesAll['devices-look-up'][device_type]['type'] 
    set_is_master(type === 'master')

  },[params.deviceId, userCtx.devicesList, userCtx.devicesAll])


  useEffect(() => {
    try {
      if (
        userCtx.devicesList[params.deviceId] &&
        userCtx.devicesList[params.deviceId].hasOwnProperty("linked_with") &&
        Object.keys(userCtx.devicesList[params.deviceId]["linked_with"]).length >0 && 
        typeof userCtx.devicesList[params.deviceId]["linked_with"] !== "string"
      ) {
        let temp_linked_with = [];
        for (let i in userCtx.devicesList[params.deviceId]["linked_with"]) {
          const slave_id =
            userCtx.devicesList[params.deviceId]["linked_with"][i];

          const slave_name =
            userCtx.devicesList[slave_id]["device-name"];

          const inputs = split_inputs(slave_id);
          const temp = {
            slave_id: slave_id,
            slave_name: slave_name,
            master_id: params.deviceId,
            deviceState: userCtx.devicesList[params.deviceId]["device-state"],
            inputs: inputs["analog_inputs"],
            liveData: userCtx.liveData[slave_id],
          };
          temp_linked_with.push(temp);
        }
        set_linked_with(temp_linked_with);
      }
    } catch (error) {
      
    }
    


    return () => {
      set_linked_with([])
    };

  }, [userCtx.devicesList, userCtx.liveData, params.deviceId]);


  const updateLogs = async () => {
    await userCtx.setLogs(params.deviceId);
  };

  const split_inputs = (id) => {
    let analog_inputs = {};
    let digital_inputs = {};
    for (let sensor in userCtx.deviceInputs[id]) {


      if( !userCtx.deviceInputs[id][sensor]['enabled'] || !userCtx.deviceInputs[id][sensor]['active']){
        continue
      }
      if (userCtx.deviceInputs[id][sensor]["type"] == 1) {
        digital_inputs[sensor] = userCtx.deviceInputs[id][sensor];
      } else {
        analog_inputs[sensor] = userCtx.deviceInputs[id][sensor];
      }
    }

    return { analog_inputs: analog_inputs, digital_inputs: digital_inputs };
  };


  const find_valid_outputs = () => {
    let temp_outputs = {};
    const id = params.deviceId;

    for ( let i in userCtx.deviceOutputs[id] ){
      if( !userCtx.deviceOutputs[id][i]['enabled'] || !userCtx.deviceOutputs[id][i]['active'] ){
        continue;
      }

      temp_outputs[i] = userCtx.deviceOutputs[id][i];
    }

    return temp_outputs

  }


  useEffect(() => {
    try {
      const id = params.deviceId;
      const temp = split_inputs(params.deviceId);
      setAnalogInputs(temp["analog_inputs"]);
      setDigitalInputs(temp["digital_inputs"]);

      setDeviceState(userCtx.devicesList[id]["device-state"]);
      setLiveData(userCtx.liveData[id]);


      
      setOutputs(find_valid_outputs());
    } catch (error) {}
  }, [
    userCtx.deviceInputs,
    userCtx.deviceOutputs,
    userCtx.liveData,
    userCtx.devicesList,
    params.deviceId,
  ]);



  const find_device_logs = (type) =>{
    let total_device_keys = {};
    let total_device_logs = {};
    let master_keys = split_inputs(params.deviceId)

    let master_logs = {};

    if ( type === 'analog_inputs' || type === 'digital_inputs' ){
      master_logs = localStorage.getItem(`${params.deviceId}/inputs`)
      ? JSON.parse(localStorage.getItem(`${params.deviceId}/inputs`))
      : {};

      for( let i in master_logs ){
        if(  master_keys[type][i] ){
          total_device_logs[`master_${i}`] = master_logs[i];
          total_device_keys[`master_${i}`] =  master_keys[type][i];
          total_device_keys[`master_${i}`]['device_id'] = params.deviceId;
          total_device_keys[`master_${i}`]['sensor_id'] = i;
        }
      }
  
      for( let  i in  linked_with ){
        let slave_keys = split_inputs(linked_with[i]['slave_id'])
        let slave_id = linked_with[i]['slave_id']
        let slave_logs = localStorage.getItem(`${slave_id}/inputs`)
        ? JSON.parse(localStorage.getItem(`${slave_id}/inputs`))
        : {};
  
        for( let j in slave_logs ){
          if(  slave_keys[type][j] ){
            total_device_logs[`slave_${i}_${j}`] = slave_logs[j];
            total_device_keys[`slave_${i}_${j}`] =  slave_keys[type][j];
            total_device_keys[`slave_${i}_${j}`]['device_id'] = slave_id;
            total_device_keys[`slave_${i}_${j}`]['sensor_id'] = j;
          }
        }
      }
    }
    else if(  type === 'outputs' ){
      master_logs = localStorage.getItem(`${params.deviceId}/outputs`)
      ? JSON.parse(localStorage.getItem(`${params.deviceId}/outputs`))
      : {};

      let master_keys = userCtx.deviceOutputs[params.deviceId]

      for( let i in master_logs ){
        if(  master_keys[i] ){
          total_device_logs[`master_${i}`] = master_logs[i];
          total_device_keys[`master_${i}`] =  master_keys[i];
        }
      }
  
      for( let  i in  linked_with ){
        
        let slave_id = linked_with[i]['slave_id']
        let slave_keys = userCtx.deviceOutputs[slave_id] 

        let slave_logs = localStorage.getItem(`${slave_id}/outputs`)
        ? JSON.parse(localStorage.getItem(`${slave_id}/outputs`))
        : {};
  
        for( let j in slave_logs ){
          if(  slave_keys[j] ){
            total_device_logs[`slave_${i}_${j}`] = slave_logs[j];
            total_device_keys[`slave_${i}_${j}`] =  slave_keys[j];
          }
        }
      }

    }
    

    return {'logs' : total_device_logs , 'keys' : total_device_keys }
  }
  useEffect(() => {

    // Set device analog logs start
    let storage =  find_device_logs('analog_inputs');
    set_logs_keys_for_analog_inputs(storage['keys'])
    set_logs_for_analog_inputs(storage['logs'])
    // Set device analog logs end

    // Set device digital logs start
    storage = find_device_logs('digital_inputs')
    set_logs_keys_for_digital_inputs(storage['keys'])
    set_logs_for_digital_inputs(storage['logs'])
    // Set device digital logs end

    // Set device outputs logs start
    storage = find_device_logs('outputs')
    set_logs_keys_for_outputs(storage['keys'])
    set_logs_for_outputs(storage['logs'])
    // Set device outputs logs end

    let temp = localStorage.getItem(`${params.deviceId}/on-off`)
      ? JSON.parse(localStorage.getItem(`${params.deviceId}/on-off`))
      : {};
    setdeviceLogs(temp);

    setDevice(params.deviceId);
    
  }, [params.deviceId, userCtx.updatingLogs, linked_with, userCtx.deviceOutputs, userCtx.deviceInputs]);


  const unlink_extender = async (e) =>{
    let master_id = e.target.closest('button').getAttribute('data-master_id')
    let slave_id = e.target.closest('button').getAttribute('data-slave_id')


    const response = await fetch(`${userCtx.serverHost}api/unlink_extender`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
      body: JSON.stringify({
        master_id: master_id,
        slave_id: slave_id,
      }),
    })
    .then((res) => res.json())
    .catch((err) => "")

    if( !response['message_send'] || response['status'] != 200 ){
      toast(t("The message cannot be sent, please try again in a moment"));


    }
    if( response['message_send'] ){
      set_waiting_unlink_slave(slave_id)
    }
  

  }


  useEffect(() => {
    let alert;
    
    if( waiting_unlink_slave ){

      let contains_slave = false;

      if( 
        userCtx.devicesList[params.deviceId].hasOwnProperty('linked_with') &&
        userCtx.devicesList[params.deviceId]['linked_with']
      ){

        for( let i in userCtx.devicesList[params.deviceId]['linked_with'] ){
          let slaves_id = userCtx.devicesList[params.deviceId]['linked_with'][i];
          if ( waiting_unlink_slave === slaves_id) {
            contains_slave = true;
            break
          }
        }
      }

      if( contains_slave ){
        alert = setTimeout(() => {
          toast(t("Device unavailable try again later"));

          set_waiting_unlink_slave("")
        }, 7000);
      }else{
        toast(t("Extenstion removed"));

        userCtx.refreshApp();
      }

      
    }

    
    return() => {
      clearTimeout(alert);
    }
  }, [userCtx.devicesList[params.deviceId], waiting_unlink_slave]);


  return (
    <div className={`deviceContainer position-relative`}>
      <div className="p-4"> 
        <DevicesBar device={params.deviceId} is_master={is_master} />
        <div className="d-flex flex-wrap gap-3 mt-3">
          <AnalogPreview
            deviceState={deviceState}
            device={params.deviceId}
            liveData={liveData}
            inputs={analogInputs}
          />
          <DigitalPreview
            device={params.deviceId}
            liveData={liveData}
            inputs={digitalInputs}
            deviceState={deviceState}
          />
          <OutputsPreview
            device={params.deviceId}
            liveData={liveData}
            outputs={outputs}
            deviceState={deviceState}
          />
        </div>

        {linked_with.map((linked, i) => {

          return (
            <div key={`${linked["slave_name"]}${i}`}>
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <h1 className="d-inline text-primary">
                    {linked["slave_name"]}
                  </h1>

                  <Link 
                    className="mb-0 ms-3 text-info d-block d-sm-inline"
                    to={`/devices/${linked["slave_id"]}/`}
                  >
                    {linked["slave_id"]}
                  </Link>
                </div>
                


                <button
                  className="btn btn-outline-danger"
                  data-master_id={linked["master_id"]}
                  data-slave_id={linked["slave_id"]}
                  onClick={unlink_extender}
                >
                  <FontAwesomeIcon icon={faX} />
                </button>
              </div>
              <div
                className="d-flex flex-wrap gap-3 mt-3"
              >
                <AnalogPreview
                  deviceState={linked['deviceState']}
                  device={linked["slave_id"]}
                  liveData={linked['liveData']}
                  inputs={linked['inputs']}
                />
              </div>
            </div>
          );
        })}

        <div className="row ">
          <div className="col-12 col-md-12">
            {logs_keys_for_analog_inputs &&
              Object.keys(logs_keys_for_analog_inputs).length > 0 &&
              logs_for_analog_inputs &&
              Object.keys(logs_for_analog_inputs).length > 0 && (
                <AnalogInputLog
                  device={device}
                  keys={logs_keys_for_analog_inputs}
                  logs={logs_for_analog_inputs}
                  updateLogs={updateLogs}
                  name={userCtx.devicesList[params.deviceId]['device-name']}
                />
              )}

          </div>
          <div className="col-12 col-lg-6 col-xl-4">

            {logs_for_digital_inputs &&
              Object.keys(logs_for_digital_inputs).length > 0 &&
              logs_keys_for_digital_inputs &&
              Object.keys(logs_keys_for_digital_inputs).length > 0 && (
                <DigitalLogs
                  title={t("Input logger")}
                  device={device}
                  type="inputs"
                  keys={logs_keys_for_digital_inputs}
                  logs={logs_for_digital_inputs}
                  updateLogs={updateLogs}
                  name={userCtx.devicesList[params.deviceId]['device-name']}
                />
              )}
          </div>
          <div className="col-12 col-lg-6 col-xl-4">

            {logs_keys_for_outputs &&
              Object.keys(logs_keys_for_outputs).length > 0 &&
              logs_for_outputs &&
              Object.keys(logs_for_outputs).length > 0 && (
                <DigitalLogs
                  title={t("Output logger")}
                  device={device}
                  type="outputs"
                  keys={logs_keys_for_outputs}
                  logs={logs_for_outputs}
                  updateLogs={updateLogs}
                  name={userCtx.devicesList[params.deviceId]['device-name']}
                />
              )}
          </div>
          <div className="col-12 col-lg-6 col-xl-4">
            {deviceLogs && Object.keys(deviceLogs).length > 0 && (
              <DigitalLogs
                title={t("Connection logger")}
                device={device}
                type="devices"
                keys={""}
                logs={deviceLogs}
                updateLogs={updateLogs}
                name={userCtx.devicesList[params.deviceId]['device-name']}
              />
            )}
          </div>
        </div>
      </div>

      {!userCtx.devicesList[params.deviceId]['active'] && 
        <div className="position-absolute w-100 h-100 top-0 left-0 bg-seethrought-grey z-index-2 border-radius-bottom-8 d-flex align-items-center justify-content-center">

          <div className="fw-bold fs-1 text-light">
              {t("Your device is inactive")}
          </div>
        </div>
      }
    </div>
  );
};

export default Device;
