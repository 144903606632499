import { useState, useContext } from "react"
import { toast } from "react-toastify";
import UserProvider from "../../store/user-context";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HashLoader from "react-spinners/HashLoader";


let init = {
  name : '',
  password : '',
}

const AddDevice = () => {
  const [device, setDevice] = useState(init)
  const userCtx = useContext(UserProvider);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, set_loading] = useState(false)

  const handleDevice = (e) => {
    let name = e.target.name
    let value = e.target.value
    let temp = {...device}

    temp[name] = value
    setDevice(temp)
  }


  const addDevice = async () => {

    if(userCtx.isDemo){
      toast(t("Δεν υπάρχει η δυνατότητα αποθήκευσης ενεργειών σε demo χρήστη"))
      return
    }

    if( device['name'] &&  device['password']){
      set_loading(true)

      const response = await  fetch(`${userCtx.serverHost}api/addDevice/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": userCtx.csrf,
        },
        credentials: "include",
        body: JSON.stringify(device),
      })
      .then((res) => res.json())
     

      if( response['flag'] ){
        navigate(`/devices/${device['name']}`)
        document.location.reload();
        setDevice(init)
        userCtx.fetchDevices()
      }else{
        toast(response['message'])
      }

      set_loading(false)

    }else{

      toast(t("Please fill in all fields"))

    }
  }


  return(

    <div className="w-100 mx-3">
      
      <div className="addDeviceContainer boxShadow d-flex flex-column justify-content-center align-items-center gap-4">
        <div className="fs-5 fw-bold">
          {t("Add device")}
        </div>
          <input type="text" placeholder={`${t("Name")} *`} name="name" value={device['name']} onChange={handleDevice}  autoComplete="off" />
          <input type="password" placeholder={`${t("Password")} *`} name="password"  value={device['password']} onChange={handleDevice}  autoComplete="off" />

          <button className="btn btn-outline-primary w-100 d-flex align-items-center gap-2 justify-content-center" onClick={addDevice} disabled={loading}> 
            {t("Add")}

            <HashLoader
              color="#04cc21"
              loading={loading}
              size={15}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </button>
      </div>
    </div>
  )

} 


export default AddDevice