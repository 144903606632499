import { faLock } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from "react-i18next";

const InsufficientRights = () =>{
  const { t } = useTranslation();



  return(
    <div className="deviceContainer p-4">

      <div className="d-flex flex-column align-items-center justify-content-center gap-2">

        <FontAwesomeIcon  icon={faLock} style={{fontSize : "50px"}} />
        <span>{t('Seems like you have insufficient rights to access this page')}</span>

      </div>

      
    </div>

  )
}


export default InsufficientRights