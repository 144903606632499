import { useState, useContext, useEffect } from "react";
import UserProvider from "../../store/user-context";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faPenToSquare,
  faGears,
  faBackward,
  faArrowLeft,
  faArrowLeftLong,
  faAngleLeft,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import Alerts from "./Alerts";
import { toast } from "react-toastify";
import CalendarForm from "./CalendarForm";
import ScheduleForm from "./ScheduleForm";
import ShowSchedule from "./ShowSchedule";
import ShowTimers from "./ShowTimers";
import AddTimer from "./TimerForm";
import ShowCalendar from "./ShowCalendar";
import "react-input-range/lib/css/index.css";
import InputRange from "react-input-range";
import { useTranslation } from "react-i18next";
import HashLoader from "react-spinners/HashLoader";
import { errorToast } from "../../utils/toasts";

const tempInmp = {
  id: "",
  active: "",
  "alert-flag": "",
  "alert-lag": "",
  "alert-mode": "",
  "deviation ": "",
  enabled: "",
  "input-symbol": "",
  "last-alert": "",
  max: "",
  min: "",
  name: "",
  inputLagHours: "",
  inputLagMins: "",
  // notifications: {
  //   call: "",
  //   push: "",
  //   sms: "",
  // },
  "reverse": "",
  "sensor-a-max": "",
  "sensor-a-min": "",
  "sensor-d-max": "",
  "sensor-d-min": "",
  "store-value": "",
  type: "",
};

const initCalendar = {
  show: false,
  type: "add",
  calendar: {},
  child: "",
};
const initschedule = {
  show: false,
  type: "add",
  calendar: {},
  child: "",
};

const initTimer = {
  show: false,
  type: "add",
  timer: {},
  child: "",
};

const initDevice = {
  show: false,
  name: "",
};

const Settings = (props) => {
  const params = useParams();
  const userCtx = useContext(UserProvider);
  const [inputs, setInputs] = useState([]);
  const [cInput, setCInput] = useState(tempInmp);
  const [outputs, setOutputs] = useState([]);
  const [cOutput, setCOutput] = useState([]);
  const [updateInputs, setUpdateInputs] = useState(true);
  const [updateOuputs, setUpdateOuputs] = useState(true);
  const [isBasic, setIsBasic] = useState(true);
  const [timerHandler, setTimerHandler] = useState(initTimer);
  const [deviceHanler, setDeviceHandler] = useState(initDevice);
  const [calendarHandler, setCalendarHandler] = useState(initCalendar);
  const [calendars, setCalendars] = useState({});
  const [scheduleHandler, setScheduleHandler] = useState(initschedule);
  const [schedules, setSchedules] = useState({});
  const [inputDesc, setInputDesc] = useState({});
  const [input_loading, set_input_loading] = useState(false);
  const [output_loading, set_output_loading] = useState(false);
  const [device_has_notifications, set_device_has_notifications] = useState(false)
  const { t } = useTranslation();
  const navigate = useNavigate();


  useEffect(() =>{

    if( userCtx.user['rules'] && userCtx.user['rules']['settings'] ){
      return
    }

    if( userCtx.user && userCtx.user['role'] == "Admin" ){
      return
    }

    navigate('/insufficient_rights/')
    
  },[])

  useEffect(() => {
    if (
      Object.keys(userCtx.devicesList).length &&
      Object.keys(inputDesc).length === 0
    ) {

      if( !userCtx.devicesList[params["deviceId"]]['active'] ){
        errorToast(t("Your device is inactive"))
        navigate(`/devices/${params["deviceId"]}/`);
      }

      let type =
        userCtx.devicesList[params["deviceId"]]["device-type"];
      setInputDesc({ fetching: true });
      fetchInputsDesc(type);
    }
  }, [params, userCtx.devicesList]);

  const fetchInputsDesc = async (type) => {
    const response = await fetch(`${userCtx.serverHost}api/getDeviceDesc/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
      body: JSON.stringify({ type: type }),
    }).then((res) => res.json());

    if (response["flag"]) {
      setInputDesc(response["data"]["inputs"]);
    } else {
      setInputDesc({});
    }
  };

  const inputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let temp = { ...cInput };

    if (name === "id") {
      temp = {
        ...userCtx.deviceInputs[params.deviceId][value],
      };
      temp["id"] = value;

      let alertLag = parseInt(temp["alert-lag"]) / 60; //minutes

      temp["inputLagHours"] = Math.trunc(alertLag / 60);
      temp["inputLagMins"] = alertLag % 60;
    } else if (name === "enabled") {
      temp["enabled"] = !temp["enabled"];
    } else if (name === "push") {
      temp["notifications"]["push"] = value === "true" ? false : true;
    } else if (name === "reverse") {
      temp["reverse"] = value === "true" ? false : true;
    } 
    else if (name === "inputLagHours") {
      value = parseInt(value) ? parseInt(value) : ''
      temp[name] = value <= 24 ? value : 24;
    } 
    else if (name === "inputLagMins") {
      value = parseInt(value) ? parseInt(value) : ''
      temp[name] = value <= 59 ? value : 59;
    } 
    else if (name === "type") {
      temp[name] = parseInt(value) ? parseInt(value) : "";
    } else {
      temp[name] = value;
    }

    setCInput(temp);
  };

  useEffect(() => {
    // console.log("usercontextChanged")
    if (userCtx && userCtx.deviceInputs && userCtx.deviceInputs) {
      let initFlag = updateInputs;
      for (let input in userCtx.deviceInputs[params.deviceId]) {
        if (initFlag) {
          // console.log("inputs updated")
          let temp =
            cInput["id"] && cInput["id"] != ""
              ? userCtx.deviceInputs[params.deviceId][
                  cInput["id"]
                ]
              : userCtx.deviceInputs[params.deviceId][input];

          temp["id"] =
            cInput["id"] && cInput["id"] != "" ? cInput["id"] : input;

          let alertLag = parseInt(temp["alert-lag"]) / 60; //minutes

          temp["inputLagHours"] = Math.trunc(alertLag / 60);
          temp["inputLagMins"] = alertLag % 60;
          

          let active_inputs = {};
          for( let i in userCtx.deviceInputs[params.deviceId]){
            if(!userCtx.deviceInputs[params.deviceId][i]['active']){
              continue
            } 
            active_inputs[i] = userCtx.deviceInputs[params.deviceId][i]
          }
          setInputs(active_inputs);
          setCInput(temp);
          setUpdateInputs(false);
          initFlag = false;
        } else {
          break;
        }
      }
    }
    if (
      userCtx &&
      userCtx.deviceOutputs 
    ) {
      let initFlag = updateOuputs;
      for (let output in userCtx.deviceOutputs[params.deviceId] ) {
        if (initFlag) {
          // console.log("outputs updated")
          let temp =
            cOutput["id"] && cOutput["id"] != ""
              ? userCtx.deviceOutputs[params.deviceId][
                  cOutput["id"]
                ]
              : userCtx.deviceOutputs[params.deviceId][output];

          
          let active_outputs = {};
          for( let i in userCtx.deviceOutputs[params.deviceId]){
            if(!userCtx.deviceOutputs[params.deviceId][i]['active']){
              continue
            } 
            active_outputs[i] = userCtx.deviceOutputs[params.deviceId][i]
          }

          setOutputs(active_outputs);

          temp["id"] =
            cOutput["id"] && cOutput["id"] != "" ? cOutput["id"] : output;
          setCOutput(temp);
          setUpdateOuputs(false);
          initFlag = false;
        } else {
          break;
        }
      }
    }
  }, [userCtx.deviceOutputs,userCtx.deviceInputs,updateOuputs, updateInputs, params.deviceId]);

  const submitInputs = () => {

    if(userCtx.isDemo){
      toast(t("There is no capability to save actions in a user demo"));
      return
    }

    if (cInput["type"] != 1) {
      if(cInput["max"] == '' || cInput["min"] == ''){
        toast(t("Please fill in the maximum and minimum values"));
        return
      }
    }

    set_input_loading(true)

    let alertLag = 0;
    if (parseInt(cInput["inputLagHours"])) {
      alertLag = parseInt(cInput["inputLagHours"]) * 60;
    }

    if (parseInt(cInput["inputLagMins"])) {
      alertLag += parseInt(cInput["inputLagMins"]);
    }

    alertLag = alertLag * 60;

    //type = 1 fields to clear
    // alert-lag
    let json = {};
    if (cInput["type"] == 1) {
      json = {
        enabled: cInput["enabled"],
        // "alert-lag": alertLag,
        "alert-mode": cInput["alert-mode"],
        "input-symbol": cInput["input-symbol"],
        "reverse": cInput["reverse"],
        name: cInput["name"],
        // notifications: cInput["notifications"],
        type: cInput["type"],
      };
    } else {
      json = {
        enabled: cInput["enabled"],
        "alert-lag": alertLag,
        deviation: parseFloat(cInput["deviation"])
          ? parseFloat(cInput["deviation"])
          : 0,
        "input-symbol": cInput["input-symbol"],
        max: parseFloat(cInput["max"]) ? parseFloat(cInput["max"]) : 0,
        min: parseFloat(cInput["min"]) ? parseFloat(cInput["min"]) : 0,
        name: cInput["name"],
        notifications: cInput["notifications"],
        "sensor-a-max": parseFloat(cInput["sensor-a-max"])
          ? parseFloat(cInput["sensor-a-max"])
          : 0,
        "sensor-a-min": parseFloat(cInput["sensor-a-min"])
          ? parseFloat(cInput["sensor-a-min"])
          : 0,
        "sensor-d-max": parseFloat(cInput["sensor-d-max"])
          ? parseFloat(cInput["sensor-d-max"])
          : 0,
        "sensor-d-min": parseFloat(cInput["sensor-d-min"])
          ? parseFloat(cInput["sensor-d-min"])
          : 0,
        type: cInput["type"],
      };
    }

    let mqtt = {
      topic: "devices-inputs",
      device: params.deviceId,
      sensor: cInput["id"],
      values: json,
    };

    fetch(`${userCtx.serverHost}api/sendMqtt/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
      body: JSON.stringify({
        topic: "deviceSettings",
        payload: mqtt,
      }),
    })
      .then((res) => res.json())
      .then((data) => {

        setTimeout(function () {
          if (data["flag"]) {
            toast(t("The input was successfully updated."));
          } else {
            toast(t("Something went wrong."));
          }
          setUpdateInputs(true);
          setUpdateOuputs(true);
          set_input_loading(false)
          userCtx.setUpdatingLogs()
        }, 3000);
      }).catch((err) =>{
        set_input_loading(false)
      });
  };

  const outputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let temp = { ...cOutput };

    if (name === "id") {
      temp = {
        ...userCtx.deviceOutputs[params.deviceId][value],
      };
      temp["id"] = value;
    } else if (name === "enabled") {
      temp["enabled"] = !temp["enabled"];
    } else if (name === "push") {
      temp["notifications"]["push"] = value === "true" ? false : true;
    } else if (name === "type") {
      temp[name] = parseInt(value) ? parseInt(value) : "";
    } else {
      temp[name] = value;
    }
    setCOutput(temp);
  };

  const submitOutputs = () => {

    if(userCtx.isDemo){
      toast(t("There is no capability to save actions in a user demo"));
      return
    }

    set_output_loading(true)
    
    let json = {};

    if (cOutput["type"] == 1) {
      json = {
        enabled: cOutput["enabled"],
        name: cOutput["name"],
        "default-state": cOutput["default-state"],
        notifications: cOutput["notifications"],
        type: cOutput["type"],
      };
    } else {
      json = {
        enabled: cOutput["enabled"],
        name: cOutput["name"],
        "button-duration": cOutput["button-duration"],
        notifications: cOutput["notifications"],
        type: cOutput["type"],
      };
    }

    let mqtt = {
      topic: "devices-outputs",
      device: params.deviceId,
      sensor: cOutput["id"],
      values: json,
    };

    fetch(`${userCtx.serverHost}api/sendMqtt/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
      body: JSON.stringify({
        topic: "deviceSettings",
        payload: mqtt,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        

        setTimeout(function () {
          if (data["flag"]) {
            toast(t("The input was successfully updated."));
          } else {
            toast(t("Something went wrong."));
          }
          set_output_loading(false)
          setUpdateInputs(true);
          setUpdateOuputs(true);
        }, 3000);
      }).catch((err) =>{
        set_output_loading(false)
      });
  };



  const addTimer = () => {
    setTimerHandler({
      show: true,
      type: "add",
      timer: {},
      child: "",
    });
  };

  const addCalentar = () => {
    setCalendarHandler({
      show: true,
      type: "add",
      calendar: {},
      child: "",
    });
  };

  const addSchedule = () => {
    setScheduleHandler({
      show: true,
      type: "add",
      calendar: {},
      child: "",
    });
  };

  const changeDeviceName = async () => {

    if(userCtx.isDemo){
      toast(t("There is no capability to save actions in a user demo"));
      return
    }

    let name = document.getElementById("deviceName").value;
    let mqtt = {
      topic: "devices-list",
      device: params.deviceId,
      values: {
        "device-name": name,
      },
    };

    if (name) {
      let response = await fetch(`${userCtx.serverHost}api/sendMqtt/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": userCtx.csrf,
        },
        credentials: "include",
        body: JSON.stringify({
          topic: "deviceSettings",
          payload: mqtt,
        }),
      }).then((res) => res.json());

      if (response["flag"]) {
        setDeviceHandler((previus) => ({ ...previus, show: false }));
      } else {
        toast(t("Something went wrong, please try again later"));
      }
    } else {
      toast(t("Please enter name"));
    }
  };

  useEffect(() =>{
    set_device_has_notifications(userCtx.device_notifications.includes(params["deviceId"]))
  },[userCtx.device_notifications, params["deviceId"]])


  const change_notifications = async () =>{

    let temp_notifications = [...userCtx.device_notifications]

    if(temp_notifications.includes(params["deviceId"])){
      temp_notifications = temp_notifications.filter(item => item !== params["deviceId"]);
    }else{
      temp_notifications.push(params["deviceId"])
    }

    let response = await fetch(`${userCtx.serverHost}api/set_user_notifications/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
      body: JSON.stringify({
        notifications: temp_notifications,
      }),
    }).then((res) => res.json());


    if( response['flag'] ){
      userCtx.set_device_notifications(temp_notifications)
    }else{
      toast(t("Something went wrong, please try again later"))
    }
  }

  const delete_device = async () =>{

    let response = await fetch(`${userCtx.serverHost}api/delete_device/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
      body: JSON.stringify({
        device: params["deviceId"],
      }),
    }).then((res) => res.json())
    .catch((err) => "");



    if( response && response['flag'] ){
      navigate('/dashboard/')
      userCtx.refreshApp();
    }else{
      toast(t("Something went wrong, please try again later"));
    }

  }

  return (
    <>
      <div className="w-100 py-4 px-3 p-xl-5 ">
        <div className="row">
          <div className="col-12 col-sm-8 text-primary">
            <FontAwesomeIcon icon={faGears} size="2x" />
            <h1 className="d-inline ms-3">{t("Settings")}</h1>
          </div>
          <div className="col-12 col-sm-4 mt-2 mt-sm-0 text-start text-sm-end">
            <Link
              className="btn btn-primary"
              to={`/devices/${params.deviceId}`}
            >
              <FontAwesomeIcon icon={faAngleLeft} size="lg" className="me-2" />
              {t("Back to the device")}
            </Link>
          </div>
        </div>
        <div className="row mt-2 mb-4">
          <div className="col text-info">
            <div className="d-flex justify-content-between align-items-center">
               <div className="d-flex align-items-center gap-3">
              <p className="m-0">
              {t("Device")}:
                {`${
                  userCtx &&
                  userCtx.devicesList
                    ? userCtx.devicesList[params.deviceId]["device-name"]
                    : ""
                } (${params.deviceId})`}
              </p>
              <FontAwesomeIcon
                className=" btn btn-primary"
                icon={faPenToSquare}
                onClick={() => {
                  setDeviceHandler((previus) => ({ ...previus, show: true }));
                }}
              />

              <div className="d-flex gap-2 align-items-center">
                <span>
                  {t('Notifications')}
                </span>

                <input 
                  type="checkbox"
                  checked={device_has_notifications}
                  onChange={change_notifications}
                />
              </div>
            </div>
            <div>
              <button className="btn btn-danger" onClick={delete_device}>
                <FontAwesomeIcon icon={faTrash} />
              </button>

            </div>
            </div>
           
          </div>

          {deviceHanler["show"] && (
            <div className="fixedContainer">
              <div className="deviceSettingsContainer fs-6 fw-semibold">
                <div className="d-flex justify-content-between px-md-5 align-items-center">
                  <span>{`${t("Name")} *`}</span>
                  <input type="text" name="fName" id="deviceName" defaultValue={ userCtx &&
                  userCtx.devicesList
                    ? userCtx.devicesList[params.deviceId]["device-name"]
                    : ""} />
                </div>

                <div className="d-flex justify-content-between px-md-5  align-items-center">
                  <button
                    className="btn btn-outline-danger"
                    onClick={() => {
                      setDeviceHandler((previus) => ({
                        ...previus,
                        show: false,
                      }));
                    }}
                  >
                    {t("Cancel")}
                  </button>
                  <button
                    className="btn btn-outline-primary"
                    onClick={changeDeviceName}
                  >
                    {t("Save")}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        
        <div className="row flex-wrap justify-content-center">
          {/* INPUT START */}

        {inputs && Object.keys(inputs).length > 0 && 
        <div className="col-12 col-xl-6">
            <div className="settingsCard mb-2 radius-15">
              {/* Row start */}
              <div className="d-flex flex-row justify-content-between align-items-center flex-wrap px-3 py-2 bg-blue text-white radius-top-left-right-15">
                <div className="d-flex gap-3 align-items-center">
                  <h3>{t("Input")}:</h3>
                  <select
                    name="id"
                    value={cInput["id"]}
                    onChange={(e) => inputChange(e)}
                  >
                    {inputs &&
                      Object.entries(inputs).map(([key, input]) => {

                        return(
                          <option value={key} key={`settings${key}`}>
                          {inputDesc &&
                            inputDesc[key] ? 
                            `(${inputDesc[key]}) ${input["name"]}`
                            : input["name"]
                          }
                        </option>
                        )
                    })}
                  </select>
                </div>
                <div className="d-flex gap-3 align-items-center">
                  <div className="fw-semibold fs-6">{t("Input active")}</div>
                  <input
                    name="enabled"
                    onClick={(e) => inputChange(e)}
                    type="checkbox"
                    value={cInput["enabled"]}
                    checked={cInput["enabled"]}
                  />
                </div>
                <div >
                  <button
                    className="btn btn-primary "
                    onClick={submitInputs}
                    disabled={input_loading}
                  >
                    <span>
                      {t("Save")}
                    </span>

                    <HashLoader
                      color="#fff"
                      loading={input_loading}
                      size={15}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </button>
                </div>
              </div>
              <div className="p-3 position-relative">
                { !cInput["enabled"] && <div className="sensor_disabled_abs"></div> }
                <div className="d-flex align-items-center">
                  <div className="fw-semibold fs-6 me-3">{t("Input name")}</div>
                  <input
                    className="fs-6"
                    type="text"
                    name="name"
                    value={cInput["name"]}
                    onChange={(e) => inputChange(e)}
                  />
                </div>
                {/* Row end */}

                {/* Row start */}
                <div className="d-flex align-items-center gap-2 mt-3 top-divider-strong pt-4 mb-4">
                  <div className="fw-semibold fs-6 me-2">{t("Settings")}</div>
                  <div className="d-flex gap-2">
                    <button
                      onClick={() => setIsBasic(true)}
                      className={`${
                        isBasic
                          ? "btn btn-secondary"
                          : "btn btn-outline-secondary"
                      }`}
                    >
                      {t("Basic")}
                    </button>
                    <button
                      onClick={() => setIsBasic(false)}
                      className={`${
                        !isBasic
                          ? "btn btn-secondary"
                          : "btn btn-outline-secondary"
                      }`}
                    >
                      {t("Advanced")}
                    </button>
                  </div>
                </div>
                {/* Row end */}

                {/* Row start */}
                {isBasic &&  (
                  <div
                    className="d-flex gap-4 mt-3 g-0 my-3 p-3 radius-15"
                    style={{ border: "1px solid #bbb" }}
                  >
                    <div className="d-flex flex-wrap align-items-center ">
                      <div className="fw-semibold fs-6 me-3">{t("Input type")}</div>
                      <select
                        name="type"
                        value={cInput["type"]}
                        onChange={(e) => inputChange(e)}
                      >
                        <option value="0"></option>
                        <option value="1">{t("Switch")}</option>
                        <option value="2">4-20ma</option>
                        <option value="3">0-10V</option>
                        <option value="4">NTC10k</option>
                        <option value="5">SHT</option>
                        <option value="6">Dallas</option>
                      </select>
                    </div>

                    { cInput && cInput["type"] != "1" ?
                      <>
                      <div className="d-flex align-items-center flex-wrap ">
                        <div className="fw-semibold fs-6 me-3">{t("Symbol")}</div>
                        <select
                          name="input-symbol"
                          value={cInput["input-symbol"]}
                          onChange={(e) => inputChange(e)}
                        >
                          <option value="0"></option>
                          <option value="1">°C</option>
                          <option value="2">%</option>
                          <option value="3">bar</option>
                          <option value="4">m</option>
                        </select>
                      </div>
                      </>
                      : 
                      <>
                        <div className="d-flex align-items-center flex-wrap ">
                        <div className="fw-semibold fs-6 me-3">{t("Reverse")}</div>
                        <input
                              type="checkbox"
                              name="reverse"
                              onChange={(e) => inputChange(e)}
                              value={cInput["reverse"]}
                              checked={cInput["reverse"]}
                            />
                      </div>
                      </>
                    }
                    
                  </div>
                )}

                {/* Row end */}

                {/* Row start */}
                {isBasic && cInput && cInput["type"] != "1" && (
                  <div
                    className="row g-0 my-3 p-3 radius-15"
                    style={{ border: "1px solid #bbb" }}
                  >
                    <div className="col-6 col-lg-6 col-xxl-4 my-2 my-xxl-0 ">
                      <div className="d-flex gap-2 align-items-center">
                        <div className="fw-semibold fs-6">{t("Minimum")}</div>
                        <input
                          type="number"
                          name="min"
                          onChange={(e) => inputChange(e)}
                          value={cInput["min"]}
                        />
                      </div>
                    </div>
                    <div className="col-6 col-lg-6 col-xxl-4 my-2 my-xxl-0 ">
                      <div className="d-flex gap-2 align-items-center">
                        <div className="fw-semibold fs-6">{t("Maximum")}</div>
                        <input
                          type="number"
                          name="max"
                          onChange={(e) => inputChange(e)}
                          value={cInput["max"]}
                        />
                      </div>
                    </div>
                    <div className="col-6 col-lg-6 col-xxl-4 my-2 my-xxl-0 ">
                      <div className="d-flex gap-2 align-items-center">
                        <div className="fw-semibold fs-6">{t("Deviation")}</div>
                        <input
                          type="number"
                          name="deviation"
                          onChange={(e) => inputChange(e)}
                          value={cInput["deviation"]}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {/* Row end */}

                {/* Row start */}
                {/* {isBasic && (
                  <>
                    <div
                      className="mt-3 g-0 p-3 radius-15"
                      style={{ border: "1px solid #bbb" }}
                    >
                      <div className="row">
                        <div className="col-12">
                          <div className="d-flex align-items-center">
                            <div className="fw-semibold fs-6 me-3 ">
                            {t("Notifications")}
                            </div>
                            <input
                              type="checkbox"
                              name="push"
                              onChange={(e) => inputChange(e)}
                              value={
                                cInput["notifications"] &&
                                cInput["notifications"]["push"]
                              }
                              checked={
                                cInput["notifications"] &&
                                cInput["notifications"]["push"]
                              }
                            />
                          </div>
                        </div>
                      </div>
                    {cInput["notifications"]["push"] && 
                      <>
                        <div className="fw-semibold fs-6 my-3">
                        {t("Delay of notifications")}
                        </div>
                        <div className="row ">
                          <div className="col-10">
                            <div className="mb-3">{t("Hour")}</div>
                            <InputRange
                              maxValue={24}
                              minValue={0}
                              value={cInput["inputLagHours"]}
                              onChange={(value) =>
                                setCInput((previus) => ({
                                  ...previus,
                                  inputLagHours: value,
                                }))
                              }
                            />
                          </div>
                          <div className="col-2 mt-4 text-end">
                            <input
                              type="number"
                              placeholder="Hours"
                              onChange={(e) => inputChange(e)}
                              name="inputLagHours"
                              value={cInput["inputLagHours"]}
                            />
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-10">
                            <div className="mb-3">{t("Minutes")}</div>
                            <InputRange
                              maxValue={59}
                              minValue={0}
                              value={cInput["inputLagMins"]}
                              onChange={(value) =>
                                setCInput((previus) => ({
                                  ...previus,
                                  inputLagMins: value,
                                }))
                              }
                            />
                          </div>
                          <div className="col-2 mt-4 text-end">
                            <input
                              type="number"
                              placeholder="Mins"
                              onChange={(e) => inputChange(e)}
                              name="inputLagMins"
                              value={cInput["inputLagMins"]}
                            />
                          </div>
                        </div>
                      </>
                    }
                    </div>
                  </>
                )} */}

                {/* Row end */}
                {/* Row start */}
                {!isBasic && cInput["type"] != "1" && (
                  <div
                    className="mt-3 p-3 radius-15"
                    style={{ border: "1px solid #bbb" }}
                  >
                    <div className="d-flex flex-column">
                      <div className="fw-semibold fs-5 me-2 mb-2 text-center">
                      {t("Sensor calibration")}
                      </div>
                      <div className="row g-5">
                        <div className="col-12 col-xl-6">
                          <div className="fw-semibold fs-6 my-2 text-center">
                            <u>{t("Digital values")}</u>
                          </div>
                          <div className="d-flex flex-column gap-3">
                            <div className="d-flex justify-content-center align-items-center gap-2">
                              <div className="fw-semibold fs-6 align-items-center gap-2">
                              {t("Bottom limit")}
                              </div>
                              <input
                                type="number"
                                placeholder="Min"
                                className="SettingsSmallInput"
                                onChange={(e) => inputChange(e)}
                                name="sensor-d-min"
                                value={cInput["sensor-d-min"]}
                              />
                            </div>

                            <div className="d-flex justify-content-center align-items-center gap-2">
                              <div className="fw-semibold fs-6 align-items-center gap-2">
                              {t("Upper limit")}
                              </div>
                              <input
                                type="number"
                                placeholder="Max"
                                className="SettingsSmallInput"
                                onChange={(e) => inputChange(e)}
                                name="sensor-d-max"
                                value={cInput["sensor-d-max"]}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-12 col-xl-6"
                          style={{ borderLeft: "1px solid #ddd" }}
                        >
                          <div className="fw-semibold fs-6 my-2 text-center">
                            <u>{t("Analogue values")}</u>
                          </div>
                          <div className="d-flex flex-column gap-3">
                            <div className="d-flex justify-content-center align-items-center gap-2">
                              <div className="fw-semibold fs-6 ">{t("Bottom limit")}</div>
                              <input
                                type="number"
                                placeholder="Min"
                                onChange={(e) => inputChange(e)}
                                name="sensor-a-min"
                                className="SettingsSmallInput"
                                value={cInput["sensor-a-min"]}
                              />
                            </div>
                            <div className="d-flex justify-content-center align-items-center gap-2">
                              <div className="fw-semibold fs-6">{t("Upper limit")}</div>
                              <input
                                type="number"
                                placeholder="Max"
                                className="SettingsSmallInput"
                                onChange={(e) => inputChange(e)}
                                name="sensor-a-max"
                                value={cInput["sensor-a-max"]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {params.deviceId && cInput["id"] && !isBasic &&  (
                  <div
                    className="mt-3 p-3 radius-15"
                    style={{ border: "1px solid #bbb" }}
                  >
                    <Alerts device={params.deviceId} input={cInput["id"]} />
                  </div>
                )}

                {/* Row end */}

                
              </div>
            </div>
          </div>
        }
          
          {/* INPUT END */}
          {/* OUTPUT START */}

          {outputs && Object.keys(outputs).length > 0 &&
           <div className="col-12 col-xl-6">
            <div className="settingsCard mb-2 radius-15">
              <div className="d-flex flex-row justify-content-between align-items-center flex-wrap px-3 py-2 bg-blue text-white radius-top-left-right-15">
                <div className="d-flex gap-3 align-items-center">
                  <h3 className="me-2">{t("Output")}</h3>
                  <select
                    name="id"
                    value={cOutput["id"]}
                    onChange={(e) => outputChange(e)}
                  >
                    {outputs &&
                      Object.entries(outputs).map(([key, output]) => (
                        <option value={key} key={`settings${key}`}>
                          {output["name"]}
                        </option>
                      ))}
                  </select>
                </div>
                
                <div className="d-flex align-items-center">
                  <div className="fw-semibold fs-6 me-2">{t("Output active")}</div>
                  <input
                    name="enabled"
                    onClick={(e) => outputChange(e)}
                    type="checkbox"
                    value={cOutput["enabled"]}
                    checked={cOutput["enabled"]}
                  />
                </div>

                <div className=" ">
                  <button className="btn btn-primary" onClick={submitOutputs} disabled={output_loading}>
                    
                    {t("Save")}

                    <HashLoader
                      color="#fff"
                      loading={output_loading}
                      size={15}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </button>
                </div>
              </div>
              <div className="p-3 position-relative">

              { !cOutput["enabled"] && <div className="sensor_disabled_abs"></div> }

                <div className="d-flex align-items-center">
                  <div className="fw-semibold fs-6 me-2">{t("Output name")} </div>
                  <input
                    type="text"
                    name="name"
                    value={cOutput["name"]}
                    onChange={(e) => outputChange(e)}
                  />
                </div>
                <div
                  className="d-flex flex-wrap gap-4 my-3 p-3 radius-15"
                  style={{ border: "1px solid #bbb" }}
                >
                  <div className="d-flex align-items-center gap-2">
                    <div className="fw-semibold fs-6 me-2">{t("Type of output")}</div>
                    <select
                      name="type"
                      value={cOutput["type"]}
                      onChange={(e) => outputChange(e)}
                    >
                      <option value="1">{t("Switch2")}</option>
                      <option value="2">{t("Button")}</option>
                    </select>
                  </div>
                  {cOutput["type"] && cOutput["type"] == "2" && (
                    <div className="d-flex align-items-center gap-2">
                      <div className="fw-semibold fs-6 me-2">
                      {t("Button duration")}
                      </div>
                      <select
                        name="button-duration"
                        value={cOutput["button-duration"]}
                        onChange={(e) => outputChange(e)}
                      >
                        <option value="1000">1s</option>
                        <option value="500">0.5s</option>
                        <option value="400">0.4s</option>
                        <option value="300">0.3s</option>
                        <option value="200">0.2s</option>
                        <option value="100">0.1s</option>
                      </select>
                    </div>
                  )}
                  {/* {cOutput["type"] && cOutput["type"] == "1" && (
                    <div className="d-flex align-items-center gap-2">
                      <div className="fw-semibold fs-6 me-2">
                      {t("Security situation")}
                      </div>
                      <select
                        name="default-state"
                        value={cOutput["default-state"]}
                        onChange={(e) => outputChange(e)}
                      >
                        <option value="1">ON</option>
                        <option value="0">OFF</option>
                      </select>
                    </div>
                  )} */}

                  <div className="d-flex align-items-center">
                    <div className="fw-semibold fs-6 me-2">{t("Notifications")}</div>
                    <input
                      name="push"
                      onClick={(e) => outputChange(e)}
                      type="checkbox"
                      value={
                        cOutput["notifications"] &&
                        cOutput["notifications"]["push"]
                      }
                      checked={
                        cOutput["notifications"] &&
                        cOutput["notifications"]["push"]
                      }
                    />
                  </div>
                </div>

                <div
                  className="my-3 p-3 radius-15"
                  style={{ border: "1px solid #bbb" }}
                >
                  <div className="d-flex gap-3 justify-content-center align-items-center">
                    <div className="fw-semibold fs-5 me-2">{t("Timers")}</div>
                    <button className="btn btn-primary" onClick={addTimer}>
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  </div>

                  {timerHandler && timerHandler["show"] && (
                    <AddTimer
                      device={params.deviceId}
                      output={cOutput["id"]}
                      type={timerHandler["type"]}
                      timer={timerHandler["timer"]}
                      child={timerHandler["child"]}
                      setTimerHandler={setTimerHandler}
                    />
                  )}

                  <div className="d-flex flex-column">
                    <ShowTimers
                      type="settings"
                      device={params.deviceId}
                      output={cOutput["id"]}
                    />
                  </div>
                </div>

                <div
                  className="my-3 p-3 radius-15"
                  style={{ border: "1px solid #bbb" }}
                >
                  <div className="d-flex gap-3 justify-content-center align-items-center">
                    <div className="fw-semibold fs-5 me-2">
                    {t("Planned actions")}
                    </div>
                    <button className="btn btn-primary" onClick={addCalentar}>
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  </div>
                  {calendarHandler && calendarHandler["show"] && (
                    <CalendarForm
                      device={params.deviceId}
                      output={cOutput["id"]}
                      type={calendarHandler["type"]}
                      calendar={calendarHandler["calendar"]}
                      child={calendarHandler["child"]}
                      setCalendarHandler={setCalendarHandler}
                    />
                  )}
                  <div className="d-flex flex-column">
                    <ShowCalendar
                      type="settings"
                      device={params.deviceId}
                      output={cOutput["id"]}
                    />
                  </div>
                </div>

                <div
                  className="my-3 p-3 radius-15"
                  style={{ border: "1px solid #bbb" }}
                >
                  <div className="d-flex gap-3 justify-content-center align-items-center">
                    <div className="fw-semibold fs-5 me-2">
                    {t("Weekly program")}
                    </div>
                    <button className="btn btn-primary" onClick={addSchedule}>
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  </div>

                  {scheduleHandler && scheduleHandler["show"] && (
                    <ScheduleForm
                      device={params.deviceId}
                      output={cOutput["id"]}
                      type={scheduleHandler["type"]}
                      calendar={scheduleHandler["calendar"]}
                      child={scheduleHandler["child"]}
                      setScheduleHandler={setScheduleHandler}
                    />
                  )}

                  <div className="d-flex flex-column">
                    <ShowSchedule
                      type="settings"
                      device={params.deviceId}
                      output={cOutput["id"]}
                    />
                  </div>
                </div>

                
              </div>
            </div>
          </div>
          }
         
          {/* OUTPUT END */}
        </div>
      </div>
    </>
  );
};

export default Settings;
