import logo from "../static/images/logo.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useEffect, useState } from "react";
import UserProvider from "../store/user-context";
import { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faBars,
  faBell,
  faCoins,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import LanguageSelect from "./LanguageSelect";
import { useTranslation } from "react-i18next";

const Header = () => {
  const [userName, setUserName] = useState("Not set");
  const userCtx = useContext(UserProvider);
  const [notifications, setNotifications] = useState([]);
  const [credits, set_credits] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    getUsername();
  }, []);

  useEffect(() => {
    let temp = {};
    for (let device in userCtx.notifications) {
      for (let notification in userCtx.notifications[device]) {
        if (userCtx.notifications[device][notification]["read"] === false) {
          temp[notification] = {
            ...userCtx.notifications[device][notification],
            device: device,
          };
        }
      }
    }

    setNotifications(sortObjectOfObjects(temp, "timestamp"));
    return () => {
      setNotifications([]);
    };
  }, [userCtx.notifications]);

  function sortObjectOfObjects(obj, field) {
    const sortedArray = Object.entries(obj)
      .map(([key, value]) => ({ key, ...value }))
      .sort((a, b) => (a[field] < b[field] ? 1 : -1));
    return sortedArray;
  }

  const getUsername = () => {
    fetch(`${userCtx.serverHost}api/user/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.flag) {
          let username = data.username != "" ? data.username : "Unknown";
          setUserName(username);
          if (data["credits"]) {
            set_credits(data["credits"]);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const logout = () => {
    fetch(`${userCtx.serverHost}api/logout/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.flag) {
          userCtx.onLogout();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setNotificationToReaded = async (e) => {
    let device = e.target.getAttribute("data-device");
    let key = e.target.getAttribute("data-key");

    let response = await fetch(
      `${userCtx.serverHost}api/setNotificationToReaded/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": userCtx.csrf,
        },
        body: JSON.stringify({
          device: device,
          key: notifications[key]["key"],
        }),
        credentials: "include",
      }
    ).then((res) => res.json());

    if (response["flag"]) {
      toast(t("The status of the notification changed to read"));

      let temp = [...notifications];
      temp.splice(key, 1);
      setNotifications(temp);
    } else {
      toast(t("Something went wrong, please try again later"));
    }
  };

  const deleteNotifications = async (e) => {
    let devices =
      userCtx.devicesAll["devices"].length > 0
        ? userCtx.devicesAll["devices"]
        : [];
    let response = await fetch(
      `${userCtx.serverHost}api/deleteNotifications/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": userCtx.csrf,
        },
        body: JSON.stringify({
          devices: devices,
        }),
        credentials: "include",
      }
    ).then((res) => res.json());

    if (response["flag"]) {
      toast(t("Notifications have been cleared"));

      setNotifications([]);
    } else {
      toast(t("Something went wrong, please try again later"));
    }
  };

  return (
    <>
      {/* {[false, 'sm', 'md', 'lg', 'xl', 'xxl'].map((expand) => ( */}
      {["md"].map((expand) => (
        <Navbar key={expand} expand={expand} className="header_container py-2">
          <div className="d-flex flex-wrap align-items-center gap-2 w-100">
            <Navbar.Brand className="me-auto">
              <img className="header_img" src={logo} alt={logo}></img>
            </Navbar.Brand>
            <div className=" d-flex flex-grow-1 justify-content-end pe-md-3 gap-2">
              <LanguageSelect />

              <div className="d-flex align-items-center text-black">
                <FontAwesomeIcon icon={faCoins} />
                <div className=" text-danger ms-2 fw-bold ">{credits}</div>
              </div>

              <div className="position-relative notificationsContainer">
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon icon={faBell} />
                  <div className="notificationsAbsoluteCounter text-danger ms-2">
                    {Object.keys(notifications).length}
                  </div>
                </div>
                <div className="notificationsShowAbsoluteCounter boxShadow">
                  <div className="text-center">
                    <button
                      onClick={deleteNotifications}
                      className="btn btn-link text-danger p-2"
                    >
                      <FontAwesomeIcon
                        icon={faTrashCan}
                        style={{ marginRight: "15px" }}
                      />
                      {t("Clean notifications")}
                    </button>
                  </div>
                  {Object.keys(notifications).map((notification) => {
                    let time = new Date(
                      parseInt(notifications[notification]["timestamp"])
                    );
                    return (
                      <div className="notificationItem d-flex flex-column gap-1">
                        <div className="notificationItemTitle">
                          {notifications[notification]["title"]}
                        </div>
                        <div className="notificationItemMessage">
                          {notifications[notification]["message"]}
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-2">
                          <div className="notificationDate text-secondary">
                            {userCtx.getFinalDate(time)}
                          </div>

                          <div className="notificationRead">
                            <span>{t("Read")}</span>
                            <input
                              onChange={setNotificationToReaded}
                              type="checkbox"
                              checked={false}
                              data-device={
                                notifications[notification]["device"]
                              }
                              data-key={notification}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <Navbar.Brand className="me-auto">
              <button onClick={userCtx.toggleMenu} className="menuToggler">
                <FontAwesomeIcon icon={faBars} className="my-auto" />
              </button>
            </Navbar.Brand>

            <div className="header_user_info">
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
                className="flex-grow-0"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title
                    id={`offcanvasNavbarLabel-expand-${expand}`}
                  ></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end pe-3 gap-3">
                    <NavDropdown
                      className="user"
                      title={userName}
                      id={`offcanvasNavbarDropdown-expand-${expand}`}
                    >
                      <NavDropdown.Item onClick={logout}>
                        {t("Logout")}
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </div>
          </div>
        </Navbar>
      ))}
    </>
  );
};

export default Header;
