import { useContext, useState } from "react";
import UserProvider from "../../store/user-context";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faUnlock } from "@fortawesome/free-solid-svg-icons";
import HashLoader from "react-spinners/HashLoader";

const OutputLock = (props) => {
  const [loading, set_loading] = useState(false);
  const userCtx = useContext(UserProvider);

  const toggleLockedOutput = () => {
    set_loading(true);
    let payload = {};

    payload["topic"] = "devices-outputs";
    payload["device"] = props.device;
    payload["sensor"] = props.output;
    payload["values"] = {};
    payload["values"]["locked"] = !props.locked;

    fetch(`${userCtx.serverHost}api/sendMqtt/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
      body: JSON.stringify({
        topic: "deviceSettings",
        payload: payload,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        set_loading(false);
      })
      .catch((res) => {
        toast("Something went wrong, please try again later");
        set_loading(false);
      });
  };

  return (
    <>
      {loading ? (
        <HashLoader
          color="#04cc21"
          loading={loading}
          size={15}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <button
          className={`btn ${
            props.locked ? "btn-success" : "btn-danger"
          }  px-2 pt-1`}
          onClick={toggleLockedOutput}
        >
          <FontAwesomeIcon
            icon={props.locked ? faUnlock : faLock}
          />
        </button>
      )}
    </>
  );
};

export default OutputLock;
