import classes from "./AnalogInput.module.css";
import ProgressBar from "react-customizable-progressbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

function getColor(value) {
  var hue = ((1 - value) * 140).toString(10);
  return ["hsl(", hue, ",100%,46%)"].join("");
}

let symbol = ["", "°C", "%", "bar", "m"];

const AnalogInput = (props) => {
  let progress =
    100 / ((props.max - props.min) / (parseFloat(props.value) - props.min));
  if (progress < 0) {
    progress = 0;
  } else if (progress > 100) {
    progress = 100;
  }

  let color = getColor(progress / 100);

  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div className={classes.AnalogInputBox}>
        {parseFloat(props.value) === -999 || isNaN(parseFloat(props.value)) ? (
          <div className={classes.errorContainer}>
            <span className="fs-4 fw-bold">Error</span>
          </div>
        ) : (
          <>
            <ProgressBar
              className={classes.rcp}
              radius={100}
              progress={progress}
              strokeWidth={28}
              strokeColor={color}
              initialAnimation
              initialAnimationDelay={200}
              strokeLinecap="butt"
              trackStrokeWidth={14}
              trackStrokeLinecap="butt"
              cut={120}
              rotate={-210}
            />
            <div className={classes.indicator}>
              <div className="d-flex flex-column ">
                <div
                  className={
                    progress === 100 || progress === 0
                      ? "fw-bold text-danger text-center"
                      : "fw-semibold text-center"
                  }
                >
                  {parseFloat(props.value)}
                </div>
                <div
                  className="text-center"
                  style={{ fontWeight: "600", color: "#c7c7c7" }}
                >
                  {props.symbol >= 1 ? (
                    <span>
                      {symbol[props.symbol]}
                    </span>
                  ) : (
                    <span style={{ opacity: 0 }} >.</span>
                  )}
                </div>
              </div>
            </div>

            <div className="d-flex mt-3 tinyText ">
              <span>{props.min}</span>
              <span>{props.max}</span>
            </div>
          </>
        )}
      </div>

      <div>{props.name}</div>
    </div>
  );
};

export default AnalogInput;
