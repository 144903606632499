import { useEffect, useState, useContext } from "react"
import { toast } from 'react-toastify';
import UserProvider  from "../../store/user-context";
import "react-input-range/lib/css/index.css"
import InputRange from 'react-input-range';
import ShowAlert from "./ShowAlert";
import { useTranslation } from "react-i18next";


const AddTimer = (props) => {
  let initialTimer = {
    "output" : "",
    "hour" : "",
    "min" : "",
    "state" : "1",
    "enabled" : true,
    "needsFetching": true
  }
  const [timer, setTimer] = useState(initialTimer)
  const userCtx = useContext(UserProvider)
  const { t } = useTranslation();
  


  useEffect(() => {
    if( props['type']  === 'edit' && timer['needsFetching'] ){

      let time = parseInt(props['timer']['time'])
      let date = new Date(time);
      let currentTimestamp = new Date(props['timer']['initTime']);
      let timerMinutes = ( date.getHours() * 60 ) + date.getMinutes()
      let cMinutes = ( currentTimestamp.getHours() * 60 ) + currentTimestamp.getMinutes()
      let difMinutes =  timerMinutes - cMinutes
      let hour = Math.floor(difMinutes/60).toString()
      let minute = (difMinutes%60).toString()

     

      initialTimer = {
        "output" : props['timer']['output'],
        "hour" : hour,
        "min" : minute,
        "state" : props['timer']['state'] ? "1" : "0",
        "enabled" : props['timer']['enabled'],
        "needsFetching": false
      }
      setTimer(initialTimer)
    }else if (props['type']  === 'add' && timer['needsFetching']){
      initialTimer = {
        "output" : "",
        "hour" : "",
        "min" : "",
        "state" : "1",
        "enabled" : true,
        "needsFetching": false
      }

      setTimer(initialTimer)
    }
  },[props])

  const handleTimer = (e) => {
    let name = e.target.name
    let value = e.target.value
    let temp = {...timer}

    
    if ( name === 'hour'){
      value = parseInt(value) <= 23 || !value ?  value : '23'
    }
    else if ( name === 'min'){
      value = parseInt(value) <= 59 || !value ?  value : '59'
    }

    if( name === 'enabled' ){
      value =  (value === 'true') ? false : true
    }

    temp[name] = value
    setTimer(temp)
  }

  // useEffect(() => {
  //   console.log(timer)
  // },[timer])

  const createTimestamp = (hour, minute) => {
    var date = new Date();
    date.setHours(hour);
    date.setMinutes(minute);
    return date.getTime();
}

function addTimeToCurrent(hour, minutes) {
  const currentTimestamp = Date.now();
  const hourMs = hour * 60 * 60 * 1000;
  const minuteMs = minutes * 60 * 1000;
  const newTimestamp = currentTimestamp + hourMs + minuteMs;
  return newTimestamp;
}

  const addTimer = () =>{
    if(userCtx.isDemo){
      toast(t("There is no capability to save actions in a user demo"));
      return
    }
    
    if( !parseInt(timer['hour'])  && !parseInt(timer['min']) ){
      toast(t("Please enter expected time"));
    }else{
      let tempTimer = {...timer} 

      tempTimer['hour'] = parseInt(tempTimer['hour']) ? parseInt(tempTimer['hour']) : 0
      tempTimer['min'] = parseInt(tempTimer['min']) ? parseInt(tempTimer['min']) : 0
      tempTimer['state'] = tempTimer['state'] === '1' ? true : false

      let timeStamp = addTimeToCurrent(tempTimer['hour'],tempTimer['min'] )

      let json = {
        "output" : props.output,
        "time": timeStamp,
        "initTime": Date.now(),
        "state": tempTimer['state'],
        "enabled" : tempTimer['enabled']
      }
      let topic 
      let message
      if( props['type']  === 'edit' ){

        topic = 'deviceSettings'
        message =
        {
          "device" : props.device,
          "topic"  : "output-timers",
          "values" : {}
        }
        message["values"][props['child']] = {}
        message["values"][props['child']] = json
        
      }else{

        topic = 'addTimer'
        message =
        {
          "device" : props.device,
          "topic"  : "output-timers",
          "values" : json
        }
      }
       
      fetch(`${userCtx.serverHost}api/sendMqtt/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": userCtx.csrf,
        },
        credentials: "include",
        body: JSON.stringify({
          topic : topic,
          payload : message
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if( data['flag'] ){
            toast(t("Action was successfully added"));

            setTimeout(function() {
              props.setTimerHandler((previus) => ({...previus, 'show' : false}))
            }, 500);

          }else{
            toast(t("Something went wrong."));
          }
        });
    }
  }

  return(
    <>
      <ShowAlert />

      <div className="fixedContainer">
        <div className="alertContainer fw-semibold fs-6">
          <div className="d-flex justify-content-center align-items-center">
            <div className="fs-4 text-secondary" >{t("Timer")}</div>
          </div>        
          <div className="d-flex gap-2 justify-content-center align-items-center top-divider-strong pt-4">
            <div>{t("Active")}</div>
            <input
                type="checkbox"
                name="enabled"
                value={timer['enabled']}
                onChange={(e)=>{handleTimer(e)}}
                checked={timer['enabled']}
              />
          </div>

          <div className="row my-3">
            <div className="col-12">
              <div className="row">
                <div className="col-10">
                  <div className="mb-3">{t("Hours")}</div>
                  <InputRange
                    maxValue={23}
                    minValue={0}
                    value={timer["hour"]}
                    onChange={value => setTimer((previus) => ({...previus, 'hour' : value }))} 
                  
                  />
                </div>
                <div className="col mt-4 text-end">
                    <input 
                      type="number" 
                      name="hour"
                      onChange={(e)=>{handleTimer(e)}}
                      value={timer['hour']}
                    />
                </div>
              </div>
              
            </div>
          </div>

          <div className="row">
            <div className="col-10">
              <div className="mb-3">{t("Minutes")}</div>
              <InputRange
                maxValue={59}
                minValue={0}
                value={timer["min"]}
                onChange={value => setTimer((previus) => ({...previus, 'min' : value }))} 
              
              />
            </div>
            <div className="col mt-4 text-end">
              <input 
                type="number" 
                name="min"
                value={timer['min']}
                onChange={(e)=>{handleTimer(e)}}
              />
            </div>
          </div>
          <div className="d-flex justify-content-center gap-3 mt-3 align-items-center">
            <div className="" >{t("Output status")}</div>
            <select
              name="state"
              value={timer['state']}
              onChange={(e)=>{handleTimer(e)}}
            >
              <option value="1">ON</option>
              <option value="0">OFF</option>
            </select>
          </div>

          <div className="d-flex justify-content-between align-items-center mt-5">
            <button className="btn btn-outline-danger"
              onClick={() => {
                props.setTimerHandler((previus) => ({...previus, 'show' : false}))
              }}
            >{t("Cancel")}</button>
            <button className="btn btn-primary" onClick={addTimer}>{t("Save")}</button>

          </div>
        </div>
      </div>
    </>

    
  )
}

export default AddTimer