import React, { useContext, useEffect, useState } from "react";
import UserProvider from "../../store/user-context";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMicrochip, faGaugeHigh, faPlus,faTrash,faPenToSquare }  from '@fortawesome/free-solid-svg-icons'
import { toast } from "react-toastify";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UserForm from "./UserForm";

const initHandler = {
  "show"  : false,
  "type"  : "add",
  'child' : '',
  'data'  : {}
}

const Profile = () => {
  const userCtx = useContext(UserProvider);
  const [userFormHandler, setUserFormHandler] = useState(initHandler)
  const navigate = useNavigate();
  const [isOrganization, setIsOrganization] = useState(false) 
  const [founder, setFounder] = useState(false)
  const [user, setUser] = useState(false);
  const [companyUsers, setCompanyUsers] = useState([])
  const { t } = useTranslation();
  
  

  useEffect(() => {
    fetchProfile()
  },[])

  const fetchProfile = async () => {
    const response = await fetch(`${userCtx.serverHost}api/fetchProfile/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
    }).then((res) => res.json())


    if( response['flag'] ){
      setUser(response['cUser'])
      if(response['isOrganization']){
        setIsOrganization(response['isOrganization'])
        setFounder(response['founder'])
        setCompanyUsers(response['users'])
      }
    }else{
      toast(t("Unexpected error, please contact the system administrator."));

      navigate("/dashboard/");
    }
  }

  // useEffect(() => {
  //   console.log(organization)
  // },[organization])

  const addUser = () => {
    let temp = {
      "show"  : true,
      "type"  : "add",
      'child' : '',
      'data'  : {}
    }
    setUserFormHandler(temp)
  }


  const editUser = (e) => {
    let key  = e.target.closest('button').getAttribute('data-action')
    let temp = {
      "show"  : true,
      "type"  : "edit",
      'child' : companyUsers[key]['id'],
      'data'  : {}
    }

    temp['data'] = companyUsers[key]
    setUserFormHandler(temp)
  }

  const editMyProfile = (e) => {

    let temp = {
      "show"  : true,
      "type"  : "editMyProfile",
      'child' : user['id'],
      'data'  : user
    }

    setUserFormHandler(temp)
  }

  const removeUser = async (e) => {
    let key  = e.target.closest('button').getAttribute('data-action')

    let response = await fetch(`${userCtx.serverHost}api/deleteUser/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
      body: JSON.stringify(
        {userId : companyUsers[key]['id'] }
      ),
    }).then((res) => res.json())

    toast(response['message'])

    fetchProfile()
  }

  



  return (
    <div className="deviceContainer p-4">

      { user &&
        <div className="border p-2 my-3 bg-white boxShadow rounded">
          <div className="row fw-semibold pb-2 mb-2">
            <div className="col">{`${user['role']}`}</div>
            <div className="col text-end ">
              <button
                className="btn btn-outline-success"
                onClick={editMyProfile}
              >
                <FontAwesomeIcon icon={faPenToSquare} />
              </button>
              </div>
          </div>
          <div className="row">
            <div className="col overflowingEllipsis">{`${user['first-name']} ${user['last-name']}`}</div>
            <div className="col overflowingEllipsis">{`${user['email']}`}</div>
            { user['phone-number'] && 
              <div className="col overflowingEllipsis">{`${user['phone-number']}`}</div>
            }
          </div>
        </div>
      }



      { isOrganization &&
        <>
          <div className="border bg-white boxShadow rounded p-3">
            <div className="d-flex justify-content-between align-items-center">
              <span className="fw-semibold">{t("Users")}</span>
              <div>
                { user['role'] === 'Admin' && 
                  <button className="btn btn-outline-success d-flex gap-2 align-items-center" onClick={addUser}>
                    <FontAwesomeIcon  icon={faPlus} />
                    <span>{t("Add User")}</span>
                  </button>
                }
              </div>
            </div>
            { Object.keys(companyUsers).length > 0 &&
              <div className=" p-2 my-3">
                <div className="row fw-semibold pb-2 mb-2">
                  <div className="col-2">{t("Name")}</div>
                  <div className="col-2">{t("Last name")}</div>
                  <div className="col-4">{t("Email")}</div>
                  <div className="col-4">{t("Role")}</div>
                </div>
                { Object.keys(companyUsers).map((cuser) => {
                  return(
                    <div className="row my-2">
                      <div className="col-2 overflowingEllipsis">{companyUsers[cuser]['first-name']}</div>
                      <div className="col-2 overflowingEllipsis">{companyUsers[cuser]['last-name']}</div>
                      <div className="col-4 overflowingEllipsis">{companyUsers[cuser]['email']}</div>
                      <div className="col-2 overflowingEllipsis">{companyUsers[cuser]['role']}</div>

                      {user['role'] === 'Admin' && 
                        <>
                          <div className="col-1">
                            <button
                              className="btn btn-outline-success"
                              data-action={cuser}
                              onClick={editUser}
                            >
                              <FontAwesomeIcon icon={faPenToSquare} />
                            </button>
                          </div>
                          <div className="col-1">
                            <button
                              className="btn btn-outline-danger"
                              data-action={cuser}
                              onClick={removeUser}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </div>
                        </>
                      }
                      
                    </div>
                  )
                })}
              </div>
            }
          </div>
        </>
      }

      {
          userFormHandler.show &&
          <UserForm 
            type={userFormHandler['type']}
            child={userFormHandler['child']}
            data={userFormHandler['data']}
            setUserFormHandler={setUserFormHandler}
            fetchProfile={fetchProfile}
          />
        }

      
    </div>
  );
};

export default Profile;
