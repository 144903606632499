import { useEffect } from "react"
import Swal from "sweetalert2"

const ShowAlert = () => {

  useEffect(() => {
    Swal.fire(
      {
        icon: 'info',
        title: 'Προειδοποίηση',
        html: 'Παρακαλούμε ελέγξε προσεκτικά τις καθορισμένες ενέργειες όπου εισάγετε. <strong>Σε περίπτωση όπου συμπίπτουν δύο ένεργειες θα υπάρξει απροσδόκητο αποτέλεσμα! </strong> ',
      })
    },[])


  return(<></>)
}


export default ShowAlert