
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage, FcmOptions } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAjkKid1f_WPvOSApQHcqYTUMS6LOkaPo4",
  authDomain: "grabi-controller.firebaseapp.com",
  databaseURL: "https://grabi-controller-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "grabi-controller",
  storageBucket: "grabi-controller.appspot.com",
  messagingSenderId: "1811414146",
  appId: "1:1811414146:web:f632603d29c4a446bbc89b",
  measurementId: "G-P186PL5DTR"
};

initializeApp(firebaseConfig);
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp)

export const getFToken = async () => {
  
  let token = await getToken(messaging, {vapidKey: 'BBR9iUusE28GDFFHMmqvEmVK__G9e2Z_2R485IIpI9vwqzYhVGjTqxvAE49dCq9MbTozITML6ii8PSdJUCJ4S-0'}).then((currentToken) => {
    // console.log(currentToken)
    if (currentToken) {
      return currentToken
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      return false
      // console.log('No registration token available. Request permission to generate one.');
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log(err)
    return false
    // console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });

  return token
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});