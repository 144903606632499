import { useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";
import UserProvider from "../../store/user-context";
import "react-input-range/lib/css/index.css"
import InputRange from 'react-input-range';
import ShowAlert from "./ShowAlert";
import { useTranslation } from "react-i18next";


let week = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const Schedule = (props) => {
  let initialCalendar = {
    startDate: "",
    startHour: "",
    startMin: "",
    stopDate: "",
    stopHour: "",
    stopMin: "",
    repeated: [0,0,0,0,0,0,0],
    state: "1",
    enabled: true,
    needsFetching: true
  };

  const [calendar, setCalendar] = useState(initialCalendar);
  const userCtx = useContext(UserProvider);
  const { t } = useTranslation();

  useEffect(() => {
    if (props["type"] === "edit" &&  calendar['needsFetching'] ) {

      let start = parseInt(props["calendar"]["start"]);
      let startDate = new Date(start);
      let startHour = startDate.getHours().toString();
      let startMin = startDate.getMinutes().toString();
      
      let stop = parseInt(props["calendar"]["stop"]);
      let stopDate = new Date(stop);
      let stopHour = stopDate.getHours().toString();
      let stopMin = stopDate.getMinutes().toString();

      initialCalendar = {
        startDate: start,
        startHour: startHour,
        startMin: startMin,
        stopDate: stop,
        stopHour: stopHour,
        stopMin: stopMin,
        repeated: [0,0,0,0,0,0,0],
        state: props["calendar"]["state"] ? "1" : "0",
        enabled: props["calendar"]["enabled"],
        needsFetching: false 
      };

      initialCalendar['repeated'] =  props["calendar"]['repeat'].split('').map(Number);
      setCalendar(initialCalendar);
    }else if ( props["type"] === "add" &&  calendar['needsFetching'] ){
      let initialCalendar = {
        startDate: "",
        startHour: "",
        startMin: "",
        stopDate: "",
        stopHour: "",
        stopMin: "",
        repeated: [0,0,0,0,0,0,0],
        state: "1",
        enabled: true,
        needsFetching: false
      };
      setCalendar(initialCalendar);
    }

  }, [props]);

  const handleCalendar = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let temp = { ...calendar };

     if ( name === 'startHour' || name === 'stopHour'){
      temp[name] = parseInt(value) <= 23 || !value ?  value : '23'
    }
    else if ( name === 'startMin' || name === 'stopMin'){
      temp[name] = parseInt(value) <= 59 || !value ?  value : '59'
    }
    else if (name === "repeated") {
      value = value === "true" ? false : true;
      let index  = e.target.getAttribute('data-index')
      temp['repeated'][index] = value ? 1 : 0
    }
    else if ( name === "enabled"){
      value =  (value === 'true') ? false : true
      temp[name] = value;
    }else{
      temp[name] = value;
    }

    setCalendar(temp);
  };

  // useEffect(() => {
  //   console.log(calendar)
  // },[calendar])

  const createTimestamp = (hour, minute) => {
    var date = new Date();
    date.setHours(hour);
    date.setMinutes(minute);
    return date.getTime();
  };


  const addCalendar = () => {
    if(userCtx.isDemo){
      toast(t("There is no capability to save actions in a user demo"));
      return
    }
    
    let tempCalendar = {...calendar}
    if (tempCalendar["startHour"] === "" || tempCalendar["stopHour"] === "" ) {
      toast(t("Please enter time"));
    } 
    else if ( !tempCalendar['repeated'].includes(1)){
      toast(t("Please enter day"));
    }
    
    else {
      let json
      json = {}
      
      json['start'] = createTimestamp(tempCalendar["startHour"],tempCalendar["startMin"])
      json['stop'] = createTimestamp(tempCalendar["stopHour"],tempCalendar["stopMin"])
      json['repeat'] = tempCalendar['repeated'].join("").toString()



      json['output'] = props.output
      json['state'] = (parseInt(tempCalendar['state']) === 1 ) ? true : false 
      json['enabled'] = tempCalendar['enabled']

      let topic;
      let message;
      if (props["type"] === "edit") {
        topic = "deviceSettings";
        message = {
          device: props.device,
          topic: "device-calendar",
          values: {},
        };
        message["values"][props["child"]] = {};
        message["values"][props["child"]] = json;
      } else {
        topic = "addCalendar";
        message = {
          device: props.device,
          topic: "device-calendar",
          values: json,
        };
      }

      fetch(`${userCtx.serverHost}api/sendMqtt/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": userCtx.csrf,
        },
        credentials: "include",
        body: JSON.stringify({
          topic : topic,
          payload : message
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if( data['flag'] ){
            toast(t("Action was successfully added"));
            setTimeout(function() {
              props.setScheduleHandler((previus) => ({...previus,show: false}));
            }, 500);

          }else{
            toast(t("Something went wrong."));
          }
        });
    }
  };

  return (
    <>
      <ShowAlert />
      <div className="fixedContainer">
        <div className="alertContainer fw-semibold fs-6">
          <div className="d-flex justify-content-between align-items-center">
            <div className="">{t("Shedule")}</div>
          </div>

          <div className="row">
            
            { calendar && calendar['repeated'] &&
              calendar['repeated'].map((day,i)=> {

                return(
                  <div className="col-6 col-md-4">
                    <div className="d-flex justify-content-between align-items-center py-2 gap-2">
                      <div>{t(week[i])}</div>
                      <input
                        data-index={i}
                        type="checkbox"
                        onChange={handleCalendar}
                        name="repeated"

                        checked={calendar['repeated'][i] === 1 ? true : false}
                        value={calendar['repeated'][i] === 1 ? true : false}
                      />
                    </div>
                    
                  </div>
                )
              } )
            }

          </div>
          <div>
            <div>{t("Starts")}</div>
            <div className="row ">
              <div className="col-10">
                <div className="mb-3">{t("Hour")}</div>
                <InputRange
                  maxValue={23}
                  minValue={0}
                  value={calendar["startHour"]}
                  onChange={(value) =>
                    setCalendar((previus) => ({ ...previus, startHour: value }))
                  }
                />
              </div>
              <div className="col-2 mt-4 text-end">
                <input
                  type="number"
                  name="startHour"
                  onChange={(e) => {
                    handleCalendar(e);
                  }}
                  value={calendar["startHour"]}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-10">
                <div className="mb-3">{t("Minute")}</div>
                <InputRange
                  maxValue={59}
                  minValue={0}
                  value={calendar["startMin"]}
                  onChange={(value) =>
                    setCalendar((previus) => ({ ...previus, startMin: value }))
                  }
                />
              </div>
              <div className="col-2 mt-4 text-end">
                <input
                  type="number"
                  name="startMin"
                  value={calendar["startMin"]}
                  onChange={(e) => {
                    handleCalendar(e);
                  }}
                />
              </div>
            </div>
          </div>
          <div>
            <div>{t("Stops")}</div>
            <div className="row ">
              <div className="col-10">
                <div className="mb-3">{t("Hour")}</div>
                <InputRange
                  maxValue={23}
                  minValue={0}
                  value={calendar["stopHour"]}
                  onChange={(value) =>
                    setCalendar((previus) => ({ ...previus, stopHour: value }))
                  }
                />
              </div>
              <div className="col-2 mt-4 text-end">
                <input
                  type="number"
                  name="stopHour"
                  onChange={(e) => {
                    handleCalendar(e);
                  }}
                  value={calendar["stopHour"]}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-10">
                <div className="mb-3">{t("Minute")}</div>
                <InputRange
                  maxValue={59}
                  minValue={0}
                  value={calendar["stopMin"]}
                  onChange={(value) =>
                    setCalendar((previus) => ({ ...previus, stopMin: value }))
                  }
                />
              </div>
              <div className="col-2 mt-4 text-end">
                <input
                  type="number"
                  name="stopMin"
                  value={calendar["stopMin"]}
                  onChange={(e) => {
                    handleCalendar(e);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center">
            <div className="">{t("Status")}</div>
            <select
              name="state"
              value={calendar["state"]}
              onChange={(e) => {
                handleCalendar(e);
              }}
            >
              <option value="1">ON</option>
              <option value="0">OFF</option>
            </select>
          </div>

          <div className="d-flex gap-2 align-items-center">
            <div className="">{t("Active")}</div>
            <input
              type="checkbox"
              name="enabled"
              value={calendar["enabled"]}
              onChange={(e) => {
                handleCalendar(e);
              }}
              checked={calendar["enabled"]}
            />
          </div>

          <div className="d-flex justify-content-between align-items-center">
            <button
              className="btn btn-outline-danger"
              onClick={() => {
                props.setScheduleHandler((previus) => ({
                  ...previus,
                  show: false
                }));
              }}
            >
              {t("Cancel")}
            </button>
            <button className="btn btn-primary" onClick={addCalendar}>
              {t("Save")}
            </button>
          </div>
        </div>
      </div>
    </>
    
  );
};

export default Schedule;
