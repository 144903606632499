import { useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";
import UserProvider from "../../store/user-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import ScheduleForm from "./ScheduleForm";
import { useTranslation } from "react-i18next";

let week = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const initschedule = {
  show: false,
  type: "edit",
  calendar: {},
  child: "",
};

const ShowCalendar = (props) => {
  const [data, setData] = useState([]);
  const ctx = useContext(UserProvider);
  const [scheduleHandler, setScheduleHandler] = useState(initschedule);
  const { t } = useTranslation();

  useEffect(() => {
    try {
      if (
        props["device"] &&
        Object.keys(ctx.schedule).length &&
        Object.keys(ctx.schedule[props["device"]]).length
      ) {
        fetchData();
      }
    } catch (error) {}

    return () => {
      setData({});
    };
  }, [props, ctx.schedule]);

  const fetchData = () => {
    if (props["type"] === "settings") {
      let temp = [];
      temp = [...ctx.schedule[props["device"]]];
      setData(temp);
    }
  };

  const editSchedule = (e) => {
    let schedule = e.target.closest("button").getAttribute("data-action");
    let temp = {
      show: true,
      type: "edit",
      calendar: data[schedule],
      child: data[schedule]["key"],
    };
    setScheduleHandler(temp);
  };

  const removeSchedule = (e) => {
    if(ctx.isDemo){
      toast(t("There is no capability to save actions in a user demo"));
      return
    }

    let schedule = e.target.closest("button").getAttribute("data-action");
    let key = data[schedule]["key"];

    if (key) {
      let json = {
        topic: "device-calendar",
        device: props["device"],
        values: [key],
      };

      fetch(`${ctx.serverHost}api/sendMqtt/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": ctx.csrf,
        },
        credentials: "include",
        body: JSON.stringify({
          topic: "deviceSettingsDelete",
          payload: json,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data["flag"]) {
            
            toast(t("Deleted successfully"));
            // let temp = [ ...schedules ]
            // temp.splice(schedule, 1)
            // setSchedules(temp)
          } else {
            toast(t("Something went wrong."));

          }
        });
    } else {
      toast(t("Something went wrong."));

    }
  };

  return (
    <div className="d-flex flex-column">
      {data &&
        data.length > 0 &&
        data.map((calendar, key) => {
          let keyDays = "";

          let days = calendar["repeat"].split("");
          for (let i in days) {
            if (days[i] === "1") {
              keyDays += ` ${t(week[i])},`;
            }
          }
          keyDays = keyDays.slice(0, -1);
          keyDays += ".";

          let start = parseInt(calendar["start"]);
          let startDate = new Date(start);
          let starHour = startDate.getHours().toString();
          let starMinute = startDate.getMinutes().toString();
          starHour = starHour.length === 1 ? `0${starHour}` : starHour;
          starMinute = starMinute.length === 1 ? `0${starMinute}` : starMinute;

          let stop = parseInt(calendar["stop"]);
          let stopDate = new Date(stop);
          let stopHour = stopDate.getHours().toString();
          let stopMinute = stopDate.getMinutes().toString();
          stopHour = stopHour.length === 1 ? `0${stopHour}` : stopHour;
          stopMinute = stopMinute.length === 1 ? `0${stopMinute}` : stopMinute;

          return (
            <div className="row fw-semibold fs-6 my-2 align-items-center top-divider-strong pt-3 mt-3" key={key}>
              <div className="col-12 text-info">Επανάληψη:{keyDays}</div>
              <div
                className={`col-2 ${
                  calendar && calendar["enabled"]
                    ? "text-success"
                    : "text-danger"
                }`}
              >
                {calendar && calendar["enabled"] ? t('Active') : t('Inactive')}
              </div>
              <div
                className={`col-2 ${
                  calendar && calendar["state"] ? "text-success" : "text-danger"
                }`}
              >
                {calendar && calendar["state"] ? "ON" : "OFF"}
              </div>
              <div className="col-2">
                <div>{`${starHour} : ${starMinute}`}</div>
              </div>
              <div className="col-2">
                <div>{`${stopHour} : ${stopMinute}`}</div>
              </div>

              <div className="col-2">
                <button
                  className="btn btn-outline-success"
                  data-action={key}
                  onClick={editSchedule}
                >
                  <FontAwesomeIcon icon={faPenToSquare} />
                </button>
              </div>
              <div className="col-2">
                <div className="d-flex">
                  <button
                    className="btn btn-outline-danger"
                    data-action={key}
                    onClick={removeSchedule}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </div>
              </div>
            </div>
          );
        })}

      {scheduleHandler && scheduleHandler["show"] && (
        <ScheduleForm
          device={props["device"]}
          output={props["output"]}
          type={scheduleHandler["type"]}
          calendar={scheduleHandler["calendar"]}
          child={scheduleHandler["child"]}
          setScheduleHandler={setScheduleHandler}
        />
      )}

      
    </div>
  );
};

export default ShowCalendar;
