import { useState, useEffect, useContext } from "react";
import UserProvider from "../../store/user-context";
import classes from "./Login.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../../static/images/logo.png";
import { ToastContainer, toast } from 'react-toastify';
import PuffLoader from "react-spinners/PuffLoader";
import LanguageSelect from "../../UI/LanguageSelect";
import { useTranslation } from "react-i18next";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const userCtx = useContext(UserProvider);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false)
  const [loading_demo, set_loading_demo] = useState(false)


  useEffect(() => {
    if( props && props.force === 'demo' && userCtx.csrf){
      demoLogin();
    }
  },[props, userCtx.csrf])

 
  const checkForm = () => {
    let flag = true
    if(!email || !password ){
      toast(t("Fill in your login details"));
      flag = false
    }

    return flag
  }
  const apiLogin = (event) => {
    event.preventDefault();
    setLoading(true)
    if( !loading && checkForm() ){
      fetch(`${userCtx.serverHost}api/login/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": userCtx.csrf,
        },
        credentials: "include",
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.response) {
            userCtx.onLogin();
            userCtx.getSession();
            navigate("/dashboard/");
          } else {
            toast(data['message'])
          }
          setLoading(false)
        })
        .catch((err) => {
          console.log(err);
          toast(t("Server problem, please refresh the page and try again."));
          setLoading(false)
        });
    }else{
      setLoading(false)
    }
    
  };
  const demoLogin = (event = null) => {
    if( event ){
      event.preventDefault();
    }

    set_loading_demo(true)
    if( !loading_demo ){
      fetch(`${userCtx.serverHost}api/demo/login/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": userCtx.csrf,
        },
        credentials: "include",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.response) {
            userCtx.onLogin();
            userCtx.getSession();
            userCtx.setIsDemo(true);
            
            navigate("/dashboard/");
          } else {
            toast(data['message'])
          }
          set_loading_demo(false)
        })
        .catch((err) => {
          console.log(err);
          toast(t("Server problem, please refresh the page and try again."));
          set_loading_demo(false)
        });
    }else{
      set_loading_demo(false)
    }
    
  };

  return (
    <div className={classes.container}>
      <img className={classes.header_img} src={logo} alt={logo}/>
      <div className={classes.formContainer}>
        <LanguageSelect />
      
      <div className="text-center fs-5 fw-bold">
      {t('Sign in to your account')}
       </div>
        <div
          className={`mb-3 mt-4 d-flex flex-column justify-content-center ${classes.inputContainer} `}
        >
          <input
            type="text"
            placeholder="Email"
            value={email}
            onInput={(e) => setEmail(e.target.value)}
          />
           <input
            placeholder="Password"
            type="password"
            value={password}
            onInput={(e) => setPassword(e.target.value)}
            className="mt-2"
          />
        </div>
       
        <button className={`custom-btn-blue d-flex align-items-center justify-content-center mt-4`} disabled={loading || loading_demo} onClick={apiLogin}>
          <span>{t('Login')} </span>
          <PuffLoader
            color="#111344"
            loading={loading}
            size={35}
            aria-label="Loading Spinner"
            data-testid="loader"
            className="ms-3"
          />
        </button>

        <button 
          className={`custom-btn-blue d-flex align-items-center justify-content-center mt-4`} 
          disabled={loading || loading_demo} 
          onClick={demoLogin}
        >
          <span> {t('Login as a demo')}</span>
          <PuffLoader
            color="#111344"
            loading={loading_demo}
            size={35}
            aria-label="Loading Spinner"
            data-testid="loader"
            className="ms-3"
          />
        </button>
      </div>        
          <NavLink 
            className={`btn btn-link p-2 mt-4 fs-5`}
            to={"/register"}
          >
            {t('Register')} 
          </NavLink>
          <NavLink 
              className={`btn btn-link fs-5`}
              to={"/forgotpassword"}
            >
            {t('Forgot my password')}
          </NavLink>
    </div>
  );
};

export default Login;
