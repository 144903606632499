import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";

const GeneralPopup = (props) => {
  
  const handleClick = (e) => {
    if (props.popUpIsShow && !e.target.closest(".generalPopupWrapperContainer")) {
      props.setPopUpIsShow(false)
    }
  };

  return(
    <div className={`${!props.popUpIsShow && 'd-none'} generalPopupWrapperFixed`} onClick={handleClick}>
      <div className="p-3 position-relative">
        <div className="position-relative">
            <button 
              className="btn btn-danger" 
              onClick={()=>{props.setPopUpIsShow(false)}}
              style={{ position: "absolute", top : "-41px", right : "0" }}
              >
            <FontAwesomeIcon icon={faX} /> 
          </button>
            <div className="generalPopupWrapperContainer">            
              <div className="d-flex justify-content-center pb-2">
                  <h5>{props.title}</h5>
              </div>
              <div className="d-flex flex-column align-items-center ">
                {props.children}
              </div>
            </div> 
        </div>
      </div>
    </div>
  )
}


export default GeneralPopup