
import { toast } from 'react-toastify';
export const successToast = (msg) => {
    toast.success( msg, {
        position: "bottom-left",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
       // draggable: true,
        progress: undefined,
    })
}

export const errorToast = (msg, position = "bottom-left" ) => {
    toast.error( msg, {
        position: position,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
       // draggable: true,
        progress: undefined,
    })
}

export const infoToast = (msg) => {
    toast.info(msg, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 1,
        });
}
