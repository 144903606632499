import { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowsRotate}  from '@fortawesome/free-solid-svg-icons';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useTranslation } from "react-i18next";
import HashLoader from "react-spinners/HashLoader";


const getDateGR = (date) =>{
  date = new Date(parseInt(date))
  let newDate = {}
  newDate['year']    = date.getFullYear()
  newDate['month']   = ( parseFloat(date.getMonth()+1 ) < 10 ) ? `0${date.getMonth()+1}` : date.getMonth()+1
  newDate['date']    = ( parseFloat(date.getDate()    ) < 10 ) ? `0${date.getDate()}`    : date.getDate()
  let finalDay = `${newDate['date']}/${newDate['month']}/${newDate['year']}`
  return finalDay
}


const getTime = (date) =>{
  date = new Date(parseInt(date))
  let newDate = {}
  newDate['hours']   = ( parseFloat(date.getHours()   ) < 10 ) ? `0${date.getHours()}`   : date.getHours()
  newDate['minutes'] = ( parseFloat(date.getMinutes() ) < 10 ) ? `0${date.getMinutes()}` : date.getMinutes()
  let finalDay = `${newDate['hours']}:${newDate['minutes']}`
  return finalDay
}


  
  




const DigitalLogs = (props) => {

  const [cSensor, setCSensor] = useState("")
  const [data, setData] = useState([])
  const { t } = useTranslation();
  const [fetching_logs, set_fetching_logs] = useState(false);


  const fetch_logs = async () =>{
    set_fetching_logs(true)
    await props.updateLogs()
    set_fetching_logs(false)
  }
  
  useEffect(() => {
    // console.log(props)
    // console.log(cSensor)
    if ( props['type'] === 'inputs' ) {
      if( props['logs'][cSensor] &&  Object.keys(props['logs'][cSensor]) ){
        let keys = Object.keys(props['logs'][cSensor])
        keys.sort((a, b) => b - a);
        for(let key of keys){
          let value = ( parseInt(props['logs'][cSensor][key]) === 1 ) ? 'ON' : 'OFF'
          setData( (prevFeed) =>[...prevFeed, {'time': key,'date':getDateGR(key), 'hour' :getTime(key), 'value': value}])
        }
      }
    }else if ( props['type'] === 'outputs' ){
      if( props['logs'][cSensor] &&  Object.keys(props['logs'][cSensor]) ){
        let keys = Object.keys(props['logs'][cSensor])
        keys.sort((a, b) => b - a);
        for(let key of keys){
          let value = ( props['logs'][cSensor][key]['value'] ) ? 'ON' : 'OFF'
          setData( (prevFeed) =>[...prevFeed, {'time': key,'date':getDateGR(key), 'hour' :getTime(key), 'value': value}])
        }
      }
    } 
  
    return () => {
      setData([])
    }
  }, [cSensor,props])

  // useEffect(() => {
  //   console.log(data)
  // },[data,props])


  useEffect(() => {
    if ( props['type'] === 'devices' ){

      let data = props['logs']
      setData([])
      let regex = new RegExp('ON.*');
      let keys = Object.keys(data)
      keys.sort((a, b) => b - a);
      for(let key of keys){
        let value = ( data[key].match(regex) ) ? 'ON' : 'OFF'
        setData( (prevFeed) =>[...prevFeed, {'time': key,'date':getDateGR(key), 'hour' :getTime(key), 'value': value}])
      }
    }

  },[props])

  useEffect(() => {
    // console.log(props)
    if( props['keys'] && Object.keys(props['keys']).length > 0 &&  props['type'] !== 'devices' ){
      setCSensor(Object.keys(props['keys'])[0])
    }

    return() => {
      setData([])
      setCSensor("")
    }
  },[props.device])

  function getCurrentDateFormatted() {
    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, '0');
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const year = currentDate.getFullYear();
  
    return `${day}_${month}_${year}`;
  }
  

  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = () => {

    let apiData = [...data]
    for( let i in apiData ){
      delete apiData[i]['time']
    }
    

    let fileName = `${props.name}_${props.keys[cSensor]['name']}_${getCurrentDateFormatted()}`
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const excel = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(excel, fileName + fileExtension);
  };


  return(
    <>
      <div className="cardContainer">
        <div className="d-flex justify-content-center radius-top-left-right-8 cardTitleColor">
          <h3 className="my-2 fs-5">{props.title}</h3>
        </div>

        <div className="d-flex  flex-wrap gap-2 align-items-center justify-content-between py-2 px-3">
          <div className="">
            { props['type'] !== 'devices' && 
              <select className="px-3 py-1 rounded" value={cSensor} onChange={(e) => setCSensor(e.target.value)}>
                <option value="">{t("Select")}</option>
                { Object.keys(props.keys).map((i) => {
                  return(
                    <option value={i}>{props['keys'][i]['name']}</option>
                  )
                }) }
              </select>
            }
          </div>
          <div className="">
            <button className="btn btn-outline-success" 
              onClick={fetch_logs}
              >
                {fetching_logs ? 
                  <HashLoader
                    color="#04cc21"
                    loading={true}
                    size={15}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                :
                  <FontAwesomeIcon  icon={faArrowsRotate}/>
                }

            </button>

            <button className="btn btn-outline-success ms-3" 
              onClick={exportToCSV}
            >
              Excel
            </button>
          </div>
        </div>
        <div className="py-3 px-1">
          <ul className="printList">
            {data && data.length > 0 ?
            data.map( (item) => {
              return(
                <li key={item.time}>
                  <span>{item.hour}</span>
                  <span>{item.date}</span>
                  <span className={`fw-bolder fs-6 ${item.value === 'ON' ? 'text-success' : 'text-danger' } ` }>{item.value}</span> 
                </li>
              )
            }) :
              <li className="text-center">{t("There are no logs.")}</li>
            }
          </ul>
        </div>
        

      </div>
    
    </>
  )
} 

export default DigitalLogs