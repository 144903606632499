import { useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";
import UserProvider from "../../store/user-context";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { el } from "date-fns/locale";
import "react-input-range/lib/css/index.css";
import InputRange from "react-input-range";
import ShowAlert from "./ShowAlert";
import { useTranslation } from "react-i18next";


const Calendar = (props) => {
  let initialCalendar = {
    startDate: "",
    startHour: "",
    startMin: "",
    stopDate: "",
    stopHour: "",
    stopMin: "",
    needsFetching: true,
    state: "1",
    enabled: true,
  };

  const [calendar, setCalendar] = useState(initialCalendar);
  const userCtx = useContext(UserProvider);
  const { t } = useTranslation();

  useEffect(() => {
    if (props["type"] === "edit" && calendar["needsFetching"]) {
      let start = parseInt(props["calendar"]["start"]);
      let startDate = new Date(start);
      let startHour = startDate.getHours().toString();
      let startMin = startDate.getMinutes().toString();
      let stop = parseInt(props["calendar"]["stop"]);
      let stopDate = new Date(stop);
      let stopHour = stopDate.getHours().toString();
      let stopMin = stopDate.getMinutes().toString();

      initialCalendar = {
        startDate: start,
        startHour: startHour,
        startMin: startMin,
        stopDate: stop,
        stopHour: stopHour,
        stopMin: stopMin,
        state: props["calendar"]["state"] ? "1" : "0",
        enabled: props["calendar"]["enabled"],
        needsFetching: false,
      };
      setCalendar(initialCalendar);
    } else if (props["type"] === "add" && calendar["needsFetching"]) {
      initialCalendar = {
        startDate: "",
        startHour: "",
        startMin: "",
        stopDate: "",
        stopHour: "",
        stopMin: "",
        needsFetching: false,
        state: "1",
        enabled: true,
      };
      setCalendar(initialCalendar);
    }
  }, [props]);

  const handleCalendar = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let temp = { ...calendar };

    if (name === "startHour" || name === "stopHour") {
      temp[name] = parseInt(value) <= 23 || !value ? value : "23";
    } else if (name === "startMin" || name === "stopMin") {
      temp[name] = parseInt(value) <= 59 || !value ? value : "59";
    } else if (name === "enabled") {
      value = value === "true" ? false : true;
      temp[name] = value;
    } else {
      temp[name] = value;
    }
    setCalendar(temp);
  };

  useEffect(() => {
    // console.log(calendar);
  }, [calendar]);

  const createDate = (day, hour, minute) => {
    var date = new Date(day);
    date.setHours(hour);
    date.setMinutes(minute);
    return date.getTime();
  };

  const addCalendar = () => {
    let tempCalendar = { ...calendar };

    if(userCtx.isDemo){
      toast(t("There is no capability to save actions in a user demo"));
      return
    }

    if (
      tempCalendar["startHour"] === "" ||
      tempCalendar["stopHour"] === "" ||
      tempCalendar["startHour"] === "NaN" ||
      tempCalendar["stopHour"] === "NaN" ||
      !tempCalendar["startHour"] ||
      !tempCalendar["stopHour"] ||
      tempCalendar["startDate"] === "" ||
      tempCalendar["stopDate"] === ""
    ) {
      toast(t("Please provide all fields"));
    } else {
      let json;
      json = {};
      json["start"] = createDate(
        tempCalendar["startDate"],
        tempCalendar["startHour"],
        tempCalendar["startMin"]
      );
      json["stop"] = createDate(
        tempCalendar["stopDate"],
        tempCalendar["stopHour"],
        tempCalendar["stopMin"]
      );
      json["output"] = props.output;
      json["state"] = parseInt(tempCalendar["state"]) === 1 ? true : false;
      json["enabled"] = tempCalendar["enabled"];

      let topic;
      let message;
      if (props["type"] === "edit") {
        topic = "deviceSettings";
        message = {
          device: props.device,
          topic: "device-calendar",
          values: {},
        };
        message["values"][props["child"]] = {};
        message["values"][props["child"]] = json;
      } else {
        topic = "addCalendar";
        message = {
          device: props.device,
          topic: "device-calendar",
          values: json,
        };
      }

      fetch(`${userCtx.serverHost}api/sendMqtt/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": userCtx.csrf,
        },
        credentials: "include",
        body: JSON.stringify({
          topic: topic,
          payload: message,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data["flag"]) {
            toast(t("Action was successfully added"));
            props.setCalendarHandler((previus) => ({
              ...previus,
              show: false,
            }));
          } else {
            toast(t("Something went wrong."));
          }
        });
    }
  };

  const dateHandler = (e) => {
    const date = new Date(e);
    const timestamp = date.getTime();
    return timestamp;
  };

  return (

    <>
      <ShowAlert />
      <div className="fixedContainer">
        <div className="alertContainer fw-semibold fs-6">
          <div className="d-flex justify-content-center align-items-center">
            <div className="fs-4">{t("Scheduled actions")}</div>
          </div>
          <div className="d-flex gap-2 justify-content-center align-items-center mt-2">
            <div className="">{t("Active")}</div>
            <input
              type="checkbox"
              name="enabled"
              value={calendar["enabled"]}
              onChange={(e) => {
                handleCalendar(e);
              }}
              checked={calendar["enabled"]}
            />
          </div>

          <div
            className="py-4 radius-15 mt-2"
            style={{ border: "1px solid #bbb" }}
          >
            <div className="text-center fs-5">{t("Start of action")}</div>
            <div className="d-flex justify-content-center gap-3 mt-3 align-items-center">
              <div className="">{t("Output status")}</div>
              <select
                name="state"
                value={calendar["state"]}
                onChange={(e) => {
                  handleCalendar(e);
                }}
              >
                <option value="1">ON</option>
                <option value="0">OFF</option>
              </select>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="d-flex flex-row justify-content-center align-items-center">
                  <DayPicker
                    mode="single"
                    selected={new Date(calendar["startDate"])}
                    onSelect={(e) => {
                      setCalendar((previus) => ({
                        ...previus,
                        startDate: dateHandler(e),
                      }));
                    }}
                    locale={el}
                  />
                </div>
              </div>
            </div>

            <div className="row px-3">
              <div className="col-10">
                <div className="mb-3">{t("Hour")}</div>
                <InputRange
                  maxValue={23}
                  minValue={0}
                  value={calendar["startHour"]}
                  onChange={(value) =>
                    setCalendar((previus) => ({ ...previus, startHour: value }))
                  }
                />
              </div>
              <div className="col-2 mt-4 text-end">
                <input
                  type="number"
                  name="startHour"
                  onChange={(e) => {
                    handleCalendar(e);
                  }}
                  value={calendar["startHour"]}
                />
              </div>
            </div>
            <div className="row mt-3 px-3">
              <div className="col-10">
                <div className="mb-3">{t("Minute")}</div>
                <InputRange
                  maxValue={59}
                  minValue={0}
                  value={calendar["startMin"]}
                  onChange={(value) =>
                    setCalendar((previus) => ({ ...previus, startMin: value }))
                  }
                />
              </div>
              <div className="col-2 mt-4 text-end">
                <input
                  type="number"
                  name="startMin"
                  value={calendar["startMin"]}
                  onChange={(e) => {
                    handleCalendar(e);
                  }}
                />
              </div>
            </div>
          </div>
          <div
            className="py-4 radius-15 mt-3"
            style={{ border: "1px solid #bbb" }}
          >
            <div className="text-center fs-5">{t("End of action")}</div>
            <div className="d-flex justify-content-center gap-3 mt-3 align-items-center">
              <div className=""> 
              {t("Output status")}
                { calendar["state"] == '1' ? " OFF" : " ON" }
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="d-flex flex-row justify-content-center align-items-center">
                  <DayPicker
                    selected={new Date(calendar["stopDate"])}
                    mode="single"
                    onSelect={(e) => {
                      setCalendar((previus) => ({
                        ...previus,
                        stopDate: dateHandler(e),
                      }));
                    }}
                    locale={el}
                  />
                </div>
              </div>
            </div>

            <div className="row px-3">
              <div className="col-10">
                <div className="mb-3">{t("Hour")}</div>
                <InputRange
                  maxValue={23}
                  minValue={0}
                  value={calendar["stopHour"]}
                  onChange={(value) =>
                    setCalendar((previus) => ({ ...previus, stopHour: value }))
                  }
                />
              </div>
              <div className="col-2 mt-4 text-end">
                <input
                  type="number"
                  name="stopHour"
                  onChange={(e) => {
                    handleCalendar(e);
                  }}
                  value={calendar["stopHour"]}
                />
              </div>
            </div>
            <div className="row mt-3 px-3">
              <div className="col-10">
                <div className="mb-3">{t("Minute")}</div>
                <InputRange
                  maxValue={59}
                  minValue={0}
                  value={calendar["stopMin"]}
                  onChange={(value) =>
                    setCalendar((previus) => ({ ...previus, stopMin: value }))
                  }
                />
              </div>
              <div className="col-2 mt-4 text-end">
                <input
                  type="number"
                  name="stopMin"
                  value={calendar["stopMin"]}
                  onChange={(e) => {
                    handleCalendar(e);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center mt-4">
            <button
              className="btn btn-outline-danger"
              onClick={() => {
                props.setCalendarHandler((previus) => ({
                  ...previus,
                  show: false,
                }));
              }}
            >
              {t("Cancel")}
            </button>
            <button className="btn btn-primary" onClick={addCalendar}>
              {t("Save")}
            </button>
          </div>
        </div>
      </div>
    </>
    
  );
};

export default Calendar;
