import { useContext, useEffect, useState } from "react"
import UserProvider  from "../../store/user-context";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash,faPenToSquare, faPlus }  from '@fortawesome/free-solid-svg-icons'
import AlertForm from "./AlertForm";
import { useTranslation } from "react-i18next";


const initAlertHandler = {
  "show" : false,
  "type" : "add",
  "alert": {},
  "child" : ""
 }
const Alerts = (props) =>{
  const userCtx = useContext(UserProvider)
  const [alerts, setAlerts] = useState({})
  const [ alertHandler, setAlertHandler ] = useState(initAlertHandler)
  const { t } = useTranslation();
  const [has_credits, set_has_credits] = useState(false)

  useEffect(() => {
    fetch_has_credits()
  },[props])




  const fetch_has_credits = () => {

    fetch(`${userCtx.serverHost}api/has_credits/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
      body: JSON.stringify({
        device : props.device,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if(data['has_credits']){
          set_has_credits(data['has_credits'])
        }
      });

  }


  useEffect(() => {
    if(  userCtx.alerts.hasOwnProperty(props.device) ){
      let input_alerts = {};

      for( let i in userCtx.alerts[props.device] ){

        if(userCtx.alerts[props.device][i]['input-id'] == props.input){
          input_alerts[i] = userCtx.alerts[props.device][i];
        }

      }
      setAlerts(input_alerts)
    }
  },[userCtx.alerts,props])
  
 
  const changeAlertStatus = (e) => {
    if(userCtx.isDemo){
      toast(t("There is no capability to save actions in a user demo"));
      return
    }

    let alert  = e.target.closest('button').getAttribute('data-action')
    
    let temp = {...alerts[alert]}
    temp['active'] = !temp['active']
    let json = 
    {
      "topic":"device-alerts",
      "device": props.device,
      "values": {}
    }
    json['values'][alert] = temp


    fetch(`${userCtx.serverHost}api/sendMqtt/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
      body: JSON.stringify({
        topic : 'deviceSettings',
        payload : json
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if( data['flag'] ){
          toast(t("Status has been changed successfully"));
        }else{
          toast(t("Something went wrong."));
        }
      });
  }



  const removeAlertStatus = (e) => {
    if(userCtx.isDemo){
      toast(t("There is no capability to save actions in a user demo"));
      return
    }
    
    let alert  = e.target.closest('button').getAttribute('data-action')
    if(alert){
      let json = 
      {
        "topic":"device-alerts",
        "device": props.device,
        "values": [alert]
      }
  
      fetch(`${userCtx.serverHost}api/sendMqtt/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": userCtx.csrf,
        },
        credentials: "include",
        body: JSON.stringify({
          topic : 'deviceSettingsDelete',
          payload : json
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if( data['flag'] ){
            toast(t("Deleted successfully"));
          }else{
            toast(t("Something went wrong."));
          }
        });
    }else{

      toast(t("Something went wrong."));
    }
    
  }


  const editAlert = (e) => {
    let alert  = e.target.closest('button').getAttribute('data-action')
    let temp = {
      "show" : true,
      "type" : "edit",
      "alert": alerts[alert],
      "child" : alert
    }
    setAlertHandler(temp)
  }  

  const addAlert = () => {
    let temp = {
      "show" : true,
      "type" : "add",
      "alert": {},
      "child" : ""
    }

    setAlertHandler(temp)
  }


  return(

    <div>
      <div className="d-flex gap-3 justify-content-center align-items-center">
        <div className="fw-semibold fs-5" >Ενέργειες</div>
        <button className="btn btn-primary" onClick={addAlert}>
          <FontAwesomeIcon icon={faPlus}/>
        </button>
      </div>

      { alerts && Object.keys(alerts).length > 0 && Object.keys(alerts).map((key) => {
        let type = alerts[key]['trigger']['type'] ? alerts[key]['trigger']['type'] : undefined
        let min = alerts[key]['trigger']['min'] ? alerts[key]['trigger']['min'] : undefined
        let max = alerts[key]['trigger']['max'] ? alerts[key]['trigger']['max'] : undefined

        let value
        let bValue = false
        if( alerts[key]['trigger']['value'] == 1 ){
          value = "ON"
          bValue = true
        }else if ( alerts[key]['trigger']['value'] == 0 ){
          value = "OFF"
          bValue = false
        }

        let active = alerts[key]['active']
        let actions = []
        if( alerts[key].hasOwnProperty('actions')){
          for( let i in alerts[key]['actions'] ){
            actions.push(alerts[key]['actions'][i])
          }
        }
        

        return(
          <>
            <div className="row mt-3 pt-3 top-divider-strong align-items-center">           
              <div className="col-12 col-xl-8">
                <div className="d-flex gap-3 align-items-center">
                  <span className="fw-semibold fs-6">{t("If")}</span>
                  { type != "1" &&
                    <div className="fw-semibold fs-6">
                      { max &&
                        <div className="d-flex flex-column align-items-center ">
                          <span>{t("over")}</span>
                          <span>{max}</span>
                        </div>
                      }

                      { max && min &&
                        <div className="d-flex flex-column align-items-center ">
                          {t("or")}
                        </div>
                      }
                      
                      { min &&
                        <div className="d-flex flex-column align-items-center ">
                          <span>{t("under")}</span>
                          <span>{min}</span>
                        </div>
                      }
                    </div>
                  }

                  { type == "1" && 
                    <div  className="">
                      <div className="d-flex flex-column align-items-center ">
                        <span className={`fw-bold fs-6 ${bValue ? 'text-success' : 'text-danger' }`}>{value}</span>
                      </div>
                    </div> 
                  }
                  {  actions && actions.length > 0 &&
                    <span className="fw-semibold fs-6">{t("Then")}</span>
                  }
                  
                  {  actions && actions.length > 0 && actions.map((action) => {
                    let device = action['device-name']
                    let output = action['output-name']
                    let state =  action['output-state']

                    let deviceName = userCtx.devicesAll['devices-list'][device] && userCtx.devicesAll['devices-list'][device]['device-name'] ? userCtx.devicesAll['devices-list'][device]['device-name'] : device
                    let outputName = userCtx.devicesAll['devices-outputs'][device] && userCtx.devicesAll['devices-outputs'][device][output]['name'] ? userCtx.devicesAll['devices-outputs'][device][output]['name'] : output
                    
                    return(
                      <div className="d-flex flex-wrap gap-3" >
                          <span className="">{ deviceName ? deviceName : device }</span>
                          <span className="">{ output ? outputName : output }</span>
                          <span className={`fw-bold fs-6 ${state ? 'text-success' : 'text-danger' }`}>
                            { state ? "ON" : "OFF" }
                          </span>
                      </div>
                    )
                  }) }
                </div>
              </div>      
              <div  className="col-12 col-xl-4 mt-2 mt-xl-0">
                <div className="d-flex justify-content-center gap-2">
                    <button className="btn btn-outline-primary" data-action={key} onClick={(e) =>{changeAlertStatus(e)}} >
                      { active ? 'ON' : 'OFF' }
                    </button>

                    <button className="btn btn-outline-success" data-action={key} onClick={(e) =>{editAlert(e)}} >
                      <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                
                    <button className="btn btn-outline-danger" data-action={key} onClick={(e) =>{removeAlertStatus(e)}} >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                </div>
              </div>           
            </div>
          </>
        )
      }) }

      { alertHandler && alertHandler['show'] &&
        <AlertForm 
          has_credits={has_credits}
          type={alertHandler['type']}
          child={alertHandler['child']}
          alert={alertHandler['alert']} 
          setAlertHandler={setAlertHandler} 
          device={props.device} 
          input={props.input} 
        />
      }
    </div>
  )
}


export default Alerts
