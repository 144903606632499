import { useEffect, useState, useContext } from "react";
import classes from "./Switch.module.css";
import UserProvider from "../../store/user-context";
import { toast } from "react-toastify";
import HashLoader from "react-spinners/HashLoader";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { errorToast } from "../../utils/toasts";

const Switch = (props) => {
  const userCtx = useContext(UserProvider);
  const [checked, setChecked] = useState(props.value);
  const [fetching, setFetching] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setChecked(props.value);
    setFetching(false);
  }, [props.value]);

  useEffect(() => {
    const alert = setTimeout(() => {
      if (fetching) {
        toast(t("Device unavailable try again later"));
        reset_switch();
        userCtx.fetchDevices();
        setFetching(false);
      }
    }, 10000);

    return () => {
      clearTimeout(alert);
    };
  }, [fetching]);

  const check_for_events = (e) => {
    let has_event = false;

    if (userCtx.devicesList[props.deviceName]["service"]) {
      handleChange(e);
      return;
    }

    for (let i in userCtx.alerts[props.deviceName]) {
      if (has_event) {
        break;
      }
      if (userCtx.alerts[props.deviceName][i]["active"]) {
        has_event = true;
        break;
      }
    }
    for (let i in userCtx.calendar[props.deviceName]) {
      if (has_event) {
        break;
      }
      if (userCtx.calendar[props.deviceName][i]["enabled"]) {
        has_event = true;
        break;
      }
    }
    for (let i in userCtx.schedule[props.deviceName]) {
      if (has_event) {
        break;
      }
      if (userCtx.schedule[props.deviceName][i]["enabled"]) {
        has_event = true;
        break;
      }
    }
    for (let i in userCtx.timers[props.deviceName]) {
      if (has_event) {
        break;
      }
      if (userCtx.timers[props.deviceName][i]["enabled"]) {
        has_event = true;
        break;
      }
    }

    if (has_event) {
      Swal.fire({
        title: t("Are you sure?"),
        text: t(
          "Your device has active events and this action might cause malfunctions, do you want to proceed?"
        ),
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: t("No"),
        confirmButtonText: t("Yes"),
      }).then((data) => {
        if (data.isConfirmed) {
          handleChange(e);
        }
      });
    } else {
      handleChange(e);
    }
  };

  const handleChange = (e) => {
    let flag = false;

    if( userCtx.user['role'] == "Admin" ){
      flag = true
    }

    if( userCtx.user['rules'] && userCtx.user['rules']['relay'] ){
      flag = true
    }

    if( !flag ){
      errorToast(t('Seems like you have insufficient rights'), "top-right")
      return
    }



    setFetching(true);

    fetch(`${userCtx.serverHost}api/sendMqtt/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
      body: JSON.stringify({
        retain: true,
        topic: `${props.deviceName}/${props.sensorName}/state`,
        payload: {
          state: !checked,
        },
      }),
    }).then((res) => res.json());


    
  };

  const reset_switch = () => {
    fetch(`${userCtx.serverHost}api/sendMqtt/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
      body: JSON.stringify({
        retain: true,
        topic: `${props.deviceName}/${props.sensorName}/state`,
        payload: {
          state: checked,
        },
      }),
    });
  };

  return (
    <div>
      {!props.locked ? (
        <>
          {!fetching && (
            <label className={classes.switch}>
              <input
                className={`${checked && classes.checked}`}
                onChange={check_for_events}
                type="checkbox"
              />
              <span className={classes.slider}></span>
            </label>
          )}

          <HashLoader
            color="#04cc21"
            loading={fetching}
            size={15}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </>
      ) : (

        <div className={`${checked ? 'text-success' : 'text-danger'}  fw-bold`}>
          { 
            checked ? "ON" : "OFF"
          }
        </div>
      )}

    </div>
  );
};

export default Switch;
