import { useContext, useEffect, useState } from "react";
import UserProvider from "../../store/user-context";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";

const AccountDevices = () => {
  const userCtx = useContext(UserProvider);
  const { t } = useTranslation();
  const [devices_preview, set_devices_preview] = useState();
  const [active_devices, set_active_devices] = useState({});

  const handle_click = (e) => {
    let billing_value = userCtx.devicesList[e.target.value]["billing-uid"]
      ? ""
      : userCtx.user.uid;
    if (userCtx.isDemo) {
      toast(t("There is no capability to save actions in a user demo"));
      return;
    }
    let payload = {};

    payload["topic"] = "devices-list";
    payload["device"] = e.target.value;
    payload["values"] = {};
    payload["values"]["billing-uid"] = billing_value;

    fetch(`${userCtx.serverHost}api/sendMqtt/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
      body: JSON.stringify({
        topic: "deviceSettings",
        payload: payload,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data["flag"]) {
          toast(t("Action was successfully added"));
        } else {
          toast(t("Something went wrong."));
        }
      });
  };

  useEffect(() =>{
    let temp_active_devices = {};

    for( let i in userCtx.devicesList ){
      if( !userCtx.devicesList[i]['active'] ){
        continue
      }
      temp_active_devices[i] = userCtx.devicesList[i];
    }
    set_active_devices(temp_active_devices)
  },[userCtx.devicesList])


  return (
    <div className="deviceContainer">
      <div className="border bg-white boxShadow rounded p-3 d-inline-block">
        <h5>{t("Debit account connection")}</h5>

       

        {Object.keys(active_devices).length > 0 ? (
          <div>
            {Object.keys(active_devices).map((device, index) => {
              let is_disabled = false;
              if (
                active_devices[device]["billing-uid"] &&
                active_devices[device]["billing-uid"] != userCtx.user.uid
              ) {
                is_disabled = true;
              }
              return (
                <div className="row ">
                  <div className="col-6">
                    {active_devices[device]["device-name"]}
                  </div>
                  <div className="col-4 text-end">
                    <input
                      type="checkbox"
                      value={device}
                      disabled={is_disabled}
                      onClick={handle_click}
                      checked={
                        active_devices[device]["billing-uid"]
                          ? true
                          : false
                      }
                    />
                  </div>
                  {active_devices[device]["billing-uid"] && active_devices[device]["user-name"] &&
                    <div className="col-2">
                      <a data-tooltip-id={`my-tooltip_${index}`} data-tooltip-content={`${t("This device belongs to")} ${active_devices[device]["user-name"]}`}>
                        <FontAwesomeIcon icon={faQuestion} />
                      </a>
                      <Tooltip id={`my-tooltip_${index}`} />
                    </div>
                  }
                  
                </div>
              );
            })}
          </div>
        ) : (
          <div>
            {t("Seems like you don't have any devices, try to add some first.")}
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountDevices;
