import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faMicrochip}  from '@fortawesome/free-solid-svg-icons'
import {useEffect,useContext,useState,} from "react";
import UserProvider from "../../store/user-context";

// Reusable Containers
import OutputsPreview from "../Devices/OutputsPreview";
import AnalogPreview from "../Devices/AnalogPreview";
import DigitalPreview from "../Devices/DigitalPreview";
import DevicesBar from '../Devices/DevicesBar';

const DeviceWatch = (props) => {
  const userCtx = useContext(UserProvider);
  const [analogInputs, setAnalogInputs] = useState({})
  const [digitalInputs, setDigitalInputs] = useState({})
  const [outputs, setOutputs] = useState({})
  const [liveData, setLiveData] = useState({})
  const [deviceState, setDeviceState] = useState(false)

  // console.log(props)
  useEffect(() => {
    // console.log(props)
    // console.log(userCtx)
    try {
      let id = props.device
      let tempAnalogInputs = {}
      let tempDigitalInputs = {}
      let tempOutputs = {}

      for( let sensor in userCtx.deviceInputs[id] ){
        if  ( !userCtx.deviceInputs[id][sensor]['enabled'] || !userCtx.deviceInputs[id][sensor]['active'] ){
          continue
        }

        if(props.sensors.includes(sensor)){
          

          if( userCtx.deviceInputs[id][sensor]['type'] == 1 ){
            tempDigitalInputs[sensor] = userCtx.deviceInputs[id][sensor]
          }else{
            tempAnalogInputs[sensor] = userCtx.deviceInputs[id][sensor]
          }
        }
      }

      for( let sensor in userCtx.deviceOutputs[id] ){
        if  ( !userCtx.deviceOutputs[id][sensor]['enabled'] | !userCtx.deviceOutputs[id][sensor]['active']){
          continue
        }

        if(props.sensors.includes(sensor)){
          tempOutputs[sensor] = userCtx.deviceOutputs[id][sensor]
        }
      }
      
      setDeviceState(userCtx.devicesList[id]['device-state'])
      setLiveData( userCtx.liveData[id] )
      setAnalogInputs(tempAnalogInputs)
      setDigitalInputs(tempDigitalInputs)
      setOutputs(tempOutputs)

    } catch (error) {
      // console.log("error")
      // console.log(error)
    }
    
  },[userCtx.deviceInputs,userCtx.deviceOutputs,userCtx.liveData,userCtx.devicesList,props])


  return (
    

      <>

        { Object.keys(analogInputs).length > 0 && 
            <AnalogPreview 
              device={props.device} 
              liveData={liveData} 
              inputs={analogInputs} 
              deviceState={deviceState}
              place="dashboard"
              name = { userCtx.devicesList && userCtx.devicesList[props.device] ? userCtx.devicesList[props.device]['device-name'] : ""}
            />
        }

        { Object.keys(digitalInputs).length > 0 && 
            <DigitalPreview
              device={props.device}
              liveData={liveData} 
              inputs={digitalInputs}
              deviceState={deviceState}
              place="dashboard"
              name = { userCtx.devicesList && userCtx.devicesList[props.device] ? userCtx.devicesList[props.device]['device-name'] : ""}
            />
        }

        { Object.keys(outputs).length > 0 && 
            <OutputsPreview
              device={props.device}
              liveData={liveData} 
              outputs = {outputs}
              deviceState={deviceState}
              place="dashboard"
              name = { userCtx.devicesList && userCtx.devicesList[props.device] ? userCtx.devicesList[props.device]['device-name'] : ""}
            />
        }
      </>
  );
};

export default DeviceWatch;
