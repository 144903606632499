import { useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";
import UserProvider from "../../store/user-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import CalendarForm from "./CalendarForm";
import { useTranslation } from "react-i18next";

function formatDate(timestamp) {
  let date = new Date(timestamp);
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  return `${day}/${month}/${year}`;
}
const initCalendar = {
  "show" : false,
  "type" : "add",
  "calendar": {},
  "child" : ""
}



const ShowCalendar = (props) => {
  const [data, setData] = useState([]);
  const ctx = useContext(UserProvider);
  const [calendarHandler, setCalendarHandler] = useState(initCalendar)
  const { t } = useTranslation();


  useEffect(() => {
    try {
      if (
        props["device"] &&
        Object.keys(ctx.calendar).length &&
        Object.keys(ctx.calendar[props["device"]]).length
      ) {
        fetchData();
      }
    } catch (error) {}

    return(() => {
      setData({})
    })
  }, [props, ctx.calendar]);

  const fetchData = () => {
    if (props["type"] === "settings") {
      let temp = [];
      temp = [...ctx.calendar[props["device"]]];
      setData(temp);
    }
  };




  const editCalendar = (e) => {
    
    let calendar  = e.target.closest('button').getAttribute('data-action')
    let temp = {
      "show" : true,
      "type" : "edit",
      "calendar": data[calendar],
      "child" : data[calendar]['key']
    }
    // console.log(temp)
    setCalendarHandler(temp)
  }  
  
  
  
  
  const removeCalendar = (e) => {
    if(ctx.isDemo){
      toast(t("There is no capability to save actions in a user demo"));
      return
    }

    let calendar  = e.target.closest('button').getAttribute('data-action')
    let key = data[calendar]['key']
    if(key){
      let json = 
      {
        "topic":"device-calendar",
        "device": props["device"],
        "values": [key]
      }
  
      fetch(`${ctx.serverHost}api/sendMqtt/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": ctx.csrf,
        },
        credentials: "include",
        body: JSON.stringify({
          topic : 'deviceSettingsDelete',
          payload : json
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if( data['flag'] ){
            toast(t("Deleted successfully"));
            // let temp = [...calendars ]
            // temp.splice(calendar, 1)
            // setCalendars(temp)
          }else{
            toast(t("Something went wrong."));
          }
        });
    }else{
      toast(t("Something went wrong."));
    }
  }


  return (
    <div className="d-flex flex-column">
      {data &&
        data.length > 0 &&
        data.map(( calendar, key) => {
          
          let start = parseInt(calendar["start"]);
          let startDateFormated = formatDate(start);
          let startDate = new Date(start);
          let starHour = startDate.getHours().toString();
          let starMinute = startDate.getMinutes().toString();
          starHour = starHour.length === 1 ? `0${starHour}` : starHour;
          starMinute = starMinute.length === 1 ? `0${starMinute}` : starMinute;

          let stop = parseInt(calendar["stop"]);
          let stopDateFormated = formatDate(stop);
          let stopDate = new Date(stop);
          let stopHour = stopDate.getHours().toString();
          let stopMinute = stopDate.getMinutes().toString();
          stopHour = stopHour.length === 1 ? `0${stopHour}` : stopHour;
          stopMinute = stopMinute.length === 1 ? `0${stopMinute}` : stopMinute;

          return (
            <div className="row fw-semibold fs-6 my-2 align-items-center top-divider-strong pt-3 mt-3" key={`cal${key}`}>
              <div
                className={`col-2 ${
                  calendar && calendar["enabled"]
                    ? "text-success"
                    : "text-danger"
                }`}
              >
                {calendar && calendar["enabled"]
                  ? t("Active")
                  : t("Inactive")}
              </div>
              <div
                className={`col-2 ${
                  calendar && calendar["state"]
                    ? "text-success"
                    : "text-danger"
                }`}
              >
                {calendar && calendar["state"] ? "ON" : "OFF"}
              </div>
              <div className="col-2">
                <div>{startDateFormated}</div>
                <div>{`${starHour} : ${starMinute}`}</div>
              </div>
              <div className="col-2">
                <div>{stopDateFormated}</div>
                <div>{`${stopHour} : ${stopMinute}`}</div>
              </div>

              <div className="col-2">
                <button
                  className="btn btn-outline-success"
                  data-action={key}
                  onClick={(e) =>{editCalendar(e)}}
                >
                  <FontAwesomeIcon icon={faPenToSquare} />
                </button>
              </div>
              <div className="col-2">
                <div className="d-flex">
                  <button
                    className="btn btn-outline-danger"
                    data-action={key}
                    onClick={(e) =>{removeCalendar(e)}}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </div>
              </div>
            </div>
          );
        })}

        { calendarHandler && calendarHandler['show'] &&
          <CalendarForm
            device={props['device']} 
            output={props['output']} 
            type={calendarHandler['type']}
            calendar={calendarHandler['calendar']}
            child={calendarHandler['child']}
            setCalendarHandler={setCalendarHandler}
          />
        }

    </div>
  );
};

export default ShowCalendar;
