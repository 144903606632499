import { useTranslation } from "react-i18next";
import AnalogInput from "./AnalogInput";

const AnalogPreview = (props) => {
  const { t } = useTranslation();

  

  return (
    <>
    {Object.keys(props.inputs).length > 0 && 
      <div className={`cardContainer`}>
        <div className="d-flex justify-content-center radius-top-left-right-8 cardTitleColor">
          <h3 className="my-2 fs-5">
            {props.place == 'dashboard' &&
            
              <span className="mx-2">{props.name}</span>
            }
            {t("Sensors")}
          </h3>
        </div>
        <div className="d-flex flex-row flex-wrap gap-3 justify-content-center p-3 position-relative">
          { Object.keys(props.inputs).map((input) => {
              return (
                <>
                {props.inputs[input]['active'] &&  props.inputs[input]['enabled'] ?
                  <AnalogInput
                      device      = {props.device}
                      input       = {input}
                      max         = {props.inputs[input]["max"]}
                      min         = {props.inputs[input]["min"]}
                      symbol      = {props.inputs[input]["input-symbol"]}
                      name        = {props.inputs[input]["name"]}
                      value       = {props.liveData['inputs'][input]}
                    />
                  :
                    ''
                }
                </>
              );
            })}

          { props.deviceState == false &&
            <div className="position-absolute w-100 h-100 top-0 left-0 bg-seethrought-grey z-index-2 border-radius-bottom-8 "></div>
          }
        </div>
      </div>
    }
      
    </>
    
  );
};

export default AnalogPreview;
