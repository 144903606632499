import classes from "./Menu.module.css";
import { NavLink } from "react-router-dom";
import { useContext } from "react";
import { useEffect, useState } from "react";
import UserProvider from "../../store/user-context";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMicrochip, faGaugeHigh, faPlus, faUser, faUsers, faL, faSimCard, faCircle}  from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from "react-i18next";


const Menu = () => {
  const userCtx = useContext(UserProvider);
  const darkMode = false;
  const [devices, setDevices ] = useState([])
  const [user_name, set_user_name] = useState("")
  const { t } = useTranslation();

  useEffect(() => {

    let temp = []

    for( let i in  userCtx.devicesList  ){

      let lookup = userCtx.devicesList[i]['device-type']
      let device_type = userCtx.devicesAll['devices-look-up'][lookup]['type'];
      if( device_type === 'master' ){
        temp.push( {'name' : userCtx.devicesList[i]['device-name'], 'id': i, 'device-state' : userCtx.devicesList[i]['device-state'] } )
      }
    }
    setDevices(temp)

  }, [userCtx.devicesList, userCtx.devicesAll]);

  useEffect(() =>{
    if( userCtx.user['first-name'] || userCtx.user['last-name'] ){
      set_user_name(`${userCtx.user['first-name']} ${userCtx.user['last-name']}`)
    }
  },[userCtx.user])

  const logout = () => {
    fetch(`${userCtx.serverHost}api/logout/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
    }).then((res) => res.json())
      .then((data) => {
        if( data.flag){
          userCtx.onLogout()
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className= {`${classes.menuLeftCol} ${darkMode && classes.darkmode} ${ userCtx.closeMenu ? classes.closeMenu : ''} d-flex flex-column  justify-content-between pb-3` }>
        <div>
            <div className="">
            <NavLink
              className={({ isActive }) =>
                isActive ? "menuActiveLink px-3 py-3 text-white" : 'px-3 py-3 text-white'
              }

              to="dashboard/"
            >
              <div className="d-flex gap-3 align-items-center fs-6 fw-semibold">
                <FontAwesomeIcon icon={faGaugeHigh} size="lg"/>
                <span >{t("Control panel")} </span> 
              </div>
            </NavLink>

          </div>
          <div className="top-divider bottom-divider py-2">
            <h5 className={`${classes.menuTitle} px-2`}>{t("DEVICES")}</h5>
            <ul className={`${classes.list}`}>
              {devices &&
                devices.map((device, index) => {

                  return (
                    <li key={device['id']}>
                      <NavLink
                      className={({ isActive }) =>
                      isActive ? "menuActiveLink px-3 py-2 text-white" : 'px-3 py-2 text-white'
                    }
                        to={`devices/${device['id']}/`}
                      >
                        <div className="d-flex gap-3 align-items-center fs-6 fw-semibold justify-content-between">
                          <div>
                            <FontAwesomeIcon  icon={faMicrochip} size="lg" />
                            <span className="ms-2">
                              {device["name"]}
                            </span>
                          </div>
                          
                          <FontAwesomeIcon className={device['device-state'] ? 'enable' : 'text-danger'} icon={faCircle} size="md" />
                        </div>
                      
                      </NavLink>
                    </li>
                  );
                })}
            </ul>

            <NavLink
              className={({ isActive }) =>
              isActive ? "menuActiveLink py-2 text-white" : 'py-2 text-white'
            }
              to="addDevice/"
            >
              <div className="d-flex px-2 gap-3 align-items-center fs-6 fw-semibold">
                <FontAwesomeIcon  icon={faPlus} />
                <span >{t("Add device")}</span> 
              </div>
            </NavLink>
          </div>
          <div className="top-divider bottom-divider py-2">
            <h5 className={`${classes.menuTitle} px-2`}>{t("ACCOUNT")}</h5>
              <NavLink
                className={({ isActive }) =>
                isActive ? "menuActiveLink py-2 text-white" : 'py-2 text-white'
              }
                to="profile/"
              >
                <div className="d-flex px-2 gap-3 align-items-center fs-6 fw-semibold">
                  <FontAwesomeIcon  icon={faUsers} />
                  <span>{t("Users")}</span> 
                </div>
              </NavLink>
              <NavLink
                className={({ isActive }) =>
                isActive ? "menuActiveLink py-2 text-white" : 'py-2 text-white'
              }
                to="devices/"
              >
                <div className="d-flex px-2 gap-3 align-items-center fs-6 fw-semibold">
                  <FontAwesomeIcon  icon={faSimCard} />
                  <span>{t("Devices")}</span> 
                </div>
              </NavLink>
          </div>
        </div>
        <div className="d-flex flex-column gap-3 ps-2">
          <div>
            {user_name}
          </div>
          <div>
            <button className="btn bg-none text-white px-0" onClick={logout}>
            {t("Logout")}
            </button>
          </div>
          
        </div>
    </div>
  );
};

export default Menu;
