import React, { useEffect, useState, useContext, useReducer } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Line,
  Legend,
  LineChart,
  ResponsiveContainer,
} from "recharts";
import "react-datetime/css/react-datetime.css";
import UserProvider from "../../store/user-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsRotate,
  faTrash,
  faTruckArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useTranslation } from "react-i18next";
import HashLoader from "react-spinners/HashLoader";
import { toast } from "react-toastify";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { el, enUS } from "date-fns/locale";

const subtractDays = (numOfDays, date = new Date()) => {
  date.setDate(date.getDate() - numOfDays);
  return Date.parse(date).toString();
};

const yMaxPercentage = 10;
const yMinPercentage = 10;

const initSensor = { sensor: "", time: "1" };
const AnalogInputLog = (props) => {
  const userCtx = useContext(UserProvider);
  const [cSensor, setCSensor] = useState(initSensor);
  const [data, setData] = useState([]);
  const [reducedData, setReducedData] = useState([]);
  const { t } = useTranslation();
  const [borders, setBorders] = useState({ min: "", max: "" });
  const [average_value, set_average_value] = useState(0);
  const [custom_day_pick, set_custom_day_pick] = useState({
    from: false,
    to: false,
  });
  const [custom_day, set_custom_day] = useState({ minDate: "", maxDate: "" });

  const [fetching_logs, set_fetching_logs] = useState(false);

  const fetch_logs = async () => {
    set_fetching_logs(true);
    await props.updateLogs();
    set_fetching_logs(false);
  };

  const delete_logs = async () => {
    set_fetching_logs(true);
    const response = await fetch(`${userCtx.serverHost}api/delete_logs/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
      body: JSON.stringify({
        device: props["keys"][cSensor["sensor"]]["device_id"],
        sensor: props["keys"][cSensor["sensor"]]["sensor_id"],
        timestamp: subtractDays(cSensor["time"]).toString(),
      }),
    })
      .then((res) => res.json())
      .catch((err) => "");

    if (response && response["flag"]) {
      localStorage.removeItem(
        `${props["keys"][cSensor["sensor"]]["device_id"]}/inputs`
      );
      userCtx.refreshApp();
    } else {
      toast(t("Something went wrong, please try again later"));
      set_fetching_logs(false);
    }
  };

  useEffect(() => {
    if (reducedData.length > 0) {
      const maxYValue = Math.max(...reducedData.map((item) => item.value));
      const minYValue = Math.min(...reducedData.map((item) => item.value));
      const sensorMin = Math.min(...reducedData.map((item) => item.min));
      const sensorMax = Math.max(...reducedData.map((item) => item.max));

      let min, max;
      min = sensorMin < minYValue ? sensorMin : minYValue;
      max = sensorMax > maxYValue ? sensorMax : maxYValue;

      const maxYSign = Math.sign(max);
      const minYSign = Math.sign(min);
      const yMaxAdjustment = Math.ceil((max / 100) * yMaxPercentage) * maxYSign;
      const yMinAdjustment = Math.ceil((min / 100) * yMinPercentage) * minYSign;

      const adjustedMaxYValue = Math.round(max + yMaxAdjustment);
      const adjustedMinYValue = Math.round(min - yMinAdjustment);
      setBorders({ min: adjustedMinYValue, max: adjustedMaxYValue });
    }
  }, [reducedData]);

  useEffect(() => {
    if (props["keys"] && Object.keys(props["keys"]).length > 0) {
      setCSensor({ sensor: Object.keys(props["keys"])[0], time: "1" });
    }

    return () => {
      setCSensor(initSensor);
      setData([]);
      setReducedData([]);
    };
  }, [props.device]);

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  function getCurrentDateFormatted() {
    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
    const year = currentDate.getFullYear();

    return `${day}_${month}_${year}`;
  }

  const exportToCSV = () => {
    let apiData = [...data];

    for (let i in apiData) {
      [apiData[i]["date"], apiData[i]["hour"]] = apiData[i]["time"].split(" ");
      delete apiData[i]["time"];

      if (i == 0) {
        apiData[i]["id"] = `${props.device} `;
        apiData[i]["name"] = `${props.name}  `;
        apiData[i]["name_input"] = `${props.keys[cSensor["sensor"]]["name"]} `;
      }
    }

    let fileName = `${props.name}_${
      props.keys[cSensor["sensor"]]["name"]
    }_${getCurrentDateFormatted()}`;
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const excel = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(excel, fileName + fileExtension);
  };

  const formChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setCSensor((previus) => ({ ...previus, [name]: value }));
  };

  useEffect(() => {
    let tempData = {};


    if( cSensor["time"] == '0' && cSensor["sensor"] && custom_day.minDate && custom_day.maxDate ){

      var dateObject = new Date(custom_day.minDate);
      var minDate = dateObject.getTime();
      dateObject = new Date(custom_day.maxDate);
      var maxDate = dateObject.getTime();


      for (let i in props["logs"][cSensor["sensor"]]) {
        if (parseInt(i) >= minDate && parseInt(i) <= maxDate) {

          tempData[i] = props["logs"][cSensor["sensor"]][i];
        }
      }


    }else if (cSensor["time"] && cSensor["sensor"]) {
      for (let i in props["logs"][cSensor["sensor"]]) {
        if (parseInt(subtractDays(cSensor["time"])) <= parseInt(i)) {
          tempData[i] = props["logs"][cSensor["sensor"]][i];
        }
      }
    } else {
      tempData = props["logs"][cSensor["sensor"]];
    }


    setData([]);
    setReducedData([]);
    if (tempData) {
      createDataset(tempData);
    }
  }, [cSensor, custom_day]);

  useEffect(() => {
    let sum = 0;

    if (data.length) {
      for (let i in data) {
        sum += parseFloat(data[i]["value"]);
      }

      sum = sum / data.length;
      sum = Math.round(sum * 100) / 100;
    }

    set_average_value(sum);
  }, [data]);

  const createDataset = (dataSet) => {
    let tempData = [];
    const keys = Object.keys(dataSet).map((str) => parseInt(str)); // Get the keys of the object
    const sortedIntArr = keys.sort((a, b) => a - b);

    for (let key in sortedIntArr) {
      let tempTotalDate = new Date(parseInt(sortedIntArr[key]));
      tempTotalDate = userCtx.getFinalDate(tempTotalDate);

      tempData.push({
        time: tempTotalDate,
        value: parseFloat(dataSet[sortedIntArr[key]]),
      });
    }
    setData(tempData);

    let tempArray = [],
      temp = [];

    for (let key in sortedIntArr) {
      temp.push({
        [sortedIntArr[key]]: parseFloat(dataSet[sortedIntArr[key]]),
      });
    }

    let totalEntries = 300,
      i = 0,
      meanTimestamp = 0,
      meanValue = 0,
      notItemFlag = true,
      tempDate;
    let step =
      temp.length > totalEntries
        ? Math.floor(temp.length / totalEntries) + 1
        : 1;

    try {
      for (i = 0; i < temp.length; i = i + step) {
        meanTimestamp = 0;
        meanValue = 0;
        tempDate = 0;
        notItemFlag = true;
        for (let j = i; j < i + step; j++) {
          if (
            temp[j] &&
            Object.keys(temp[j]).length > 0 &&
            Object.values(temp[j]).length > 0 &&
            parseFloat(Object.values(temp[j])[0]) != "-999"
          ) {
            meanTimestamp += parseInt(Object.keys(temp[j])[0]);
            meanValue += parseFloat(Object.values(temp[j])[0]);
          } else {
            notItemFlag = false;
          }
        }

        if (notItemFlag) {
          tempDate = new Date(parseInt(meanTimestamp / step));
          tempDate = userCtx.getFinalDate(tempDate);
          tempArray.push({
            time: tempDate,
            value: (meanValue / step).toFixed(2),
            min:
              props["keys"][cSensor["sensor"]]["min"] != ""
                ? parseFloat(props["keys"][cSensor["sensor"]]["min"])
                : null,
            max:
              props["keys"][cSensor["sensor"]]["max"] != ""
                ? parseFloat(props["keys"][cSensor["sensor"]]["max"])
                : null,
          });
        }
      }

      setReducedData(tempArray);
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <>
      <div className="cardContainer">
        <div className="d-flex justify-content-center radius-top-left-right-8 cardTitleColor">
          <h3 className="my-2 fs-5">
            {cSensor["sensor"] &&
            props["keys"] &&
            props["keys"][cSensor["sensor"]] &&
            props["keys"][cSensor["sensor"]]["name"] ? (
              <span>
                {t("Sensor logger")} "
                {cSensor["sensor"] &&
                  props["keys"] &&
                  props["keys"][cSensor["sensor"]]["name"]}
                "
              </span>
            ) : (
              <span>{t("Select input")}</span>
            )}
          </h3>
        </div>

        <div className="d-flex flex-wrap gap-2 align-items-center justify-content-between py-2 px-3">
          <div className="d-flex gap-2">
            <select
              className="px-3 py-1 rounded me-3"
              name="time"
              onChange={formChange}
              value={cSensor["time"]}
            >
              <option value="1"> {`1 ${t("day")}`} </option>
              <option value="7"> {`7 ${t("days")}`} </option>
              <option value="30"> {`1 ${t("month")}`} </option>
              <option value="90"> {`3 ${t("months")}`} </option>
              <option value="364">{`1 ${t("year")}`} </option>
              <option value="0">{t("Custom")}</option>
            </select>

            {cSensor["time"] == 0 && (
              <div >
                <div  className="d-flex gap-2">
                  <button
                    className="btn btn-outline-success "
                    onClick={() => {
                      set_custom_day_pick((state) => ({
                        ...state,
                        from: true,
                      }));
                    }}
                    disabled={fetching_logs}
                  >
                    {t("From")}
                  </button>
                  {custom_day_pick.from && (
                    <div className="position-relative">
                      <div className="abs_date_picker">
                        <DayPicker
                          mode="single"
                          selected={
                            custom_day["minDate"] &&
                            new Date(custom_day["minDate"])
                          }
                          onSelect={(e) => {
                            e &&
                              set_custom_day_pick((state) => ({
                                ...state,
                                from: false,
                              }));

                            set_custom_day((previus) => ({
                              ...previus,
                              minDate: e
                                .toLocaleDateString("en-CA", {
                                  timeZone: "Europe/Athens",
                                })
                                .split("/")
                                .reverse()
                                .join("-"),
                            }));
                          }}
                          locale={el}
                        />
                      </div>
                    </div>
                  )}

                  <button
                    className="btn btn-outline-success "
                    onClick={() => {
                      set_custom_day_pick((state) => ({ ...state, to: true }));
                    }}
                    disabled={fetching_logs}
                  >
                    {t("To")}
                  </button>

                  {custom_day_pick.to && (
                    <div className="position-relative">
                      <div className="abs_date_picker">
                        <DayPicker
                          mode="single"
                          selected={
                            custom_day["maxDate"] &&
                            new Date(custom_day["maxDate"])
                          }
                          onSelect={(e) => {
                            e &&
                              set_custom_day_pick((state) => ({
                                ...state,
                                to: false,
                              }));
                            set_custom_day((previus) => ({
                              ...previus,
                              maxDate: e
                                .toLocaleDateString("en-CA", {
                                  timeZone: "Europe/Athens",
                                })
                                .split("/")
                                .reverse()
                                .join("-"),
                            }));
                          }}
                          locale={el}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            <select
              className="px-3 py-1 rounded"
              name="sensor"
              value={cSensor["sensor"]}
              onChange={formChange}
            >
              <option value="">{t("Select")}</option>
              {props["keys"] &&
                Object.keys(props["keys"]).length > 0 &&
                Object.keys(props["keys"]).map((input) => {
                  return (
                    <option key={`input-${input}`} value={input}>
                      {props["keys"][input]["name"]}
                    </option>
                  );
                })}
            </select>
          </div>
          <div>
            <button
              className="btn btn-outline-danger"
              onClick={delete_logs}
              disabled={fetching_logs}
            >
              {fetching_logs ? (
                <HashLoader
                  color="#04cc21"
                  loading={true}
                  size={15}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              ) : (
                <FontAwesomeIcon icon={faTrash} />
              )}
            </button>

            <button
              className="btn btn-outline-success ms-3"
              onClick={fetch_logs}
              disabled={fetching_logs}
            >
              {fetching_logs ? (
                <HashLoader
                  color="#04cc21"
                  loading={true}
                  size={15}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              ) : (
                <FontAwesomeIcon icon={faArrowsRotate} />
              )}
            </button>

            <button
              className="btn btn-outline-success ms-3"
              onClick={exportToCSV}
            >
              Excel
            </button>
          </div>
        </div>

          <div className="d-flex flex-wrap gap-2 align-items-center justify-content-between py-2 px-3">
            {t("Average value")}: {average_value}
          </div>

        {reducedData && reducedData.length > 0 ? (
          <ResponsiveContainer width="99%" height={300}>
            <LineChart data={reducedData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="time" />
              <YAxis domain={[borders["min"], borders["max"]]} />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="value"
                name="Τιμή"
                stroke="#04cc21"
                dot={false}
                activeDot={false}
              />
              <Line
                type="monotone"
                dataKey="max"
                name="Μέγιστο όριο"
                stroke="#dc3545"
                dot={false}
                activeDot={false}
              />
              <Line
                type="monotone"
                dataKey="min"
                name="Ελάχιστο όριο"
                stroke="#0d6efd"
                dot={false}
                activeDot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <div className="text-center boxShadow my-3 p-2 rounded-bottom ">
            {t("There are no recorders for this period.")}
          </div>
        )}
      </div>
    </>
  );
};

export default AnalogInputLog;
