import logo from "../../static/images/logo.png";
import { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import UserProvider from "../../store/user-context";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

let init = {
  email : "",
}


const ForgotPassword = () => {
  const [user, setUser] = useState(init)
  const userCtx = useContext(UserProvider);
  const navigate = useNavigate();
  const { t } = useTranslation();

  function emailIsValid (InputEmail){
    if (InputEmail.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
      return true; 
    } else {
      return false;
    }
  }

  const handlerUser = (e) => {
    let name = e.target.name
    let value = e.target.value
    let temp = {...user}
    temp[name] = value
    setUser(temp)
  }

  
   const requestPassword = async () => {
    if( emailIsValid(user['email'] ) ){

      const response = await  fetch(`${userCtx.serverHost}api/forgotPassword/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": userCtx.csrf,
        },
        credentials: "include",
        body: JSON.stringify(user),
      })
      .then((res) => res.json())

      if( response['flag'] ){
        toast(t("You have been sent the details for the password change in the email you provided."));
        navigate('/login')
      }else{
        toast(t("The email does not seem to correspond to a user.Please enter a valid email."));
      }
        
    }else{
      toast(t("Please fill in a valid email."));
    }
  }


  return(
    <div className="greyContainer">
      <div className="registerForm boxShadow">
        <div className="d-flex justify-content-center"><img className="registerImage" src={logo} alt={logo} ></img></div>
        <div>
          <input type="text" placeholder="Email *" name="email" value={user['email']} onChange={handlerUser} />
        </div>
        <div>
          <button className="blueButton" onClick={requestPassword}> 
            Αποστολή
          </button>
        </div>
        <div>
          <NavLink 
              to={"/login"}
              className={`text-center fw-bold d-block  noUnderline blueButton`}
            >
            Συνδεση
          </NavLink>
        </div>
      </div>
    </div>
  )
}


export default ForgotPassword