import { useTranslation } from "react-i18next";
import Switch from "./Switch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faUnlock } from "@fortawesome/free-solid-svg-icons";
import { useContext, useState } from "react";
import UserProvider from "../../store/user-context";
import { toast } from "react-toastify";
import HashLoader from "react-spinners/HashLoader";
import OutputLock from "./OutputLock";

const OutputsPreview = (props) => {
  const { t } = useTranslation();

  return (
    <>
      {props &&
        props["outputs"] &&
        Object.keys(props["outputs"]).length > 0 && (
          <div>
            <div className="cardContainer">
              <div className="d-flex justify-content-center radius-top-left-right-8 cardTitleColor">
                <h3 className="my-2 fs-5">
                  {props.place == "dashboard" && (
                    <span className="mx-2">{`${props.name}`}</span>
                  )}
                  {t("Outputs")}
                </h3>
              </div>
              <div className="position-relative">
                <ul className="ulColumn p-3">
                  {props["outputs"] &&
                    Object.keys(props["outputs"]).map((output) => {
                      return (
                        <>
                          {props["outputs"][output]["active"] &&
                          props["outputs"][output]["enabled"] ? (
                            <li key={output}>
                              <div className="d-flex gap-2 align-items-center">
                                <span>{props["outputs"][output]["name"]}</span>

                                <OutputLock
                                  device={props.device}
                                  output={output}
                                  locked={
                                    props["outputs"][output]["locked"]
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                              <Switch
                                deviceName={props.device}
                                sensorName={output}
                                locked={
                                  props["outputs"][output]["locked"]
                                    ? true
                                    : false
                                }
                                value={props["liveData"]["outputs"][output]}
                              />
                            </li>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    })}
                </ul>

                {props.deviceState == false && (
                  <div className="position-absolute w-100 h-100 top-0 left-0 bg-seethrought-grey z-index-2 border-radius-bottom-8 "></div>
                )}
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export default OutputsPreview;
