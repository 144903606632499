import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ReconnectingWebSocket from 'reconnecting-websocket'
import { errorToast, successToast } from "../utils/toasts";
import HashLoader from "react-spinners/HashLoader";
import { useTranslation } from "react-i18next";

function OldmergeObjects(originalObject, updateObject) {
  // create a new object to hold the merged values
  const mergedObject = {};

  // loop through each property of the original object
  for (const key in originalObject) {
    // if the property exists in the update object, use its value
    if (key in updateObject) {
      // if the property is an object, recursively merge it
      if (typeof originalObject[key] === 'object' && typeof updateObject[key] === 'object') {
        mergedObject[key] = OldmergeObjects(originalObject[key], updateObject[key]);
      }
      // otherwise, use the value from the update object
      else {
        mergedObject[key] = updateObject[key];
      }
    }
    // if the property doesn't exist in the update object, use the original value
    else {
      mergedObject[key] = originalObject[key];
    }
  }
  // loop through each property of the update object
  for (const key in updateObject) {
    // if the property doesn't exist in the original object, add it to the merged object
    if (!(key in originalObject)) {
      mergedObject[key] = updateObject[key];
    }
  }

  // return the merged object
  return mergedObject;
}



function mergeObjects(originalObject, updateObject) {
  const mergedObject = {};

  for (const key in originalObject) {
    if (originalObject.hasOwnProperty(key)) {
      if (updateObject.hasOwnProperty(key)) {
        if (
          typeof originalObject[key] === 'object' &&
          typeof updateObject[key] === 'object' &&
          originalObject[key] !== null &&
          updateObject[key] !== null
        ) {
          mergedObject[key] = mergeObjects(originalObject[key], updateObject[key]);
        } else {
          mergedObject[key] = updateObject[key];
        }
      } else {
        mergedObject[key] = originalObject[key];
      }
    }
  }

  for (const key in updateObject) {
    if (
      updateObject.hasOwnProperty(key) &&
      !originalObject.hasOwnProperty(key)
    ) {
      mergedObject[key] = updateObject[key];
    }
  }

  return mergedObject;
}





const UserContext = React.createContext({
  devicesAll : [],
  serverHost: '',
  wsHost: '',
  isLoggedIn: false,
  csrf: "",
  name: "Not set",
  notifications: {},
  watchedList: {},
  timers: {},
  schedule: {},
  alerts: {},
  calendar: {},
  user: {},
  deviceInputs: {},
  deviceOutputs: {},
  devicesList: {},
  liveData: {},
  devices: [],
  device_notifications: [],
  loading: false,
  updatingLogs: false,
  isDemo: false,
  setIsDemo:  () => {},
  onLogout: () => {},
  setUpdatingLogs: () => {},
  setUserDevices: () => {},
  onLogin: (email, password) => {},
  closeMenu: false,
  toggleMenu: () =>{},
  getFinalDate: () =>{},
  set_device_notifications: () =>{},
  fetchWatchedList: () =>{},
  setLoading: () =>{},
});

let httpURL;
let tempLive = {}
let tempInputs = {}
let tempOutputs = {}
let tempDevicesList = {}
let wsURL

export const UserProvider = (props) => {
  // DEV
  httpURL = "http://localhost:8000/"
  wsURL = "ws://localhost:8000/"

  //  LIVE
  httpURL = "https://panel.grabi.gr/"
  wsURL = "wss://panel.grabi.gr/"
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isDemo, setIsDemo] = useState(false);
  const [csrf, setCsrf] = useState();
  const [devices, setDevices] = useState();
  const [devicesAll, setDevicesAll] = useState([]);
  const [fbid, setFbid] = useState();
  const [closeMenu, setCloseMenu] = useState(false);
  const [serverHost, setServerHost] = useState(httpURL);
  const [wsHost, setWsHost] = useState(wsURL);
  const [notifications, setNotifications] = useState({})
  const [watchedList, setWatchedList] = useState({})
  const [timers, setTimers] = useState({})
  const [schedule, setSchedule] = useState({})
  const [calendar, setCalendar] = useState({})
  const [alerts, setAlerts] = useState({})
  const [user, setUser] = useState({})
  const [loading, setLoading] = useState(true)
  const [updatingLogs, setUpdatingLogs] = useState(false)
  const [ws, setWs] = useState(false)
  const [liveData, setLiveData] = useState({})
  const [deviceInputs, setDeviceInputs] = useState({})
  const [deviceOutputs, setDeviceOutputs] = useState({})
  const [devicesList, setDevicesList] = useState({})
  const [device_notifications, set_device_notifications] = useState([])
  const { t } = useTranslation();

  useEffect(() =>{
    let alert

    if( !isLoggedIn ){
      return;
    }

    if( loading ){
      return;
    }

    if( ws ){
      alert = setTimeout(() => {
        successToast(t("You are online"))
      }, 3000);
    }else{
      alert = setTimeout(() => {
        errorToast(t("You are offline, trying to reconnect"))
      }, 3000);
    }

    

  

    return () => {
      clearTimeout(alert);
    };

  },[loading,isLoggedIn, ws])



  let timer 
  useEffect(()  => {
    async function csrf(){
      await getCSRF();
      getSession();
      clearInterval(timer)
      timer =  setInterval(getSession, 60000 * 20 )
    }
    csrf()
  }, []);


  
  useEffect( () => {

    if( !isLoggedIn ){
      return
    }

    if( loading ){
      return
    }

    if( !ws ){
      setWs(new WebSocket(`${wsURL}ws/DeviceAll/`))
    }
  },[ ws, isLoggedIn, loading ])


  useEffect(() => {
    if(ws){
      ws.onopen = () => {
        console.log("connection open")
        let devices = [...devicesAll['devices'], ...devicesAll['extenders']]
        ws.send(JSON.stringify( {type: 'open', devices } ))
      };

      ws.onmessage = (event) => {
        wsRecieve(JSON.parse( event.data))
      };

      ws.onclose = () => {
        setWs(false)
        console.log("connection onclose")
        // errorToast(t("You are offline, trying to reconnect"))
      };
    }
  },[ws])






  const wsRecieve = (ws) => {
    let temp

    if( ws && Object.keys(ws).length > 0 ){

      if (ws['topic'] === 'live-data'){
        temp = findTemp(ws)
        tempLive = mergeObjects(tempLive, temp)

        setLiveData(tempLive)
      }else if( ws['topic'] === 'devices-inputs' ) {
        temp = findTemp(ws)
        tempInputs = mergeObjects(tempInputs, temp)
        setDeviceInputs(tempInputs)
      }
      else if( 
        ws['topic'] === 'devices-outputs' 
      ){
        temp = findTemp(ws)
        tempOutputs = mergeObjects(tempOutputs, temp)
        setDeviceOutputs(tempOutputs) 
      } else if ( ws && ws['topic'] === 'devices-list' ){
        temp = findTemp(ws)
        tempDevicesList = mergeObjects(tempDevicesList, temp)
        setDevicesList(tempDevicesList) 
      } else if ( ws['topic'] === 'output-timers' ){
        fetchTimers(ws['device'])
      } else if ( ws['topic'] === 'device-calendar' ){
        fetchCalendarSchedule(ws['device'])
      } else if ( ws['topic'] === 'device-alerts' ){
        fetchAlerts(ws['device'])
      } else if ( ws['topic'] === 'devices-notifications' ){

        temp = {}
        if( ws['type'] === "put"  && ws['field'] === "" ){
          if(ws['value'] ){
            temp[ws['device']] = ws['value']
            setNotifications((previus) => mergeObjects(previus,temp))
          }else{
            temp[ws['device']] = {}
            setNotifications((previus) => ({...previus, ...temp}))
          }
        }else if (  ( ws['type'] === "put" || ws['type'] === "patch" )   && ws['field'] !== ""  ){
          temp[ws['device']] = {}
          temp[ws['device']][ws['field']] = ws['value']
          setNotifications((previus) => mergeObjects(previus,temp))
        }
        setLogs(ws['device'])
      }
    }
  }

  
  
 
  

  const findTemp = (ws) => {

    const str = ws['path'];
    const parts = str.split('/').filter(Boolean);
    const obj = {};
    parts.reduce((acc, current, index, arr) => {
      if (index === arr.length - 1) {
        acc[current] = ws['value'];
      } else {
        acc[current] = {};
      }
      return acc[current];
    }, obj);

    return obj
  }





  const getSession = () => {
    fetch(`${httpURL}api/session/`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.isAuthenticated) {
          setFbid(data.fid);
          setIsLoggedIn(true);
          setIsDemo(data.isDemo)
        } else {
          getCSRF()
          setIsLoggedIn(false);
        }
      });
  };


  useEffect(() => {
    if( isLoggedIn ){
      async function initAPP(){
        await fetchDevices()
        await fetchUser()
        fetchNotifications()
        fetchWatchedList()
        fetchTimers()
        fetchCalendarSchedule()
        fetchAlerts()
        setLoading(false)

      }
      initAPP()
    }


    return () => {
      setLoading(true)
    }
  },[isLoggedIn])


  const refreshApp = async () =>{

    setLoading(true)
    await fetchDevices()
    await fetchUser()
    fetchNotifications()
    fetchWatchedList()
    fetchTimers()
    fetchCalendarSchedule()
    fetchAlerts()

    if(ws){
      ws.send(JSON.stringify( { type: 'close' } ))
      ws.close()
    }
    setWs(false)


    setLoading(false)

  }
 


  const setLogs = async (device) => {

    
    let inputsLogs = ""
    let outputsLogs = ""
    let deviceLogs = "" 
    
    try{
      inputsLogs = JSON.parse(localStorage.getItem(`${device}/inputs`)) 
      outputsLogs = JSON.parse(localStorage.getItem(`${device}/outputs`)) 
      deviceLogs = JSON.parse(localStorage.getItem(`${device}/on-off`)) 
    }catch(error) {
    }
    
    // 

    let isSet = false
    let inputs = {}
    let outputs = {}
    let devices = ""
    let logs = {}
    if (inputsLogs && outputsLogs && deviceLogs){
      isSet = true
      for( let i in  inputsLogs){
        inputs[i] = Object.keys(inputsLogs[i]).map(str => parseInt(str)).sort((a, b) => a - b).pop().toString();
      }
      for( let i in  outputsLogs){
        outputs[i] = Object.keys(outputsLogs[i]).map(str => parseInt(str)).sort((a, b) => a - b).pop().toString();
      }
      devices = Object.keys(deviceLogs).map(str => parseInt(str)).sort((a, b) => a - b).pop().toString();
      logs = {'inputs' : inputs, 'outputs' : outputs, 'on-off': devices }
    }



    const data = await fetch(`${httpURL}api/fetchLogs/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrf,
      },
      body: JSON.stringify(
        {
          device : device,
          logs : logs,
          isSet : isSet
        } 
      ),
      credentials: "include",
    }).then((res) => res.json())

    if( data['flag'] && !isSet && data['logs']){
      if(data['logs'] && data['logs']['inputs'] ){
        localStorage.setItem(`${device}/inputs`, JSON.stringify(data['logs']['inputs']))
      }
      if(data['logs'] && data['logs']['outputs'] ){
        localStorage.setItem(`${device}/outputs`, JSON.stringify(data['logs']['outputs']))
      }
      if(data['logs'] && data['logs']['on-off'] ){
        localStorage.setItem(`${device}/on-off`, JSON.stringify(data['logs']['on-off']))
      }
    }else if (data['flag'] && data['logs']){

      try {
        for( let i in data['logs']['inputs'] ){
          let tempObj = {...data['logs']['inputs'][i], ...inputsLogs[i] }
          inputsLogs[i] = tempObj
        }
        for( let i in data['logs']['outputs'] ){
          let tempObj = {...data['logs']['outputs'][i], ...outputsLogs[i] }
          outputsLogs[i] = tempObj
        }

        localStorage.setItem(`${device}/inputs`, JSON.stringify(inputsLogs))
        localStorage.setItem(`${device}/outputs`, JSON.stringify(outputsLogs))
        localStorage.setItem(`${device}/on-off`, JSON.stringify({...deviceLogs, ...data['logs']['on-off'] }))
      } catch (error) {
        localStorage.removeItem(`${device}/inputs`);
        localStorage.removeItem(`${device}/outputs`);
        localStorage.removeItem(`${device}/on-off`);
      }
    }
    setUpdatingLogs((previus) => !previus)

  }

  const fetchDevices = async () => {
    const data = await fetch(`${httpURL}api/devicesAll/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrf,
      },
      credentials: "include",
    })
      .then((res) => res.json())

    if( data['flag'] ){
      for( let i in data['devices'] ){
        await setLogs(data['devices'][i])
      }
      
      tempLive = data['live-data']
      tempInputs = data['devices-inputs']
      tempOutputs = data['devices-outputs']
      tempDevicesList = data['devices-list']

      if(data['notifications']){
        set_device_notifications(data['notifications'])
      }

      setDevicesAll(data)
      setLiveData(data['live-data'])
      setDeviceInputs(tempInputs)
      setDeviceOutputs(tempOutputs)
      setDevicesList(tempDevicesList)
    }
  };


  const fetchWatchedList = async() => {
    let json = await fetch(`${serverHost}api/dashboard/getWatchedDevices/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrf,
      },
      credentials: "include",
    })
    .then((res) => res.json())
    setWatchedList(json)
  }

  const fetchNotifications = (device = null) => {
    fetch(`${httpURL}api/deviceSettings/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrf,
      },
      body: JSON.stringify({
        devices: device,
        settings: 'devices-notifications',
      }) ,
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        if( data['flag'] ){
          setNotifications((previus) => ({...previus, ...data['devices-notifications'] }))
        }
      });
  }


  const fetchTimers = (device = null) => {
    fetch(`${httpURL}api/deviceSettings/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrf,
      },
      body: JSON.stringify({
        settings: 'output-timers',
        devices: device
      }) ,
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        if( data['flag'] ){
          setTimers((previus) => ({...previus, ...data['output-timers']}))
        }
      });

    return(() => {
      setTimers({})
    })
  }


  const splitCalendarsSchedule = (data) => {
    let tempCalendar = {}
    let tempSchedule = {}
    let finalCalendar = {}
    let finalSchedule = {}
    let device 

    for( device in data ){
      tempCalendar = {}
      tempSchedule = {}

      for( let key in data[device] ){
        if( data[device][key].hasOwnProperty('repeat') ){
          tempSchedule[key] = data[device][key]
        }else{
          tempCalendar[key] = data[device][key]
        }

      }
      finalCalendar[device] = sortObjectOfObjects( tempCalendar, 'start')
      finalSchedule[device] = sortObjectOfObjects( tempSchedule, 'start')
    }

    setCalendar((previus) => ({...previus,...finalCalendar }))
    setSchedule((previus) => ({...previus,...finalSchedule }))
  }

  
  
  const fetchCalendarSchedule = (device = null) => {
    fetch(`${httpURL}api/deviceSettings/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrf,
      },
      body: JSON.stringify({
        settings: 'device-calendar',
        devices: device
      }) ,
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        if( data['flag'] ){
          splitCalendarsSchedule(data['device-calendar'])
        }
      });
  }

  const fetchAlerts = (device = null) => {
    fetch(`${httpURL}api/deviceSettings/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrf,
      },
      body: JSON.stringify({
        settings: 'device-alerts',
        devices: device
      }) ,
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        if( data['flag'] ){
          setAlerts( (previus) => ({...previus, ...data['device-alerts']}) )
        }
      });
  }

 


  const fetchUser = async () => {
    const data = await fetch(`${httpURL}api/deviceSettings/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrf,
      },
      body: JSON.stringify({
        settings: 'users-list',
      }) ,
      credentials: "include",
    })
      .then((res) => res.json())
      

      if( data['flag'] ){
        setUser(data['users-list'])
      }
  }

  function sortObjectOfObjects(obj, field) {
    const sortedArray = Object.entries(obj)
      .map(([key, value]) => ({ key, ...value }))
      .sort((a, b) => (a[field] > b[field]) ? 1 : -1);
    return sortedArray;
  }


  const getCSRF = async () => {
    const temp = await fetch(`${httpURL}api/csrf/`, {
      credentials: "include",
    })
    .then((res) => res)

    return setCsrf(temp.headers.get("X-CSRFToken"));
  };


  


  const logoutHandler = () => {

    if(ws){
      ws.send(JSON.stringify( { type: 'close' } ))
      ws.close()
    }
    setWs(false)



    set_device_notifications([])
    setIsLoggedIn(false);
    setIsDemo(false);
    setDevices()
    setDevicesAll([])
    setFbid()
    setNotifications({})
    setWatchedList({})
    setTimers({})
    setSchedule({})
    setCalendar({})
    setAlerts({})
    setUser({})
    setLoading(true)
    setUpdatingLogs(false)
    setLiveData({})
    setDeviceInputs({})
    setDeviceOutputs({})
    setDevicesList({})
  };

  const loginHandler = () => {
    setIsLoggedIn(true);
  };

  const setUserDevices = (devices) => {
    setDevices(devices);
  };

  const toggleMenu = () => {
    setCloseMenu(!closeMenu);
  };

  const getFinalDate = (date) => {
    let newDate = {};
    newDate["year"] = date.getFullYear();
    newDate["month"] =
      parseFloat(date.getMonth() + 1) < 10
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1;
    newDate["date"] =
      parseFloat(date.getDate()) < 10 ? `0${date.getDate()}` : date.getDate();
    newDate["hours"] =
      parseFloat(date.getHours()) < 10 ? `0${date.getHours()}` : date.getHours();
    newDate["minutes"] =
      parseFloat(date.getMinutes()) < 10
        ? `0${date.getMinutes()}`
        : date.getMinutes();
    newDate["seconds"] =
      parseFloat(date.getSeconds()) < 10
        ? `0${date.getSeconds()}`
        : date.getSeconds();
  
    let finalDay = `${newDate["date"]}/${newDate["month"]}/${newDate["year"]} ${newDate["hours"]}:${newDate["minutes"]}`;
    return finalDay;
  };

  return (
    <UserContext.Provider
      value={{
        isLoggedIn: isLoggedIn,
        csrf: csrf,
        devices: devices,
        fbid: fbid,
        serverHost : serverHost,
        wsHost : wsHost,
        closeMenu: closeMenu,
        devicesAll : devicesAll,
        notifications: notifications,
        watchedList: watchedList,
        timers: timers,
        calendar: calendar,
        schedule: schedule,
        user: user,
        alerts: alerts,
        loading: loading,
        updatingLogs: updatingLogs,
        deviceInputs: deviceInputs,
        deviceOutputs: deviceOutputs,
        devicesList: devicesList,
        liveData: liveData,
        isDemo: isDemo,
        device_notifications: device_notifications,
        setIsDemo: setIsDemo,
        setLoading: setLoading,
        setUpdatingLogs: setUpdatingLogs,
        setLogs: setLogs,
        onLogout: logoutHandler,
        onLogin: loginHandler,
        setCsrf: setCsrf,
        setUserDevices: setUserDevices,
        getSession: getSession,
        toggleMenu: toggleMenu,
        fetchDevices : fetchDevices,
        fetchNotifications : fetchNotifications,
        setNotifications : setNotifications,
        getFinalDate : getFinalDate,
        fetchWatchedList : fetchWatchedList,
        refreshApp : refreshApp,
        set_device_notifications : set_device_notifications,
        
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContext;