import { useEffect, useState, useContext } from "react"
import { toast } from 'react-toastify';
import UserProvider  from "../../store/user-context";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPenToSquare }  from '@fortawesome/free-solid-svg-icons'
import AddTimer from "./TimerForm";
import { useTranslation } from "react-i18next";

const initTimer = {
  "show" : false,
  "type" : "add",
  "timer": {},
  "child" : ""
}

const ShowTimers = (props) => {
  const [timerHandler, setTimerHandler] = useState(initTimer)
  const userCtx = useContext(UserProvider)
  const [data, setData] = useState({})
  const { t } = useTranslation();

  const editTimer = (e) => {
    let timer  = e.target.closest('button').getAttribute('data-action')
    let temp = {
      "show" : true,
      "type" : "edit",
      "timer": data[timer],
      "child" : timer
    }
    setTimerHandler(temp)
  }  
  

  const removeTimer = (e) => {
    if(userCtx.isDemo){
      toast(t("There is no capability to save actions in a user demo"));
      return
    }

    let timer  = e.target.closest('button').getAttribute('data-action')
    if(timer){
      let json = 
      {
        "topic":"output-timers",
        "device": props['device'],
        "values": [timer]
      }
  
      fetch(`${userCtx.serverHost}api/sendMqtt/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": userCtx.csrf,
        },
        credentials: "include",
        body: JSON.stringify({
          topic : 'deviceSettingsDelete',
          payload : json
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if( data['flag'] ){
            
            toast(t("Deleted successfully"));
          }else{
            toast(t("Something went wrong."));
          }
        });
    }else{
      toast(t("Something went wrong."));
    }
  }



  useEffect(() => {
    try {
      if( props['device'] && Object.keys(userCtx.timers).length &&  Object.keys(userCtx.timers[props['device']]).length ){
        fetchData()
      }
    } catch (error) {
      
    }

    return(() => {
      setData({})
    })
    
  },[props,userCtx.timers])

  const fetchData = () => {
    if( props['type'] === 'settings' ){
      let temp = {}
      for( let i in  userCtx.timers[props['device']] ){
        if( userCtx.timers[props['device']][i]['output'] != props['output'] ){
          continue;
        }
        temp[i] = userCtx.timers[props['device']][i]
      }

      setData(temp)
    }
  }


  return(
    <div>
      <div>
      { timerHandler && timerHandler['show'] &&
        <AddTimer 
          device={props['device']} 
          output={props['output']} 
          type={timerHandler['type']}
          timer={timerHandler['timer']}
          child={timerHandler['child']}
          setTimerHandler={setTimerHandler}
        />
      }

      </div>

      { data && Object.keys(data).length > 0 && 
        <div className="row g-1 align-items-center fw-semibold fs-6 my-2 top-divider-strong pt-3 mt-3">
          <div className={`col-3 col-md-3`}  >
          {t("Status")}
          
          </div>
          <div className={`col-3 col-md-3` } >
          {t("Action")}
          
          </div>
          <div className="col-2 col-md-2">
          {t("Execution time")}
          
          </div>
          <div className="col-2 col-md-2">
          {t("Timer")}
          
          </div>
        </div>
      }
      

      { data && Object.keys(data).map((timer) => {

          let time = parseInt(data[timer]['time'])
          let date = new Date(time);
          let hour = date.getHours().toString();
          let minute = date.getMinutes().toString();

          hour = ( hour.length === 1 ) ? `0${hour}` : hour
          minute = ( minute.length === 1 ) ? `0${minute}` : minute

          let currentTimestamp = new Date(data[timer]['initTime']);
          let timerMinutes = ( date.getHours() * 60 ) + date.getMinutes()
          let cMinutes = ( currentTimestamp.getHours() * 60 ) + currentTimestamp.getMinutes()
          let difMinutes =  timerMinutes - cMinutes
          let difHour = Math.floor(difMinutes/60).toString()
          let difMinute = (difMinutes%60).toString()

          let msg = ''
          if( difHour > 0 ){
            msg += `${difHour} ${t("hour")}`
          }

          if( difHour > 0 && difMinute > 0){
            msg += ` ${t("and")} `
          }

          if( difMinute > 0 ){
            msg += difMinute + " "
            msg += difMinute == 1 ? `${t("minute")}` : `${t("minutes")}`
          }

          return(
            <div className="row g-1 align-items-center fw-semibold fs-6 my-2 top-divider-strong pt-3 mt-3" key={timer}>
              <div className={`col-3 col-md-3 ${(data[timer] && data[timer]['enabled']) ? 'text-success' : 'text-danger' }`}  >
                { (data[timer] && data[timer]['enabled']) ? t("Active") : t("Inactive")  }
              </div>
              <div className={`col-3 col-md-3 ${(data[timer] && data[timer]['state']) ? 'text-success' : 'text-danger'}` } >
                { (data[timer] && data[timer]['state']) ? 'ON' : 'OFF' }
              </div>

              <div className="col-2 col-md-2">
                { data[timer]['enabled'] && `${hour} : ${minute}`}
              </div>
              <div className="col-2 col-md-2">
                {msg}
              </div>
              <div className="col-1 col-md-1">
                <button 
                  className="btn btn-outline-success px-2 py-1" 
                  data-action={timer} 
                  onClick={(e) =>{editTimer(e)}}
                >
                  <FontAwesomeIcon icon={faPenToSquare} />
                </button>
              </div>
              <div className="col-1 col-md-1">
                <div className="d-flex">
                  <button 
                    className="btn btn-outline-danger px-2 py-1" 
                    data-action={timer} 
                    onClick={(e) =>{removeTimer(e)}} 
                  >
                      <FontAwesomeIcon icon={faTrash} />
                  </button>
                </div>
              </div>
            </div>
          )
          }) }
    </div>
  )
}


export default ShowTimers