import { useEffect,useContext, useState } from "react";
import UserProvider from "../../store/user-context";
import DeviceWatch from "./DeviceWatch";
import { faWifi}  from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const { t } = useTranslation();
  const userCtx = useContext(UserProvider)
  const [online, setOnline] = useState(0);
  const [offline, setOffline] = useState(0);
  const [watched, setWatched] = useState([])

  useEffect(() => {
    if( userCtx && userCtx.devicesList && userCtx.devicesList  ){
      let online = 0
      let offline = 0
      for( let i in  userCtx.devicesList ){
        if( userCtx.devicesList[i]['device-state']  ){
          online++
        }else{
          offline++
        }
      }
      
      setOnline(online)
      setOffline(offline)
    }
  },[userCtx.devicesList])


 

  useEffect( () =>{
    setWatched(userCtx.watchedList)
  },[])

  useEffect( () =>{
    setWatched(userCtx.watchedList)
  },[userCtx.watchedList])



  return(
    <div className={"px-3 pt-3 pb-5 w-100"}>
      <div className="d-flex flex-row flex-wrap align-items-center justify-content-start w-100 gap-3 my-3 px-3">  
        <div className={"counterContainer boxShadow"}>
          <div className={"iconContainer"} style={{background : "#04cc21"}}>
            <FontAwesomeIcon  icon={faWifi} />
          </div>
          <div>{t("Online")}:</div>
          <div>{online}</div>
        </div>

        <div className={"counterContainer boxShadow"}>
          <div className={"iconContainer"} style={{background : "#8d8d8d"}}>
            <FontAwesomeIcon  icon={faWifi} />
          </div>
          <div>{t("Offline")}:</div>
          <div>{offline}</div>
        </div>
      </div>
      { ( (userCtx.user['role'] == "Admin") || ( userCtx.user['rules'] && userCtx.user['rules']['watch'] ) ) && 
        <div className="mt-4 px-3  d-flex flex-wrap gap-3">
          {Object.entries(watched).length > 0 &&
            Object.entries(watched).map(([key, value]) => {
              return( <DeviceWatch key={key} device={key} sensors={value}  /> )
          })}
        </div>
      }


      
    </div>
  );

}

export default Dashboard;