import { useState, useContext, useEffect } from "react";
import UserProvider from "../../store/user-context";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus, faMinus }  from '@fortawesome/free-solid-svg-icons'
import "react-input-range/lib/css/index.css"
import InputRange from 'react-input-range';
import ShowAlert from "./ShowAlert";
import { useTranslation } from "react-i18next";

const initialAlert = {
  "active" : true,
  "type": "",
  "min": "",
  "max": "",
  "value": "0",
  "push": false,
  "lagMins" : "",
  "lagHours" : "",
  "has_sms" : false,
  "has_call" : false,
  "sms" : "",
  "call" : "",
};

let initialAction = {
  "device-name": "",
  "output-name": "",
  "output-state": "1",
}

const AlertForm = (props) => {
  const userCtx = useContext(UserProvider);
  const [alert, setAlert] = useState(initialAlert);
  const [actions, setActions] = useState([]);
  const [input, setInput] = useState([]);
  const [cAction, setCAction] = useState(initialAction)
  const [devices, setDevices] = useState()
  const [devicesList, setDevicesList] = useState([])
  const [outputs, setOutputs] = useState([])
  const [showActions, setShowActions] = useState(false)
  const { t } = useTranslation();

  useEffect(() => {
    if( props['type']  === 'edit' ){
      if(  props['alert'] && props['alert']['actions'] ){
        let tempActions = []
        for( let i in props['alert']['actions'] ){
          tempActions.push(props['alert']['actions'][i])
        }
        setActions( tempActions )
      }

      if(  props['alert'] && props['alert']['trigger'] ){
        let alarmLagMins = parseInt(props['alert']['alarm-lag']) / 60
        let mins = alarmLagMins % 60 ?  alarmLagMins % 60 : ""
        let hours = Math.floor(alarmLagMins / 60) ?  Math.floor(alarmLagMins / 60) : ""
        let temp = {
          "type": props['alert']['trigger']['type'] ? props['alert']['trigger']['type'] : "",
          "min": props['alert']['trigger']['min'] ? props['alert']['trigger']['min'] : "",
          "max": props['alert']['trigger']['max'] ? props['alert']['trigger']['max'] : "",
          "value": props['alert']['trigger']['value'] ? props['alert']['trigger']['value'] : "",
          "push": props['alert']['notifications']['push'] ? props['alert']['notifications']['push'] : false,
          "lagMins" : mins,
          "lagHours" : hours,
          'active': props['alert']['active'],
          "has_sms" : props['alert']['notifications']['sms'] ? true : false,
          "has_call" :  props['alert']['notifications']['call'] ? true : false,
          "sms" : props['alert']['notifications']['sms'],
          "call" : props['alert']['notifications']['call'],
        };
        setAlert(temp)
      }
      
    }
    setInput(userCtx.devicesAll["devices-inputs"][props.device][props.input]);
    let temp_active_devices = [];

    for( let i in userCtx.devicesList ){
      if( !userCtx.devicesList[i]['active'] ){
        continue
      }
      temp_active_devices.push(i);
    }
    
    setDevices(temp_active_devices)
    setDevicesList(userCtx.devicesAll["devices-list"])
    
  }, []);



  useEffect(() => {

    let active_outputs = {};
      for( let i in userCtx.devicesAll["devices-outputs"][cAction['device-name']]){
        if(!userCtx.devicesAll["devices-outputs"][cAction['device-name']][i]['active']){
          continue
        } 
        active_outputs[i] = userCtx.devicesAll["devices-outputs"][cAction['device-name']][i]
      }
      
    setOutputs( active_outputs )


  },[cAction['device-name']])


  // useEffect(() => {
  //   console.log(outputs)
  // },[outputs])

  const actionChange = (e) =>{
    let name = e.target.name
    let value = e.target.value
    let temp = {...cAction}
    temp[name] = value

    if( name === 'device-name' ){
      temp['output-name'] = ''
      temp['output-state'] = '1'
    }
    // console.log(temp)
    setCAction(temp)
  }

  const alertChange = (e) => {
    let name = e.target.name
    let value = e.target.value
    let temp = {...alert}
    
    if( name === 'push' ){
      temp['push'] =  (value === 'true') ? false : true
    }
    else if( name === 'has_call' ){
      temp['has_call'] =  (value === 'true') ? false : true
    }
    else if( name === 'has_sms' ){
      temp['has_sms'] =  (value === 'true') ? false : true
    }
    else if( name === 'active' ){
      temp['active'] =  (value === 'true') ? false : true
    }
    else if ( name === 'lagHours'){
      temp[name] = parseInt(value) <= 24 || !value ?  value : '24'
    }
    else if ( name === 'lagMins'){
      temp[name] = parseInt(value) <= 59 || !value ?  value : '59'
    }
    else{
      temp[name] = value
    }
    setAlert(temp)
  }


  const addAction = () => {
    let temp = {...cAction}
    let tempActions = [...actions ]
    if(
      temp['device-name'] &&
      temp['output-name'] &&
      temp['output-state'] 
    ){
      temp['output-state'] = (temp['output-state'] === "1") ? true : false
      tempActions.push(temp)
      setActions(tempActions)
    }else{
      toast(t("Please provide all fields"));

    }
  }

  // useEffect(() => {
  //   console.log(actions)
  // },[actions])

  const deleteAction = (e) => {
    let id  = e.target.getAttribute('data-id')
    let tempActions  = [...actions ]
    tempActions.splice(id, 1);
    setActions(tempActions)
  }

  const submitAlert = () => {
    if(userCtx.isDemo){
      toast(t("There is no capability to save actions in a user demo"));
      return
    }

    let json = {
      "topic": "device-alerts",
      "device": props.device,
      "input-id": props.input,
      "alarm-index": 1,
      "active": alert['active'],
      "alarm-lag": 0,
      "last-alert": 0,
      "notifications": {
        "push": alert['push'],
        "sms": alert['has_sms'] ? alert['sms'] : "",
        "call": alert['has_call'] ? alert['call'] : "",
      },
      "trigger": {
      },
      "actions" : {}

    }

    let validMessage = true

    if( input["type"] != 1){
      // Analog
      json['trigger']['type'] = input["type"]
      json['trigger']['min'] = alert['min']
      json['trigger']['max'] = alert['max']

      if( !json['trigger']['min'] && !json['trigger']['max'] ){
        validMessage = false
      }

    }else{
      // Digital
      json['trigger']['type'] = input["type"]
      json['trigger']['value'] = alert['value']

      if( !json['trigger']['value']  ){
        validMessage = false
      }
    }

    let alertLag = 0;
    if( parseInt(alert['lagHours']) ){
      alertLag =  parseInt(alert['lagHours']) * 60
    }

    if( parseInt(alert['lagMins']) ){
      alertLag += parseInt(alert['lagMins'])
    }

    alertLag = alertLag * 60 
    json['alarm-lag'] = alertLag

    
    if( actions.length > 0 ){
      json['actions'] =  {...actions}
    }
    

    if(validMessage){
      let topic;
      let message;
      if( props['type']  === 'edit' ){
        message = {
          "topic" : json['topic'],
          "device" : json['device'],
          "values" : {}
        }
        delete json['topic']
        delete json['device']
        message['values'][props['child']] = json
        topic = 'deviceSettings'
      }else{
        topic = 'addAlert'
        message = json
      }

      fetch(`${userCtx.serverHost}api/sendMqtt/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": userCtx.csrf,
        },
        credentials: "include",
        body: JSON.stringify({
          topic : topic,
          payload : message
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if( data['flag'] ){
            toast(t("Action was successfully added"));
            props.setAlertHandler(false)

          }else{
            toast(t("Something went wrong."));
          }
        });
    }else{
      toast(t("Please provide all fields"));
    }
  }



  return (

    <>
      <ShowAlert />
      <div className="fixedContainer">
        <div className="alertContainer">

        <div>
            <div className="d-flex align-items-center">
              <span className="fw-semibold fs-6 me-3">{t("Active")}</span>
              <input
                type="checkbox"
                name="active"
                value={alert['active']}
                onChange={alertChange}
                checked={alert['active']}
              />
            </div>
          </div>


          {input && input["type"] == 1 && (
            <div className="d-flex justify-content-between">
              <span className="fw-semibold fs-6 me-3">{t("Input state")}</span>
              <select
                name="value"
                value={alert['value']}
                onChange={alertChange}
              >
                <option value="1">ON</option>
                <option value="0">OFF</option>
              </select>
            </div>
          )}

          {input && input["type"] != 1  && (
            <div>
              <div className="fw-semibold fs-6 mb-3">{t("Input value")}</div>
              <div>
                <div className="d-flex justify-content-between my-3">
                  <span className="fw-semibold fs-6">{t("Max")}</span>
                  <input 
                    name="max"
                    value={alert['max']}
                    onChange={alertChange}
                    type="number" 
                    placeholder="Max"  
                  />
                </div>
                <div className="d-flex justify-content-between ">
                  <span className="fw-semibold fs-6">{t("Min")}</span>
                  <input  
                    name="min"
                    value={alert['min']}
                    onChange={alertChange} 
                    type="number"
                    placeholder="Min"   />
                </div>
              </div>
            </div>
          )}

          <div>
            <div className="d-flex justify-content-between mb-3">
              <div className="fw-semibold fs-6 me-3">
                {t("Actions")}
              </div>
              <div>
              <button 
                // onClick={addAction}
                onClick={() => (setShowActions(!showActions))}
                className={`btn 
                ${ !showActions ? 'btn-success' : ' btn-danger'}`}>
                <FontAwesomeIcon icon={ !showActions ? faPlus : faMinus} />
              </button>
              </div>
            </div>

            <div className="my-4">
              { actions && actions.map((action,index) => {
                let canEdit = (
                  userCtx.devicesAll['devices-list'][action['device-name']] &&
                  userCtx.devicesAll['devices-list'][action['device-name']]['device-name']  && 
                  userCtx.devicesAll['devices-outputs'][action['device-name']][action['output-name']]['name'] ) ? true : false

                return(
                  <div className="row my-2">
                    <div className="col-3 d-flex align-items-center fw-semibold justify-content-center">
                      <span >
                      { canEdit ?
                        userCtx.devicesAll['devices-list'][action['device-name']]['device-name'] 
                        : action['device-name']
                      }
                      </span>
                    </div>
                    <div className="col-3 d-flex align-items-center fw-semibold justify-content-center">
                      <span>
                        { canEdit ? userCtx.devicesAll['devices-outputs'][action['device-name']][action['output-name']]['name'] :action['output-name'] }
                      </span>
                    </div>
                    <div className="col-3 d-flex align-items-center fw-semibold justify-content-center">
                      <span className={` fw-bold fs-6 ${ action['output-state']? "text-success" : "text-danger" } `} >{action['output-state']? "ON" : "OFF"}</span>
                    </div>

                    <div className="col-3 d-flex align-items-center fw-semibold justify-content-center">
                      {canEdit && 
                        <button onClick={(e)=>deleteAction(e)} data-id={index} className="btn btn-danger ">
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                      }
                    
                    </div>
                  </div>
                )
                })}
            </div>


            { showActions &&
              <>
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex justify-content-between">
                    <div className="fw-semibold fs-6">{t("Device")}</div>
                    <select 
                      value={cAction['device-name']}
                      name="device-name"
                      onChange={(e)=>{actionChange(e)}}
                    >
                      <option value="">{t("Select devices")}</option>
                      { devices && devices.map((device) => (
                          <option value={device}> {userCtx.devicesAll['devices-list'][device]['device-name']} </option>
                        ))}
                  </select>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="fw-semibold fs-6">{t("Output")}</div>
                    <select 
                    value={cAction['output-name']}
                    name="output-name"
                    onChange={(e)=>{actionChange(e)}}
                    >
                      <option value="">{t("Select")}</option>
                      { outputs &&
                        Object.keys(outputs).map((output) => (
                          <option value={output} > { outputs[output]['name'] } </option>
                        ))
                      }
                    </select>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="fw-semibold fs-6">{t("State")}</div>
                    <select
                      value={cAction['output-state']}
                      name="output-state"
                      onChange={(e)=>{actionChange(e)}}
                    >
                      <option value="1">On</option>
                      <option value="0">Off</option>
                    </select>
                  </div>
                  <div className="d-flex justify-content-end">
                    <button 
                      onClick={addAction}
                      className={`btn 
                      ${cAction && cAction['device-name'] && cAction['output-name'] && cAction['output-state'] ?
                      ' btn-success' : ' btn-danger'}`}>
                      {t("Add")}
                    </button>
                  </div>
                  
                </div>
              </>
            }
            
          </div>
          
          <div>
            <div className="fw-semibold fs-6 mb-3">{t("Notifications")}</div>
            {
              !props.has_credits &&
              <div className="my-3">
                <div>
                  {t("Seems like you have no credits.")}
                </div>
                <div>
                  {t("You can contact us to add some.")}
                </div>
              </div>
            }
            <div className="row gap-2 mb-2">
              <span className=" col-6 fw-semibold fs-6 me-3">{t("Push notification")}</span>
              <input
                type="checkbox"
                className="col-1"
                name="push"
                value={alert['push']}
                onChange={alertChange}
                checked={alert['push']}
              />
            </div>
            <div className="row gap-2 mb-2">
              <span className=" col-6 fw-semibold fs-6 me-3">{t("Sms notification")}</span>
              <input
                type="checkbox"
                className="col-1 align-self-center"
                name="has_sms"
                value={alert['has_sms'] }
                onChange={alertChange}
                checked={alert['has_sms']}
                disabled={!props.has_credits }
              />
              <input
                type="text"
                className="col-1 px-3"
                name="sms"
                value={alert['sms']}
                onChange={alertChange}
                disabled={!props.has_credits || !alert['has_sms']}
              />
            </div>
            <div className="row gap-2 mb-2">
              <span className=" col-6 fw-semibold fs-6 me-3">{t("Call notification")}</span>
              <input
                type="checkbox"
                className="col-1 align-self-center"
                name="has_call"
                value={alert['has_call']}
                onChange={alertChange}
                checked={alert['has_call']}
                disabled={!props.has_credits  }
              />
              <input
                type="text"
                className="col-1"
                name="call"
                disabled={!props.has_credits || !alert['has_call']}
                value={alert['call']}
                onChange={alertChange}
              />
            </div>
          </div>

          <div>
            <div className="fw-semibold fs-6 mb-3">{t("Input lag")}</div>

            <div className="row px-3">
              <div className="col-10">
                <div className="mb-3">{t("Hour")}</div>
                <InputRange
                  maxValue={24}
                  minValue={0}
                  value={alert['lagHours']}
                  onChange={value => setAlert((previus) => ({...previus, 'lagHours' : value }))} 
                />
              </div>
              <div className="col-2 mt-4 text-end">
                <input 
                  type="number" 
                  name="lagHours"
                  value={alert['lagHours']}
                  onChange={alertChange}
                />
              </div>
            </div>
            <div className="row my-3 px-3">
              <div className="col-10">
                <div className="mb-3">{t("Minute")}</div>
                <InputRange
                  maxValue={59}
                  minValue={0}
                  value={alert['lagMins']}
                  onChange={value => setAlert((previus) => ({...previus, 'lagMins' : value }))} 
                />
              </div>
              <div className="col-2 mt-4 text-end">
                <input 
                  name="lagMins"
                  value={alert['lagMins']}
                  onChange={alertChange}
                  type="number" 
                />
              </div>
            </div>
          </div>
          

          <div className="d-flex justify-content-between">
            <button className="btn btn-outline-danger" onClick={()=>{props.setAlertHandler(false)}}>{t("Cancel")}</button>
            <button className="btn btn-primary" onClick={submitAlert}>{t("Save")}</button>
          </div>
        </div>
      </div>
    </>
    
  );
};

export default AlertForm;
